/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import {
  Box,
  Flex,
  HStack,
  Stack,
  Textarea,
  VStack,
  Text,
  Button,
  useToast
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Field } from '../../components/DynamicForms/Field'
import { GroupContainer } from '../../components/DynamicForms/GroupContainer'
import { Input } from '../../components/Form/Input'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { Switch } from '../../components/Form/Switch'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { ModalConfirmExclude } from '../../components/Modal/confirmExclude'
import { CardContainer } from '../../components/molecules/CardContainer'
import { PermissionComponent } from '../../components/Permissions'
import { useDnd } from '../../hooks/useDnd'
import { LayoutDefault } from '../../layouts/Default'
import { apiAuth } from '../../services/apiAuth'
import { useLogic } from './logic'
import { useService } from './services'

type FormData = {
  is_active: boolean
  description: string
}

type Status = {
  label: string
  value: string
}

type Specialist = {
  label: string
  value: number
}

interface Fields {
  id: string
  name: string
  isRequired: boolean
  placeholder: string
  type: string
  value: string
  defaultValue: string
  label: string
}

type FieldsSelect = {
  value: string
  label: string
}

interface GroupFieldsProps {
  id: string
  name: string
  fields: Array<Fields>
  isVisible: boolean
}

type RenderGroupProps = {
  group: GroupFieldsProps
  index: number
}

type Method = 'create' | 'update'

type SpecialistData = Array<Specialist>

interface ParamsProps {
  id?: string
}

interface Choices {
  label: string
  value: string
}

interface FieldData {
  id: number
  choices?: Array<Choices> | []
  created_at: string
  updated_at: string
  name: string
  is_required: boolean
  type: string
  value: string | null
  label: string | null
  placeholder: string | null
  default_value: string | null
  max_length?: number | undefined
}

interface Groups {
  id: number
  name: string
  is_visible: boolean
  fields: Array<FieldData> | []
}

interface Users {
  id: number
  social_name: string
}

interface Forms {
  id: number
  name: string
  description: string | null
  is_active: boolean
  status: number
  groups: Array<Groups> | []
  users: Array<Users> | []
}

export const CreateAndUpdateForms = () => {
  const [t] = useTranslation('pageCreatingForms')
  const toast = useToast()
  const history = useHistory()
  const { id } = useParams<ParamsProps>()
  const { listForms, setListForms } = useDnd()
  const logic = useLogic()
  const services = useService()
  const { handleSubmit, register } = useForm<FormData>()
  // defaultValues status
  const optionsDefaultStatus = [
    {
      value: '1',
      label: `${t('status.1')}`
    },
    {
      value: '2',
      label: `${t('status.2')}`
    }
  ]
  // defaultValues fields
  const optionsDefaultField = [
    {
      value: 'text',
      label: `${t('fields.1')}`
    },
    {
      value: 'textarea',
      label: `${t('fields.2')}`
    },
    {
      value: 'select',
      label: `${t('fields.3')}`
    },
    {
      value: 'radio',
      label: `${t('fields.4')}`
    }
  ]
  // states
  const [localMethod, setLocal] = useState<Method>('create')
  const [form, setForm] = useState<Forms | null>(null)
  const [name, setName] = useState('')
  const [status, setStatus] = useState<Status>({
    value: '1',
    label: `${t('status.1')}`
  })
  const [specialists, setSpecialists] = useState<SpecialistData>([])
  const [selectField, setSelectField] = useState<FieldsSelect>({
    value: 'text',
    label: `${t('fields.1')}`
  })

  // validade groups and fields quantity > 0 state
  const [errorGroup, setErrorGroup] = useState(false)
  const [errorField, setErrorField] = useState(false)

  // validate fields state
  const [errorGroupsName, setErroGroupsName] = useState('')
  const [errorFieldIndex, setErroFieldIndex] = useState(0)
  const [errorType, setErrorType] = useState('')

  // state modal exclude
  const [isOpenExclude, setIsOpenExclude] = useState(false)
  const [indexGroupExclude, setIndexGroupExclude] = useState<number | null>(
    null
  )
  const [idFieldExclude, setIdFieldExclude] = useState<number | null>(null)
  const [typeExclude, setTypeExclude] = useState<string>('')
  // states modal create edit group
  const [isOpen, setIsOpen] = useState(false)
  // modal add new field
  const [isOpenAddField, setIsOpenAddField] = useState(false)
  const [typeEditGroup, setTypeEditGroup] = useState<Method>('create')
  const [groupId, setGroupId] = useState('')
  // state save change add Field
  const [idField, setIdField] = useState('')
  const [IndexGroup, setIndexGroup] = useState(0)

  useEffect(() => {
    // get post ou put method
    function handleGetMethod() {
      const local = history.location.pathname

      if (local.indexOf('create') !== -1) {
        setLocal('create')
      } else {
        setLocal('update')
      }
    }

    handleGetMethod()

    return () => {}
  }, [history])

  useEffect(() => {
    if (id === undefined) {
      return
    }

    apiAuth.get<Forms>(`clinics/forms/${id}/`).then((response) => {
      setForm(response.data)
    })
  }, [id])

  useEffect(() => {
    if (form === null) {
      return
    }

    setName(form.name)

    if (form.status === 1) {
      setStatus({
        value: '1',
        label: `${t('status.1')}`
      })
    } else {
      setStatus({
        value: '2',
        label: `${t('status.2')}`
      })
    }

    setSpecialists(
      form.users.map((user) => {
        return {
          value: user.id,
          label: user.social_name
        }
      })
    )

    const backValuesGroups = form?.groups.map((group) => {
      const backFields = group.fields.map((field) => {
        const options =
          field?.choices !== undefined
            ? field.choices.map((option, index) => {
                return {
                  value: option.value,
                  label: option.label,
                  pos: index
                }
              })
            : []

        return {
          id: String(field.id),
          name: field.name !== null ? field.name : '',
          isRequired: field.is_required,
          placeholder: field.placeholder !== null ? field.placeholder : '',
          type: field.type,
          value: field.value !== null ? field.value : '',
          defaultValue: field.default_value !== null ? field.default_value : '',
          label: field.label !== null ? field.label : '',
          options,
          maxLength: field?.max_length !== null ? field.max_length : undefined
        }
      })

      return {
        name: group.name,
        isVisible: group.is_visible,
        id: String(group.id),
        fields: backFields
      }
    })

    setListForms(backValuesGroups)
  }, [form])

  // open and close modal
  const handlerOpenModal = () => {
    setIsOpen(true)
  }

  const handlerCloseModal = () => {
    setIsOpen(false)
  }

  // open and close field modal
  const handlerOpenModalField = () => {
    setIsOpenAddField(true)
  }

  const handlerCloseModalField = () => {
    setIsOpenAddField(false)
  }

  const handlerOpenModalExclude = () => {
    setIsOpenExclude(true)
  }

  const handlerCloseModalExclude = () => {
    setIsOpenExclude(false)
  }

  const handlerNameSet = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value.toUpperCase()
    setName(values)
  }

  const handlerSelectStatus = (event: any) => {
    setStatus(event)
  }

  const handlerSelectSpecialist = (event: any) => {
    setSpecialists(event)
  }

  const handlerSelectTypeField = (event: any) => {
    setSelectField(event)
  }

  // render fields function
  const RenderGroup = ({ group, index }: RenderGroupProps) => {
    return (
      <GroupContainer
        key={group.id}
        title={group.name}
        isEditable
        isVisible={group.isVisible}
        onAction={() => {
          logic.setNameGroup(group.name || '')
          setGroupId(group.id)
          setTypeEditGroup('update')
          handlerOpenModal()
        }}
        onExclude={() => {
          setTypeExclude('group')
          setIndexGroupExclude(index)
          setIdFieldExclude(null)
          handlerOpenModalExclude()
        }}
        index={index}
      >
        <Stack w="100%" spacing="2" direction="column">
          {group.fields.map((field: Fields, indexField) => (
            <Field
              index={indexField}
              indexList={index}
              key={field.id}
              data={field}
              onExclude={() => {
                setTypeExclude('field')
                setIndexGroupExclude(index)
                setIdFieldExclude(Number(field.id))
                handlerOpenModalExclude()
              }}
            />
          ))}
        </Stack>
        <Flex w="100%" justifyContent="flex-start" my="2">
          <Button
            colorScheme="green"
            size="sm"
            onClick={() => {
              handlerOpenModalField()
              setIdField(group.id)
              setIndexGroup(index)
            }}
          >
            {t('create.container3.btnAddField')}
          </Button>
        </Flex>
      </GroupContainer>
    )
  }

  // handler exclude group or field in listForm
  const handlerExcludeGroupAndOrField = () => {
    if (indexGroupExclude === null) {
      return
    }

    if (typeExclude === 'group' && idFieldExclude === null) {
      const values = [...listForms]
      values.splice(indexGroupExclude, 1)

      setListForms(values)
    }

    console.log(indexGroupExclude)
    console.log(idFieldExclude)

    if (typeExclude === 'field' && idFieldExclude !== null) {
      const values = [...listForms]
      const valuesFields = values[indexGroupExclude].fields
      const newValuesFields = valuesFields.map((field: any) => {
        return Number(field.id) !== idFieldExclude && field
      })
      const fields = newValuesFields.filter(Boolean)
      const data = {
        fields
      }
      const newValuesListForms = listForms.map((group, index) => {
        return index === indexGroupExclude ? { ...group, ...data } : group
      })

      setListForms(newValuesListForms)
    }

    handlerCloseModalExclude()
  }

  // format users specialist for preferences body
  const returnUsersSpecialist = () => {
    const users = specialists.map((user) => {
      return {
        id: user.value,
        name: user.label
      }
    })

    return users
  }

  // format groups for body request
  const returnGroupsFormatted = () => {
    const groups = listForms.map((group, indexGroup) => {
      const fields = group.fields.map((field, indexField) => {
        const choices =
          field?.options !== undefined
            ? field?.options.map((option) => {
                return {
                  value: option?.value !== '' ? option.value : null,
                  label: option?.label !== '' ? option.label : null,
                  index: option?.pos
                }
              })
            : []

        return {
          name: field?.name !== '' ? field.name : null,
          is_required: field.isRequired,
          type: field.type,
          index: indexField,
          value: field?.value !== '' ? field.value : null,
          label: field.label !== '' ? field.label : null,
          placeholder: field.placeholder !== '' ? field.placeholder : null,
          default_value: field.defaultValue !== '' ? field.defaultValue : null,
          max_length:
            field?.maxLength !== undefined ? field.maxLength : undefined,
          choices
        }
      })

      return {
        name: group.name,
        is_visible: group.isVisible,
        fields,
        index: indexGroup
      }
    })

    return groups
  }

  // validation of groups and fields > 0
  const validateOfGroupsAndFields = () => {
    let isNotValid = false

    if (status?.value === '1') {
      return isNotValid
    }

    if (listForms.length === 0) {
      isNotValid = true
      setErrorGroup(true)
      console.log(isNotValid)
      return isNotValid
    }

    listForms.forEach((groups) => {
      if (groups.fields.length === 0) {
        isNotValid = true
        setErroGroupsName(groups.name)
        setErrorGroup(false)
        setErrorField(true)

        return isNotValid
      }

      return isNotValid
    })

    return isNotValid
  }

  // if status 2 validation of fields
  const validateFields = () => {
    let isNotValid = false

    if (status?.value === '1') {
      return isNotValid
    }

    listForms.forEach((group) => {
      group.fields.forEach((field, index) => {
        if (field.label === '') {
          isNotValid = true
          setErroGroupsName(group.name)
          setErroFieldIndex(index + 1)
          setErrorType(t('errors.type.1'))

          return isNotValid
        }
        if (field?.options && field.options.length > 0) {
          field.options.forEach((option) => {
            if (option.value === '') {
              isNotValid = true

              setErroGroupsName(group.name)
              setErroFieldIndex(index + 1)
              setErrorType(t('errors.type.2'))

              return isNotValid
            }

            return isNotValid
          })
        }

        return isNotValid
      })
    })

    return isNotValid
  }

  useEffect(() => {
    validateOfGroupsAndFields()
    validateFields()

    return () => {}
  }, [listForms, status])

  // submitForm
  const handlerSubmitMakerForm: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    const isNotValidBase = validateOfGroupsAndFields()

    if (isNotValidBase) {
      if (errorGroup === true) {
        toast({
          title: `${t('errors.title')}`,
          isClosable: true,
          position: 'top',
          status: 'error',
          duration: 4000,
          variant: 'solid',
          description: `${t('errors.errorGroup')}`
        })

        return
      }

      if (errorField === true) {
        toast({
          title: `${t('errors.title')}`,
          isClosable: true,
          position: 'top',
          status: 'error',
          duration: 4000,
          variant: 'solid',
          description: `${t('errors.errorField')} ${errorGroupsName}`
        })

        return
      }
      return
    }

    const isNotValidDetail = validateFields()

    if (isNotValidDetail) {
      if (errorType === t('errors.type.1')) {
        toast({
          title: `${t('errors.title')}`,
          isClosable: true,
          position: 'top',
          status: 'error',
          duration: 5000,
          variant: 'solid',
          description: `${t('errors.errorMessage')}`
        })
      } else if (errorType === t('errors.type.2')) {
        toast({
          title: `${t('errors.title')}`,
          isClosable: true,
          position: 'top',
          status: 'error',
          duration: 5000,
          variant: 'solid',
          description: `${t('errors.errorMessage')}`
        })
      }

      return
    }

    const users = returnUsersSpecialist()
    const groups = returnGroupsFormatted()

    const payload = {
      name,
      status: status?.value,
      is_active:
        values?.is_active !== undefined
          ? values?.is_active
          : form?.is_active || true,
      description:
        values?.description !== '' ? values?.description : form?.description,
      users,
      groups
    }

    if (localMethod === 'create') {
      await services.createNewDynamicForm(payload)
    } else {
      await services.updateDynamicForm(payload, id !== undefined ? id : '')
    }
  }

  return (
    <>
      <LayoutDefault
        title={localMethod === 'create' ? t('create.title') : t('editTitle')}
        urlBack="/settings/forms"
        onSubmit={handleSubmit(handlerSubmitMakerForm)}
      >
        <Stack justifyContent="space-evenly" spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="40%" mr="8">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  fontSize="16"
                  placeholder={t('create.container1.name')}
                  isRequired
                  value={name}
                  onChange={handlerNameSet}
                  maxLength={30}
                />
              </Box>
              <Stack direction="row" my="2" spacing="8" w="50%">
                <Box w="300px" mr="2">
                  <SelectComponent
                    options={optionsDefaultStatus}
                    name="status"
                    value={status}
                    onChange={handlerSelectStatus}
                    title={t('create.container1.i2')}
                  />
                </Box>
                {localMethod === 'create' ? (
                  <Box ml="12">
                    <Switch
                      defaultChecked={
                        form?.is_active !== undefined ? form.is_active : true
                      }
                      label={t('create.container1.i3')}
                      {...register('is_active')}
                    />
                  </Box>
                ) : (
                  <PermissionComponent spiCode="FORMS" ipCode="INACTIVATE">
                    <Box ml="12">
                      <Switch
                        defaultChecked={
                          form?.is_active !== undefined ? form.is_active : true
                        }
                        label={t('create.container1.i3')}
                        {...register('is_active')}
                      />
                    </Box>
                  </PermissionComponent>
                )}
              </Stack>
            </Stack>
            <Flex direction="column">
              <Text mb="2" fontSize="sm">
                {t('create.container1.i5')}
              </Text>
              <Textarea
                placeholder={t('create.container1.description')}
                defaultValue={form?.description || ''}
                {...register('description')}
                maxLength={200}
              />
            </Flex>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="column" my="2" spacing="6" w="100%">
              <SelectComponent
                options={
                  services.specialists?.length &&
                  services.specialists.map((specialist) => {
                    return {
                      value: specialist?.user?.id,
                      label: specialist?.social_name || specialist?.user?.name
                    }
                  })
                }
                value={specialists}
                title={t('create.container2.i1')}
                isMulti
                onChange={handlerSelectSpecialist}
                name="specialists"
                placeholder={t('create.container2.specialist')}
              />
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container3.title')}>
            {/* <BuilderForm /> */}
            <Stack direction="column" spacing="4">
              {listForms.map((group, index) => (
                <RenderGroup group={group} index={index} />
              ))}
            </Stack>
            <Flex w="100%" my="2">
              <Button
                colorScheme="green"
                size="sm"
                onClick={() => {
                  setTypeEditGroup('create')
                  logic.setNameGroup('')
                  handlerOpenModal()
                }}
              >
                {t('create.container3.btnAddGroup')}
              </Button>
            </Flex>
          </CardContainer>
          <Flex w="100%" px="2" justifyContent="flex-end">
            <Button colorScheme="blue" type="submit" size="md">
              {t('save')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </LayoutDefault>
      <ModalArrivedTime
        title={t('modal.title')}
        onClose={handlerCloseModal}
        isOpen={isOpen}
        onUpdate={() => {
          if (typeEditGroup === 'create') {
            logic.handlerAddGroupFields()
            logic.setNameGroup('')
            handlerCloseModal()
          } else if (typeEditGroup === 'update') {
            logic.handlerChangeNameGroup(groupId)
            logic.setNameGroup('')
            handlerCloseModal()
          }
        }}
      >
        <Box>
          <Input
            name="groupName"
            isRequired
            label={t('modal.groupName')}
            value={logic.nameGroup}
            onChange={(event) =>
              logic.setNameGroup(event.target.value.toUpperCase())
            }
          />
        </Box>
      </ModalArrivedTime>
      <ModalArrivedTime
        isOpen={isOpenAddField}
        onUpdate={() => {
          logic.handlerAddField(idField, IndexGroup, selectField.value)
        }}
        onClose={handlerCloseModalField}
        title={t('modalField.title')}
      >
        <Box>
          <SelectComponent
            options={optionsDefaultField}
            title={t('modalField.fieldType')}
            value={selectField}
            onChange={handlerSelectTypeField}
            name="selectedField"
          />
        </Box>
      </ModalArrivedTime>
      <ModalConfirmExclude
        isOpen={isOpenExclude}
        onClose={handlerCloseModalExclude}
        title="Confirma Exclusão"
        onConfirmExclude={handlerExcludeGroupAndOrField}
      >
        Gostaria realmente de excluir este item?
      </ModalConfirmExclude>
    </>
  )
}
