/* eslint-disable camelcase */
import React from 'react'
import { CheckboxGroupProps } from '@chakra-ui/react'
import { Input } from './Input'
import { RadioGroup } from './RadioGroup'
import { Textarea } from './Textarea'
import { Select } from './Select'

interface Choices {
  value: string
  label: string
}

interface ElementProps {
  field: {
    id: number
    type: string
    name: string
    label: string
    default_value?: any
    is_required: boolean
    max_length: number
    value: string
    choices?: Choices[]
  }
  handlerChangeFormValues?: (idField: number, event: any) => void
}

export const Element: React.FC<ElementProps> = ({
  field,
  handlerChangeFormValues
}) => {
  switch (field.type) {
    case 'text':
      return (
        <Input
          name={field.name}
          label={field.label}
          value={field.value !== null ? field.value : ''}
          isRequired={field.is_required}
          maxLength={field.max_length}
          onChange={(event) => handlerChangeFormValues?.(field.id, event)}
        />
      )

    case 'radio':
      return (
        <RadioGroup
          isRequired={field.is_required}
          defaultValue={field.default_value}
          label={field.label}
          choices={field.choices || undefined}
          onChange={(event) => handlerChangeFormValues?.(field.id, event)}
        />
      )

    case 'textarea':
      return (
        <Textarea
          name={field.name}
          label={field.label}
          value={field.value !== null ? field.value : ''}
          isRequired={field.is_required}
          maxLength={field.max_length}
          onChange={(event) => handlerChangeFormValues?.(field.id, event)}
        />
      )

    case 'select':
      return (
        <Select
          name={field.name}
          label={field.label}
          value={field.value !== null ? field.value : ''}
          isRequired={field.is_required}
          choices={field.choices || undefined}
          onChange={(event) => handlerChangeFormValues?.(field.id, event)}
        />
      )

    default:
      return null
  }
}
