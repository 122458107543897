/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { LayoutDefault } from '../../../layouts/Default'
import { apiAuth } from '../../../services/apiAuth'
import { CardContainer } from '../../../components/molecules/CardContainer'
import { Select } from '../../../components/Form/Select'

interface FormData {}

interface ParamsProps {
  id: string
}

interface User {
  id: number
  user: {
    id: number
    name: string
    email: string
    is_active: boolean
    is_specialist: boolean
    is_superuser: boolean
  }
  birth_date: string | null
  telephone: string | null
  social_name: string | null
  role: string | null
  observation: string | null
  image: string | null
}

interface Specialist {
  specialist: {
    id: number
    social_name: string
  }
  agenda_permission: number
  medical_record_permission: number
  payment_checkout: boolean
  check_paid: number
  check_received: number
  invoice: number
}

type GetSpecialists = Array<Specialist>

interface Configs {
  specialistId: number
  specialistName: string
  schedulePermission: string
  medicalRecordPermission: string
  receive: boolean
  paymentConfirmation: string
  confirmationOfReceipt: string
  controlFiscal: string
}

export const UpdateSpecialists = () => {
  const { t } = useTranslation('pageUsers')
  const toast = useToast()
  const router = useHistory()
  const { formState, handleSubmit } = useForm<FormData>({})
  const { id } = useParams<ParamsProps>()
  const [user, setUser] = useState<User | null>(null)
  const [specialists, setSpecialists] = useState<Specialist[]>([])
  const [arrayConfigs, setArrayConfigs] = useState<Configs[]>([])
  const arraySchedulePermissions = [
    { value: '1', label: `${t('specialistConfig.permissions.1')}` },
    { value: '2', label: `${t('specialistConfig.permissions.2')}` },
    { value: '3', label: `${t('specialistConfig.permissions.3')}` }
  ]
  const arrayMedicalPermissions = [
    { value: '1', label: `${t('specialistConfig.permissions.1')}` },
    { value: '2', label: `${t('specialistConfig.permissions.2')}` }
  ]

  useEffect(() => {
    apiAuth.get<User>(`users/${id}/`).then((response) => {
      setUser(response.data)
    })

    return () => {}
  }, [id])

  useEffect(() => {
    const GetResponseSpecialists = () => {
      apiAuth
        .get<GetSpecialists>(`users/${id}/specialist-accesses/`)
        .then((response) => {
          setSpecialists(response.data)
        })
    }

    GetResponseSpecialists()

    return () => {}
  }, [id])

  useEffect(() => {
    if (specialists?.length > 0) {
      const formatArrayConfigs = specialists.map((specialist) => {
        return {
          specialistId: specialist.specialist.id,
          specialistName: specialist.specialist.social_name,
          schedulePermission: String(specialist.agenda_permission),
          medicalRecordPermission: String(specialist.medical_record_permission),
          receive: specialist.payment_checkout,
          paymentConfirmation: String(specialist.check_paid),
          confirmationOfReceipt: String(specialist.check_received),
          controlFiscal: String(specialist.invoice)
        }
      })

      console.log(formatArrayConfigs)

      setArrayConfigs(formatArrayConfigs)
    }
  }, [specialists])

  // handler select new PermissionScheduling
  const handlerUpdatePermissionsScheduling = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const values = [...arrayConfigs]
    if (event.target.value === '1') {
      values[index].schedulePermission = event.target.value
      values[index].receive = false
    } else {
      values[index].schedulePermission = event.target.value
    }

    setArrayConfigs(values)
  }

  // handler select new medicalRecordPermission
  const handlerUpdatePermissionsMedicalRecord = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const values = [...arrayConfigs]
    values[index].medicalRecordPermission = event.target.value

    setArrayConfigs(values)
  }

  // handler switch checked receive
  const handlerUpdateReceive = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const values = [...arrayConfigs]

    if (event.target.checked === true) {
      if (values[index].schedulePermission === '1') {
        values[index].schedulePermission = '2'
        values[index].receive = event.target.checked
      } else {
        values[index].receive = event.target.checked
      }
    }

    if (event.target.checked === false) {
      values[index].receive = event.target.checked
    }

    setArrayConfigs(values)
  }

  // handler switch checked paymentConfirmation
  const handlerUpdatePaymentConfirmation = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const values = [...arrayConfigs]
    values[index].paymentConfirmation = event.target.value

    setArrayConfigs(values)
  }

  // handler switch confirmationOf Receipt
  const handlerUpdateConfirmationOfReceipt = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const values = [...arrayConfigs]
    values[index].confirmationOfReceipt = event.target.value

    setArrayConfigs(values)
  }

  // handler switch checked controlFiscalPermission
  const handlerUpdateControlFiscalPermission = (
    index: number,
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const values = [...arrayConfigs]
    values[index].controlFiscal = event.target.value

    setArrayConfigs(values)
  }

  // format body for create or update specialist access
  const formatSpecialistAccess = () => {
    const arrayFormatValues = arrayConfigs.map((config) => {
      return {
        specialist: {
          id: config.specialistId,
          social_name: config.specialistName
        },
        agenda_permission:
          config.schedulePermission === '0' ? '1' : config.schedulePermission,
        medical_record_permission:
          config.medicalRecordPermission === '0'
            ? '1'
            : config.medicalRecordPermission,
        payment_checkout: config.receive,
        check_paid:
          config.paymentConfirmation === '0' ? '1' : config.paymentConfirmation,
        check_received:
          config.confirmationOfReceipt === '0'
            ? '1'
            : config.confirmationOfReceipt,
        invoice: config.controlFiscal === '0' ? '1' : config.controlFiscal
      }
    })

    return arrayFormatValues
  }

  const handlerSubmitUpdate: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    const payload = formatSpecialistAccess()

    try {
      const { data } = await apiAuth.post(
        `users/${id}/specialist-accesses/`,
        payload
      )
      if (data !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/users')
        }, 2000)
      }
    } catch (err: any) {
      toast({
        title: 'Aconteceu um erro!',
        description: `${err.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  return (
    <LayoutDefault
      onSubmit={handleSubmit(handlerSubmitUpdate)}
      title={`${t('specialistConfig.title')} ${
        user?.social_name ? `(${user.social_name})` : ''
      }`}
      urlBack="/settings/users"
    >
      <Stack w="100%" spacing="4" justifyContent="space-around">
        <Flex
          w="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap="4"
          flexDir="row"
        >
          <Text>{t('specialistConfig.userSelect')}</Text>
          <Text>{user?.user?.name}</Text>
        </Flex>
        <CardContainer title={t('specialistConfig.container1.title')}>
          <Table>
            <Thead>
              <Tr>
                <Th>{t('specialistConfig.specialist')}</Th>
                <Th>{t('specialistConfig.schedule')}</Th>
                <Th>{t('specialistConfig.chart')}</Th>
                <Th>{t('specialistConfig.receive')}</Th>
                <Th>{t('specialistConfig.paymentConfirmation')}</Th>
                <Th>{t('specialistConfig.confirmationOfReceipt')}</Th>
                <Th>{t('specialistConfig.controlFiscal')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {arrayConfigs.length > 0 &&
                arrayConfigs.map((config, index) => (
                  <Tr key={config.specialistId}>
                    <Td>{config.specialistName}</Td>
                    <Td>
                      <Box>
                        <Select
                          options={arraySchedulePermissions}
                          value={config.schedulePermission}
                          placeholder=""
                          name="schedulePermission"
                          onChange={(event) =>
                            handlerUpdatePermissionsScheduling(index, event)
                          }
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <Select
                          options={arrayMedicalPermissions}
                          value={config.medicalRecordPermission}
                          placeholder=""
                          name="medicalRecordPermission"
                          onChange={(event) =>
                            handlerUpdatePermissionsMedicalRecord(index, event)
                          }
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <Switch
                          isChecked={config.receive}
                          name="receive"
                          onChange={(event) =>
                            handlerUpdateReceive(index, event)
                          }
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <Select
                          options={arraySchedulePermissions}
                          value={config.confirmationOfReceipt}
                          placeholder=""
                          name="confirmationOfReceipt"
                          onChange={(event) =>
                            handlerUpdateConfirmationOfReceipt(index, event)
                          }
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <Select
                          options={arraySchedulePermissions}
                          value={config.paymentConfirmation}
                          placeholder=""
                          name="paymentConfirmation"
                          onChange={(event) =>
                            handlerUpdatePaymentConfirmation(index, event)
                          }
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <Select
                          options={arraySchedulePermissions}
                          value={config.controlFiscal}
                          placeholder=""
                          name="controlFiscal"
                          onChange={(event) =>
                            handlerUpdateControlFiscalPermission(index, event)
                          }
                        />
                      </Box>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </CardContainer>
        <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
          <Button
            type="submit"
            size="md"
            fontSize="md"
            bg="blue.300"
            color="#fff"
            isLoading={formState.isSubmitting}
          >
            {t('create.button')}
          </Button>
        </Flex>
      </Stack>
    </LayoutDefault>
  )
}
