import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'
import { useModal } from '../../hooks/useModal'

interface ModalFiltersProps {
  children: ReactNode
  title: string
  applyFilter: () => void
  isHaveDefault?: boolean
  isOpen: boolean
  removeFilter?: () => void
  onClose: () => void
}

export const ModalFilters = ({
  children,
  title,
  applyFilter,
  removeFilter,
  isOpen,
  onClose,
  isHaveDefault
}: ModalFiltersProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? 'full' : 'md'}
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent overflowY="auto">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => {
                removeFilter?.()
              }}
            >
              {isHaveDefault ? 'Config. padrão' : 'Limpar'}
            </Button>
            <Button colorScheme="blue" onClick={() => applyFilter()}>
              Aplicar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
