import React, { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import lodash from 'lodash'
import { useDnd } from '../../hooks/useDnd'

interface Options {
  pos: number
  label: string
  value: string | number
}

interface Fields {
  id: string
  name: string
  isRequired: boolean
  placeholder: string
  type: string
  value: string
  defaultValue: string
  label: string
  maxLength?: number
  options?: Options[]
}

export const useLogic = () => {
  const [t] = useTranslation('pageCreatingForms')
  const toast = useToast()
  const { setListForms, listForms } = useDnd()
  const [nameGroup, setNameGroup] = useState('')

  const handlerAddGroupFields = () => {
    if (nameGroup === '') {
      toast({
        title: `${t('errors.title')}`,
        duration: 3000,
        position: 'top',
        isClosable: true,
        variant: 'solid',
        description: `${t('errors.nameGroup')}`,
        status: 'error'
      })

      return
    }

    setListForms([
      ...listForms,
      {
        id: lodash.uniqueId(),
        name: nameGroup,
        fields: [],
        isVisible: true
      }
    ])
  }

  const handlerAddField = (id: string, index: number, type: string) => {
    const fields: Fields[] = [
      ...listForms[index].fields,
      {
        id: lodash.uniqueId(),
        name: '',
        isRequired: false,
        placeholder: '',
        type,
        value: '',
        defaultValue: '',
        label: '',
        options: [],
        maxLength: undefined,
        error: true
      }
    ]

    const data = {
      fields
    }

    const groupNewValue = listForms.map((group) => {
      return id === group.id ? { ...group, ...data } : group
    })

    setListForms(groupNewValue)
  }

  const handlerChangeNameGroup = (groupId: string) => {
    if (nameGroup === '') {
      toast({
        title: `${t('errors.title')}`,
        duration: 3000,
        position: 'top',
        isClosable: true,
        variant: 'solid',
        description: `${t('errors.nameGroup')}`,
        status: 'error'
      })

      return
    }

    const data = {
      name: nameGroup
    }

    const newValuesGroups = listForms.map((group) => {
      return groupId === group.id ? { ...group, ...data } : group
    })

    setListForms(newValuesGroups)
  }

  return {
    handlerAddGroupFields,
    setNameGroup,
    nameGroup,
    handlerAddField,
    handlerChangeNameGroup
  }
}
