/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
import React, { FC, useRef, useState } from 'react'
import {
  Button,
  CloseButton,
  Flex,
  Text,
  VStack,
  Box,
  Checkbox,
  HStack
} from '@chakra-ui/react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { FiEdit, FiGrid, FiPlusCircle } from 'react-icons/fi'
import { useDrag, useDrop } from 'react-dnd'
import { Input } from '../../Form/Input'
import { useDnd } from '../../../hooks/useDnd'

interface Options {
  pos: number
  label: string
  value: string
}

interface InputBuildProps {
  data: {
    id: string
    name: string
    isRequired: boolean
    placeholder: string
    type: string
    value: string
    defaultValue: string
    label: string
    options?: Array<Options>
    error?: boolean
  }
  onExclude?: () => void
  indexList: number
  index: number
}

interface DropAndDragItem {
  type: string
  id: string | number
  index: number
  name: string
  indexList: number
}

type TypeOptionsData = Array<Options>

export const SelectBuild: FC<InputBuildProps> = ({
  data,
  onExclude,
  indexList,
  index
}) => {
  const [t] = useTranslation('pageCreatingForms')
  const [name, setName] = useState(data.name)
  const [isRequired, setIsRequired] = useState(data.isRequired)
  const [placeholder, setPlaceholder] = useState(data.placeholder)
  const [value, setValue] = useState(data.value)
  const [defaultValue, setDefaultValue] = useState(data.defaultValue)
  const [label, setLabel] = useState(data.label)
  const [showEdit, setShowEdit] = useState(false)
  const [error, setError] = useState(data?.error)
  const [options, setOptions] = useState<TypeOptionsData>(() => {
    if (data?.options !== undefined) {
      if (data?.options.length > 0) {
        return data?.options
      }
    }

    return [
      {
        label: '',
        pos: 0,
        value: ''
      }
    ]
  })

  const handlerAddNewOption = (i: number) => {
    setOptions([
      ...options,
      {
        label: '',
        pos: i + 1,
        value: ''
      }
    ])
  }

  const handlerRemoveOption = (i: number) => {
    const values = [...options]
    values.splice(i, 1)

    const newValues = values.map((option, indexOption) => {
      return {
        pos: indexOption + 1,
        label: option.label,
        value: option.value
      }
    })

    setOptions(newValues)
  }

  const handlerInputChangeLabel = (
    event: React.ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    const values = [...options]
    const valuesAux = values.slice(i, i + 1)
    const newValue = valuesAux.map((option) => {
      return {
        pos: option.pos,
        label: event.currentTarget.value,
        value: event.currentTarget.value
      }
    })

    const valuesChange = values
    valuesChange[i] = newValue[0]

    setOptions(values)
  }

  const { MoveForm, listForms, setListForms } = useDnd()
  const ref = useRef<any>(null)
  const styledGrabbing = {
    border: '2px dashed rgba(0, 0, 0, 0.2)',
    pt: '5px',
    borderRadius: '0',
    bg: '#d3d3d3',
    cursor: 'grabbing'
  }
  const [{ isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    type: 'GROUP',
    item: { type: 'GROUP', id: data?.id, index, name: data?.name, indexList }
  })

  const [, dropRef] = useDrop({
    accept: 'GROUP',
    hover(item: DropAndDragItem, monitor) {
      const draggedListIndex = item.indexList
      const targetListIndex = indexList

      const draggedIndex = item.index
      const targetIndex = index

      if (
        draggedIndex === targetIndex &&
        draggedListIndex === targetListIndex
      ) {
        return
      }

      const targetSize = ref?.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const coordY = draggedOffset !== undefined ? draggedOffset?.y : 1
      const coordX = draggedOffset !== undefined ? draggedOffset?.x : 1
      const draggedTop = coordY !== undefined ? coordY - targetSize.top : 1

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return
      }

      MoveForm(draggedListIndex, targetListIndex, draggedIndex, targetIndex)

      item.index = targetIndex
      item.indexList = targetListIndex
    }
  })

  dragRef(dropRef(ref))

  // return error options jsx
  const returnOptionsError = () => {
    let isError = false

    options?.forEach((option) => {
      if (option?.value === '') {
        isError = true
      }
    })

    if (isError) {
      return (
        <Text fontSize="14px" color="red">
          {t('errors.type.2')}
        </Text>
      )
    }

    return null
  }

  // valid form
  const internalErrorForm = () => {
    let isError = false

    if (label === '') {
      isError = true

      return isError
    }

    options?.forEach((option) => {
      if (option.value === '') {
        isError = true

        return isError
      }

      return isError
    })

    return isError
  }

  const handlerChangeFieldValue = () => {
    const internalError = internalErrorForm()

    const group = listForms[indexList]
    const object = {
      isRequired,
      defaultValue,
      name: uuidV4(),
      label,
      placeholder,
      options,
      error: internalError
    }

    const fields = group.fields.map((field) => {
      return field.id === data.id ? { ...field, ...object } : field
    })

    const objectField = {
      fields
    }

    const newGroupValues = listForms.map((groupList, indexGroup) => {
      return indexList === indexGroup
        ? { ...groupList, ...objectField }
        : groupList
    })

    return newGroupValues
  }

  return (
    <>
      <Flex
        w="100%"
        h="60px"
        ref={ref}
        bgColor="white"
        p="2"
        justifyContent="space-between"
        style={isDragging ? styledGrabbing : undefined}
        border={error ? '1px solid red' : '1px solid #808080'}
        alignItems="center"
        borderRadius={8}
      >
        <Flex gap={2} direction="row">
          <FiGrid size={22} color="gray" />
          {/* question or label form */}
          <Text>{label}</Text>
          <Text fontSize="14px">{t('fields.3')}</Text>
          <Text fontSize="14px" fontWeight="hairline">
            {options.length === 1
              ? `(${options.length} ${t('option')}`
              : `(${options.length} ${t('options')}`}
          </Text>
          {isRequired ? <Text fontSize="14px">{t('isRequired')}</Text> : null}
          {label === '' ? (
            <Text fontSize="14px" color="red">
              {t('errors.type.1')}
            </Text>
          ) : null}
          {returnOptionsError()}
        </Flex>
        <Flex gap={4}>
          <Button
            colorScheme={!showEdit ? 'green' : 'blue'}
            color={!showEdit ? 'white' : 'white'}
            size="sm"
            fontSize={14}
            onClick={() => {
              setShowEdit(!showEdit)
              if (showEdit) {
                const newValuesGroups = handlerChangeFieldValue()

                setListForms(newValuesGroups)
              }
            }}
          >
            {!showEdit ? <FiEdit /> : `${t('saveEdit')}`}
          </Button>
          <CloseButton
            bg="red"
            color="white"
            onClick={() => {
              onExclude?.()
            }}
          />
        </Flex>
      </Flex>
      {showEdit ? (
        <VStack
          w="100%"
          p="2"
          spacing="2"
          alignItems="baseline"
          justifyContent="flex-start"
        >
          <Input
            name="label"
            label={`${t('question')}`}
            value={label}
            onChange={(event) => setLabel(event.target.value)}
          />
          <Box>
            <Text fontSize="14px">{t('questionIsRequired')}</Text>
            <Checkbox
              name="isRequired"
              defaultChecked={isRequired}
              onChange={(event) => setIsRequired(event.target.checked)}
            />
          </Box>
          <Flex
            w="100%"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
          >
            <Text my="4" fontSize="14px">
              {t('configOptions')}
            </Text>
            <VStack spacing="4">
              {options.map((option, indexOption) => (
                <Flex
                  key={option.pos}
                  w="100%"
                  gap={6}
                  alignItems="end"
                  justifyContent="flex-start"
                >
                  <Flex
                    w="30px"
                    borderRadius={15}
                    h="30px"
                    bgColor="green.500"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text color="white">{indexOption + 1}</Text>
                  </Flex>
                  <Box>
                    <Input
                      name="labelSelect"
                      value={option.label}
                      label="Item"
                      onChange={(event) =>
                        handlerInputChangeLabel(event, indexOption)
                      }
                    />
                  </Box>
                  <Button
                    colorScheme="green"
                    color="white"
                    size="sm"
                    fontSize={18}
                    onClick={() => handlerAddNewOption(indexOption)}
                  >
                    <FiPlusCircle />
                  </Button>
                  {options.length > 1 && (
                    <CloseButton
                      onClick={() => handlerRemoveOption(indexOption)}
                    />
                  )}
                </Flex>
              ))}
            </VStack>
          </Flex>
        </VStack>
      ) : null}
    </>
  )
}
