/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

interface Specialist {
  id: number
  user: {
    id: number
    name: string
  }
  social_name: string
}

type SpecialistData = Array<Specialist>

type GetResponse = {
  results: SpecialistData
}

export const useService = () => {
  const toast = useToast()
  const router = useHistory()
  const [specialists, setSpecialists] = useState<SpecialistData>([])

  useEffect(() => {
    apiAuth
      .get<GetResponse>(`users/`, {
        params: {
          is_active: true,
          is_specialist: true
        }
      })
      .then((response) => {
        setSpecialists(response.data.results)
      })
  }, [])

  const createNewDynamicForm = async (payload: object) => {
    try {
      const { data } = await apiAuth.post(`clinics/forms/`, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/forms')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Erro ao Criar Formulário!',
        description: `${error?.response?.data?.error_message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  const updateDynamicForm = async (payload: object, id: string) => {
    try {
      const { data } = await apiAuth.put(`clinics/forms/${id}/`, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Atualização realizada com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/forms')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Erro ao Atualizar Formulário!',
        description: `${error?.response?.data?.error_message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  return {
    specialists,
    createNewDynamicForm,
    updateDynamicForm
  }
}
