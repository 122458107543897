import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

interface Choices {
  value: string
  label: string
}

interface InputProps extends ChakraSelectProps {
  name: string
  label?: string
  error?: FieldError
  isRequired?: boolean
  children?: ReactNode
  choices?: Choices[]
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, InputProps> = (
  { name, label, error, isRequired, choices, children, ...rest },
  ref
) => {
  const [t] = useTranslation('pageAttendanceScreen')

  return (
    <FormControl mr="4" isRequired={isRequired} isInvalid={!!error}>
      {!!label && (
        <FormLabel mt={6} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <ChakraSelect
        name={name}
        id={name}
        color="gray.500"
        focusBorderColor="gray.900"
        bgColor="AppWorkspace"
        variant="flushed"
        _hover={{ bgColor: 'gray.50' }}
        size="lg"
        ref={ref}
        {...rest}
      >
        <option value="">{t('infoSelect')}</option>
        {choices !== undefined &&
          choices.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </ChakraSelect>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase)
