/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
import { format } from 'date-fns'
import React, { useState } from 'react'
import { v4 as uuidV4 } from 'uuid'
import { Form, useService } from './services'

interface Indications {
  id: string
  service: {
    value: string
    label: string
  } | null
  observation: string
  indicationDate: string
}

interface DraftsData {
  id: string
  isVisible: boolean
  isChange: boolean
  baseContent: string | null
  isPreference: boolean
  selected: {
    value: string
    label: string
  } | null
}

interface DraftsDataToken {
  id: string
  isVisible: boolean
  isChange: boolean
  baseContent: string | null
  isPreference: boolean
  selected: {
    value: string
    label: string
  } | null
  form?: Form
}

interface ContentDraft {
  id: string
  content: string
  backContentRender?: string
}

type IndicationsData = Array<Indications>

type TokenDraftsData = Array<DraftsDataToken> | []

type AllDraftsData = Array<DraftsData> | []

type ContentDraftData = Array<ContentDraft> | []

export const useLogic = () => {
  const services = useService()
  const [observation, setObservation] = useState('')
  const [indications, setIndications] = useState<IndicationsData>([
    {
      id: uuidV4(),
      service: null,
      observation: '',
      indicationDate: format(new Date(), 'yyyy-MM-dd')
    }
  ])

  // token
  const [tokens, setTokens] = useState<TokenDraftsData>([])
  const [contentsToken, setContentsT] = useState<ContentDraftData>([])

  // prescriptions
  const [prescriptions, setPrescriptions] = useState<AllDraftsData>([])
  const [contentsPrescriptions, setContentsP] = useState<ContentDraftData>([])

  // exams
  const [examsRequest, setExamsRequest] = useState<AllDraftsData>([])
  const [contentsExams, setContentsE] = useState<ContentDraftData>([])

  const handlerAddNewIndications = () => {
    setIndications([
      ...indications,
      {
        service: null,
        observation: '',
        id: uuidV4(),
        indicationDate: format(new Date(), 'yyyy-MM-dd')
      }
    ])
  }

  const handlerSelectIndication = (id: string, event: any) => {
    const newIndications = [...indications]

    newIndications.forEach((indication) => {
      if (id === indication.id) {
        indication['service'] = event || null
      }
    })

    setIndications(newIndications)
  }

  const handlerChangeObservationOfIndication = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newIndications = [...indications]

    newIndications.forEach((indication) => {
      if (id === indication.id) {
        indication['observation'] = event.target.value
      }
    })

    setIndications(newIndications)
  }

  const handlerChangeIndicationsDate = (
    id: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newIndications = [...indications]

    newIndications.forEach((indication) => {
      if (id === indication.id) {
        indication['indicationDate'] = event.target.value
      }
    })

    setIndications(newIndications)
  }

  const handlerRemoveIndications = (index: number) => {
    const oldIndications = [...indications]
    oldIndications.splice(index, 1)

    setIndications(oldIndications)
  }

  // token add, remove, getValue, isChange, isVisible
  const handlerAddNewToken = () => {
    if (tokens.length === 0) {
      setTokens([
        {
          id: uuidV4(),
          isChange: false,
          isVisible: true,
          baseContent: null,
          isPreference: true,
          selected: null,
          form: undefined
        }
      ])

      setContentsT([
        {
          id: uuidV4(),
          content: ''
        }
      ])
    }

    setTokens([
      ...tokens,
      {
        id: uuidV4(),
        isChange: false,
        isVisible: true,
        baseContent: null,
        isPreference: true,
        selected: null,
        form: undefined
      }
    ])

    setContentsT([
      ...contentsToken,
      {
        id: uuidV4(),
        content: ''
      }
    ])
  }

  const handlerRemoveToken = (index: number) => {
    const oldTokens = [...tokens]
    oldTokens.splice(index, 1)

    const oldContentsToken = [...contentsToken]
    oldContentsToken.splice(index, 1)

    setContentsT(oldContentsToken)
    setTokens(oldTokens)
  }

  const getValueDraftTokenById = (value: any, index: number) => {
    const data = {
      content: value
    }

    if (tokens.length === contentsToken.length) {
      const newData = contentsToken.map((cont, i) => {
        return index === i ? { ...cont, ...data } : cont
      })

      setContentsT(newData)
    }
  }

  const handlerClickIsVisibleToken = (id: string, change: boolean) => {
    if (change === true) {
      const data = {
        isVisible: false
      }

      const newTokens = tokens.map((token) => {
        return id === token.id ? { ...token, ...data } : token
      })

      setTokens(newTokens)
    } else {
      const data = {
        isVisible: true
      }

      const newTokens = tokens.map((token) => {
        return id === token.id ? { ...token, ...data } : token
      })

      setTokens(newTokens)
    }
  }

  const handlerClickIsPreferenceToken = (id: string, change: boolean) => {
    if (change === true) {
      const data = {
        isPreference: false
      }

      const newTokens = tokens.map((token) => {
        return id === token.id ? { ...token, ...data } : token
      })

      setTokens(newTokens)
    } else {
      const data = {
        isPreference: true
      }

      const newTokens = tokens.map((token) => {
        return id === token.id ? { ...token, ...data } : token
      })

      setTokens(newTokens)
    }
  }

  const handlerSelectFormToken = (id: string, event: any) => {
    const getFormSelect = services.formsComplete.find(
      (form) => String(form.id) === event.value
    )

    const data = {
      selected: event,
      form: getFormSelect
    }

    const newTokens = tokens.map((token) => {
      return id === token.id ? { ...token, ...data } : token
    })

    setTokens(newTokens)
  }

  const handlerClearSelectAndBackToDraftToken = (id: string) => {
    const data = {
      selected: null,
      form: undefined
    }

    const newTokens = tokens.map((token) => {
      return id === token.id ? { ...token, ...data } : token
    })

    setTokens(newTokens)
  }

  // prescriptions add, remove, getValue, isChange, isVisible
  const handlerAddNewPrescription = () => {
    if (prescriptions.length === 0) {
      setPrescriptions([
        {
          id: uuidV4(),
          isChange: false,
          isVisible: true,
          baseContent: null,
          isPreference: true,
          selected: null
        }
      ])

      setContentsP([
        {
          id: uuidV4(),
          content: ''
        }
      ])
    }

    setPrescriptions([
      ...prescriptions,
      {
        id: uuidV4(),
        isChange: false,
        isVisible: true,
        baseContent: null,
        isPreference: true,
        selected: null
      }
    ])

    setContentsP([
      ...contentsPrescriptions,
      {
        id: uuidV4(),
        content: ''
      }
    ])
  }

  const handlerRemovePrescription = (index: number) => {
    const oldPrescriptions = [...prescriptions]
    oldPrescriptions.splice(index, 1)

    const oldContentPrescriptions = [...contentsPrescriptions]
    oldContentPrescriptions.splice(index, 1)

    setContentsP(oldContentPrescriptions)
    setPrescriptions(oldPrescriptions)
  }

  const handlerSelectPrescription = (id: string, index: number, event: any) => {
    const values = [...prescriptions]
    const getPrescription = services.prescriptionsComplete.find(
      (pres) => String(pres.id) === event.value
    )

    const data = {
      content: getPrescription?.prescription_detail || '',
      backContentRender: getPrescription?.prescription_detail || undefined
    }

    const dataSelect = {
      selected: event
    }

    const newPrescription = values.map((pres) => {
      return id === pres.id ? { ...pres, ...dataSelect } : pres
    })

    const newData = contentsPrescriptions.map((cont, i) => {
      return index === i ? { ...cont, ...data } : cont
    })

    setContentsP(newData)
    setPrescriptions(newPrescription)
  }

  const getValueDraftPrescriptionById = (value: any, index: number) => {
    const data = {
      content: value
    }

    if (prescriptions.length === contentsPrescriptions.length) {
      const newData = contentsPrescriptions.map((cont, i) => {
        return index === i ? { ...cont, ...data } : cont
      })

      setContentsP(newData)
    }
  }

  const handlerClickIsPreferencePrescription = (
    id: string,
    change: boolean
  ) => {
    if (change === true) {
      const data = {
        isPreference: false
      }

      const newPrescriptions = prescriptions.map((pres) => {
        return id === pres.id ? { ...pres, ...data } : pres
      })

      setPrescriptions(newPrescriptions)
    } else {
      const data = {
        isPreference: true
      }

      const newPrescriptions = prescriptions.map((pres) => {
        return id === pres.id ? { ...pres, ...data } : pres
      })

      setPrescriptions(newPrescriptions)
    }
  }

  const handlerClickIsVisiblePrescription = (id: string, change: boolean) => {
    if (change === true) {
      const data = {
        isVisible: false
      }

      const newPrescription = prescriptions.map((prescription) => {
        return id === prescription.id
          ? { ...prescription, ...data }
          : prescription
      })

      setPrescriptions(newPrescription)
    } else {
      const data = {
        isVisible: true
      }

      const newPrescription = prescriptions.map((prescription) => {
        return id === prescription.id
          ? { ...prescription, ...data }
          : prescription
      })

      setPrescriptions(newPrescription)
    }
  }

  // examsRequest add, remove, getValue, isChange, isVisible
  const handlerAddNewExamRequest = () => {
    if (examsRequest.length === 0) {
      setExamsRequest([
        {
          id: uuidV4(),
          isChange: false,
          isVisible: true,
          baseContent: null,
          isPreference: true,
          selected: null
        }
      ])

      setContentsE([
        {
          id: uuidV4(),
          content: ''
        }
      ])
    }

    setExamsRequest([
      ...examsRequest,
      {
        id: uuidV4(),
        isChange: false,
        isVisible: true,
        baseContent: null,
        isPreference: true,
        selected: null
      }
    ])

    setContentsE([
      ...contentsExams,
      {
        id: uuidV4(),
        content: ''
      }
    ])
  }

  const handlerRemoveExamRequest = (index: number) => {
    const oldExams = [...examsRequest]
    oldExams.splice(index, 1)

    const oldContentExams = [...contentsExams]
    oldContentExams.splice(index, 1)

    setContentsE(oldContentExams)
    setExamsRequest(oldExams)
  }

  const handlerSelectExamRequest = (id: string, index: number, event: any) => {
    const values = [...examsRequest]
    const getExam = services.examsComplete.find(
      (exam) => String(exam.id) === event.value
    )

    const data = {
      content: getExam?.exam_detail || '',
      backContentRender: getExam?.exam_detail || undefined
    }

    const dataSelect = {
      selected: event
    }

    const newExams = values.map((exam) => {
      return id === exam.id ? { ...exam, ...dataSelect } : exam
    })

    const newData = contentsExams.map((cont, i) => {
      return index === i ? { ...cont, ...data } : cont
    })

    setContentsE(newData)
    setExamsRequest(newExams)
  }

  const getValueDraftExamRequestById = (value: any, index: number) => {
    const data = {
      content: value
    }

    if (examsRequest.length === contentsExams.length) {
      const newData = contentsExams.map((cont, i) => {
        return index === i ? { ...cont, ...data } : cont
      })

      setContentsE(newData)
    }
  }

  const handlerClickIsVisibleExamRequest = (id: string, change: boolean) => {
    if (change === true) {
      const data = {
        isVisible: false
      }

      const newExams = examsRequest.map((exams) => {
        return id === exams.id ? { ...exams, ...data } : exams
      })

      setExamsRequest(newExams)
    } else {
      const data = {
        isVisible: true
      }

      const newExams = examsRequest.map((exams) => {
        return id === exams.id ? { ...exams, ...data } : exams
      })

      setExamsRequest(newExams)
    }
  }

  const handlerClickIsPreferenceExamRequest = (id: string, change: boolean) => {
    if (change === true) {
      const data = {
        isPreference: false
      }

      const newExam = examsRequest.map((exam) => {
        return id === exam.id ? { ...exam, ...data } : exam
      })

      setExamsRequest(newExam)
    } else {
      const data = {
        isPreference: true
      }

      const newExam = examsRequest.map((exam) => {
        return id === exam.id ? { ...exam, ...data } : exam
      })

      setExamsRequest(newExam)
    }
  }

  return {
    setObservation,
    observation,
    setIndications,
    indications,
    setContentsT,
    setContentsE,
    setContentsP,
    handlerChangeObservationOfIndication,
    handlerAddNewIndications,
    handlerRemoveIndications,
    handlerSelectIndication,
    setPrescriptions,
    prescriptions,
    handlerRemovePrescription,
    handlerAddNewPrescription,
    getValueDraftPrescriptionById,
    handlerClickIsVisiblePrescription,
    handlerClickIsPreferencePrescription,
    tokens,
    setTokens,
    handlerAddNewToken,
    handlerRemoveToken,
    getValueDraftTokenById,
    handlerClickIsVisibleToken,
    handlerClickIsPreferenceToken,
    handlerSelectFormToken,
    handlerClearSelectAndBackToDraftToken,
    examsRequest,
    setExamsRequest,
    handlerAddNewExamRequest,
    handlerRemoveExamRequest,
    handlerSelectExamRequest,
    getValueDraftExamRequestById,
    handlerClickIsVisibleExamRequest,
    handlerClickIsPreferenceExamRequest,
    contentsPrescriptions,
    handlerSelectPrescription,
    contentsToken,
    contentsExams,
    handlerChangeIndicationsDate
  }
}
