/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

const BASE_URL = 'clinics/ways-receives/'

export const useService = () => {
  const [loadingRest, setLoadingRest] = useState(false)

  // terceiros
  const router = useHistory()
  const toast = useToast()

  // função para criar
  async function createRequest(body: any) {
    try {
      setLoadingRest(true)

      const response = await apiAuth.post(BASE_URL, body)

      if (response.data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/financial/receiving-method')
        }, 2000)
      }
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // função para editar
  async function updateRequest(body: any, id: number) {
    try {
      setLoadingRest(true)
      const res = await apiAuth.put(`${BASE_URL}${id}/`, body)

      console.log(res)

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/financial/receiving-method')
      }, 2000)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // export
  return {
    createRequest,
    updateRequest,
    loadingRest
  }
}
