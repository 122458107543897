import { ReactNode } from 'react'
import {
  Flex,
  Box,
  IconButton,
  Icon,
  useBreakpointValue
} from '@chakra-ui/react'
import { RiMenuLine } from 'react-icons/ri'
import { Logo } from './Logo'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'

interface HeaderProps {
  children?: ReactNode
}

export function Header({ children }: HeaderProps) {
  const { handlerOpenSideBarDrawer } = useSidebarDrawer()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Flex
        as="header"
        w="100vw"
        h="50px"
        mx="auto"
        px="8"
        align="center"
        bg="#fff"
        position="fixed"
        top="0"
        left="auto"
        right="auto"
        zIndex="99"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
      >
        <Flex d="flex" flexDirection="row">
          {!isWideVersion && (
            <IconButton
              aria-label="Open navigation"
              icon={<Icon as={RiMenuLine} />}
              fontSize="24"
              variant="outline"
              onClick={() => handlerOpenSideBarDrawer()}
              mr="2"
            />
          )}
          {isWideVersion && <Logo />}
        </Flex>
        <Flex
          w="100%"
          d="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {children}
        </Flex>
      </Flex>

      <Box height="50px" />
    </>
  )
}
