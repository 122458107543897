/* eslint-disable camelcase */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { SketchPicker } from 'react-color'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiOutlineBgColors } from 'react-icons/ai'
import * as yup from 'yup'
import { ArrowBack } from '../../../components/atoms/arrowBack'
import { Input } from '../../../components/Form/Input'
import { Switch } from '../../../components/Form/Switch'
import { Textarea } from '../../../components/Form/Textarea'
import { TextFieldControlled } from '../../../components/Form/TextFieldControlled'
import { Header } from '../../../components/Header'
import { ModalArrivedTime } from '../../../components/Modal/confirmArrivedTime'
import { ModalWebcam } from '../../../components/Modal/modalWebcam'
import { CardContainer } from '../../../components/molecules/CardContainer'
import { Sidebar } from '../../../components/Sidebar'
import { WebcamCapture } from '../../../components/Webcam'
import { ImageComponent } from '../../../components/Webcam/ImageComponent'
import { useDocument } from '../../../hooks/useDocument'
import Dropzone from './Dropzone'
import { useLogicUser } from './Logic'
import { useService } from './service'

interface UsersData {
  birth_date: string
  email: string
  observation: string
  contact: string
  password: string
  is_active: boolean
  is_specialist: boolean
}

const createNewUserFormSchema = yup.object().shape({
  password: yup
    .string()
    .required('Senha obrigatória')
    .min(6, 'No mínimo 6 caracteres')
})

export function UsersCreate() {
  // hooks outers
  const { t } = useTranslation('pageUsers')
  const { imgSrc } = useDocument()
  // hooks
  const service = useService()
  const logic = useLogicUser()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<UsersData>({
    resolver: yupResolver(createNewUserFormSchema)
  })
  const { errors } = formState
  // states modal
  const [isOpen, setIsOpen] = useState(false)
  // color picker
  const [color, setColor] = useState('#fff')
  const [isOpenColorModal, setIsOpenColorModal] = useState(false)

  const handleCreateUser: SubmitHandler<UsersData> = async (values) => {
    const stringValue = imgSrc.substring(0, 4)
    const payload = {
      user: {
        name: logic.name,
        email: values.email,
        password: values.password,
        is_active: values.is_active,
        is_specialist: values.is_specialist,
        is_superuser: logic.superuser
      },
      document: logic.document === '' ? null : logic.document,
      color: color === '#fff' ? undefined : color,
      social_name: logic.socialName,
      telephone: values.contact !== '' ? values.contact : undefined,
      birth_date: values.birth_date !== '' ? values.birth_date : undefined,
      role: logic.occupation,
      observation: values.observation !== '' ? values.observation : undefined,
      image: stringValue === 'http' ? undefined : imgSrc,
      user_contacts: []
    }

    service.handleCreateNewUser(payload)
  }

  // Close modal
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('create.title')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/settings/users">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleCreateUser)}
        >
          <Stack spacing="4" w="100%">
            <CardContainer title={t('create.container1.title')}>
              <Stack position="absolute" top="4" right="4" direction="row">
                <Tooltip
                  label={t('create.color')}
                  aria-label={t('create.color')}
                  placement="left-end"
                >
                  <Flex
                    w="25px"
                    h="25px"
                    borderRadius="full"
                    border="1px solid #121214"
                    justifyContent="center"
                    alignItems="center"
                    bg="white"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => setIsOpenColorModal(true)}
                  >
                    <AiOutlineBgColors color="#121214" />
                  </Flex>
                </Tooltip>
                {color !== '#fff' && (
                  <Box w="25px" h="25px" borderRadius="6" bg={color} />
                )}
              </Stack>
              <Flex direction="row">
                <Flex flexDirection="column">
                  {/* Image for user */}
                  {imgSrc ? <ImageComponent /> : <Dropzone />}
                  {/* Open Modal Webcam */}
                  <Button
                    size="md"
                    fontSize="md"
                    bg="blue.300"
                    color="#fff"
                    onClick={() => setIsOpen(true)}
                  >
                    {t('create.container1.btnWebcam')}
                  </Button>
                </Flex>
                <Flex ml="8" flex="1" direction="column">
                  <Stack direction="row" my="2" spacing="6">
                    <Box w="80%">
                      <TextFieldControlled
                        label={t('create.container1.i1')}
                        isRequired
                        textAlign="left"
                        placeholder={t('create.container1.i1')}
                        name="name"
                        value={logic.name}
                        onChange={(event) => {
                          logic.setName(event.target.value.toUpperCase())
                          if (event.currentTarget.value.length <= 20) {
                            logic.setSocialName(
                              event.target.value.toUpperCase()
                            )
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      <Input
                        label={t('create.container1.i2')}
                        type="date"
                        {...register('birth_date')}
                        error={errors.birth_date}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" my="2" spacing="6">
                    <Box w="40%">
                      <Input
                        label={t('create.container1.i3')}
                        isRequired
                        {...register('email')}
                        error={errors.email}
                      />
                    </Box>
                    <Box w="30%">
                      <Input
                        label={t('create.container1.i7')}
                        isRequired
                        name="socialName"
                        value={logic.socialName}
                        onChange={(event) =>
                          logic.setSocialName(event.target.value.toUpperCase())
                        }
                        maxLength={25}
                      />
                    </Box>
                    <Box w="30%">
                      <Input
                        label={t('create.container1.i8')}
                        name="document"
                        value={logic.document}
                        onChange={(event) =>
                          logic.setDocument(event.target.value)
                        }
                        maxLength={25}
                      />
                    </Box>
                    <Box>
                      <TextFieldControlled
                        mask="number"
                        textAlign="left"
                        placeholder={t('create.container1.i4')}
                        label={t('create.container1.i4')}
                        {...register('contact')}
                        error={errors.contact}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" my="2" spacing="6">
                    <Box w="86%">
                      <Textarea
                        label={t('create.container1.i5')}
                        {...register('observation')}
                        error={errors.observation}
                      />
                    </Box>
                    <Box>
                      <Input
                        label={t('create.container1.i6')}
                        type="password"
                        isRequired
                        {...register('password')}
                        error={errors.password}
                      />
                    </Box>
                  </Stack>
                </Flex>
              </Flex>
            </CardContainer>

            <CardContainer title={t('create.containerPer.title')}>
              <Stack
                w="100%"
                direction={['column', 'row']}
                my="2"
                spacing="4"
                justifyContent="space-between"
              >
                <Stack>
                  <Stack
                    direction="row"
                    my="2"
                    spacing="6"
                    w="100%"
                    justifyContent="space-between"
                  >
                    <Box w="400px">
                      <Input
                        label={t('create.containerPer.iFunction')}
                        name="occupation"
                        value={logic.occupation}
                        onChange={(event) =>
                          logic.setOccupation(event.target.value.toUpperCase())
                        }
                      />
                    </Box>
                    <Box>
                      <Switch
                        label={t('create.containerPer.specialist')}
                        {...register('is_specialist')}
                      />
                    </Box>
                    <Box>
                      <Switch
                        label={t('create.containerPer.superuser')}
                        name="superuser"
                        onChange={(event) => {
                          logic.SetSuperuser(event.target.checked)
                        }}
                      />
                    </Box>
                    <Box>
                      <Switch
                        mx="4"
                        label={t('create.containerPer.active')}
                        defaultChecked
                        {...register('is_active')}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </CardContainer>

            <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState.isSubmitting}
              >
                {t('create.button')}
              </Button>
            </Flex>
          </Stack>
        </Flex>
      </Box>
      <ModalWebcam
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Flex mx="2px" h="500px">
          <WebcamCapture />
        </Flex>
      </ModalWebcam>
      <ModalArrivedTime
        title={t('modalColorPicker.title')}
        isOpen={isOpenColorModal}
        onClose={() => setIsOpenColorModal(false)}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Flex mx="2px" h="400px">
          <Stack
            w="100%"
            direction={['column', 'row']}
            my="2"
            spacing="4"
            justifyContent="space-between"
          >
            <Flex flexDir="row" gap="2">
              <SketchPicker
                color={color}
                onChangeComplete={({ hex }) => setColor(hex)}
              />
            </Flex>
            <Stack w="100%">
              <Stack
                direction="row"
                my="2"
                spacing="4"
                w="100%"
                justifyContent="flex-start"
              >
                {color !== '#fff' && (
                  <Box w="100px" borderRadius="full" dir="row">
                    <Avatar
                      name={logic.name}
                      color="white"
                      bg={color}
                      w="80px"
                      h="80px"
                      height="100%"
                    />
                  </Box>
                )}
                <Text ml="6" fontWeight="hairline">
                  {t('modalColorPicker.message')}
                </Text>
              </Stack>
              <Box w="100%" bg={color} borderRadius={16} h="200px" />
            </Stack>
          </Stack>
        </Flex>
      </ModalArrivedTime>
    </>
  )
}
