import React, { ReactNode } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'
import { MdClearAll } from 'react-icons/md'
import { useDocument } from '../../hooks/useDocument'

interface ModalUploadFilesProps {
  children: ReactNode
  title: string
  isOpen: boolean
  onClose: () => void
}

export const ModalUploadFiles: React.FC<ModalUploadFilesProps> = ({
  isOpen,
  children,
  onClose,
  title
}) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? 'md' : 'sm'}
        blockScrollOnMount={false}
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent top="-4" overflow="hidden">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody overflow="auto">{children}</ModalBody>
          <ModalFooter>
            <Button size="xs" colorScheme="red" mx="2" onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
