/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/auth'
import { apiAuth } from '../../../services/apiAuth'

export type SuppliersData = {
  id?: number
  name: string
  sale_price: string
  cost_price: string
  product_supplier: string
  is_active?: boolean
}

const BASE_URL = 'clinics/payment-sources/'

export const useService = () => {
  // hooks
  const { clinicId } = useAuth()

  // estados
  const [data, setData] = useState<SuppliersData[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingRest, setLoadingRest] = useState(false)
  // estados paginacao
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [offset, setOffset] = useState(0)
  const [filter, setFilter] = useState(false)

  // filtros
  const [isActived, setIsActived] = useState(true)
  const [searchState, setSearch] = useState('')

  // terceiros
  const router = useHistory()
  const toast = useToast()
  let debounce: any = null

  // funcao para criar
  async function createRequest(body: any) {
    try {
      setLoadingRest(true)

      const response = await apiAuth.post(BASE_URL, body)

      if (response.data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/financial/payment-sources')
        }, 2000)
      }
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // funcao para editar
  async function updateRequest(body: any, id: number) {
    try {
      setLoadingRest(true)
      const res = await apiAuth.put(`${BASE_URL}${id}/`, body)

      console.log(res)

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/financial/payment-sources')
      }, 2000)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // paginacao
  async function capturePagination(limitArg?: any, offsetArg?: any) {
    try {
      const response = await apiAuth.get(
        `clinics/${clinicId}/tags/?limit=${limitArg}&offset=${
          offsetArg || offset
        }`
      )
      setData(response.data.results)

      console.log(response)
    } catch (error) {
      console.log('Erro ao buscar os tags (hook)')
    } finally {
      setLoading(false)
    }
  }

  async function onPrev() {
    const offsetAux = offset - 1
    setOffset(offsetAux)
    setPage(page - 1)
    setLoading(true)

    capturePagination(limit, offsetAux)
  }

  async function onNext() {
    const offsetAux = offset + 1
    setOffset(offsetAux)
    setPage(page + 1)
    setLoading(true)

    capturePagination(limit, offsetAux)
  }

  // logica de filto
  async function getAll(is_actived?: boolean, search?: string) {
    try {
      const params = {
        is_active: is_actived,
        name__icontains: search || ''
      }

      const response = await apiAuth.get(
        `clinics/${clinicId}/payment-sources/`,
        {
          params
        }
      )

      setData(response.data.results)
    } catch (error) {
      console.log('Erro ao buscar os patients (hook)')
    } finally {
      setLoading(false)
    }
  }

  async function getSearchInput(search: string) {
    try {
      setLoading(true)
      const params = {
        is_actived: isActived,
        name__icontains: search || undefined
      }

      const response = await apiAuth.get(
        `clinics/${clinicId}/payment-sources/`,
        {
          params
        }
      )

      // const response = await apiAuth.get(
      //   `clinics/products/?is_active=${}&name__icontains=${search}`
      // )

      setData(response.data.results)
    } catch (error) {
      console.log('Erro ao buscar os patients (hook)')
    } finally {
      setLoading(false)
    }
  }

  // busca
  function searchFNInput(event: any): any {
    clearInterval(debounce)

    if (event.length > 1) {
      debounce = setTimeout(() => {
        setSearch(event)
        getSearchInput(event)
      }, 400)
    } else {
      setSearch('')
      setLoading(true)
      getAll(isActived, '')
    }
  }

  // is_actived
  function captureActiveChange(e: any) {
    setLoading(true)
    setIsActived(!isActived)
    console.log(searchState)

    getAll(!isActived, searchState)
  }

  // export
  return {
    data,
    createRequest,
    updateRequest,
    loading,
    loadingRest,
    capturePagination,
    onNext,
    onPrev,
    page,
    getAll,
    searchFNInput,
    captureActiveChange
  }
}
