import { CloseButton, Spinner } from '@chakra-ui/react'
import * as C from './styles'
import { useDocument } from '../../../hooks/useDocument'

export const ImageComponent = () => {
  const { imgSrc, handlerRemoveImage } = useDocument()

  return (
    <C.Container>
      {imgSrc ? (
        <>
          <img src={imgSrc} alt="perfil" />
          <CloseButton
            bg="white"
            _hover={{ bg: 'red.600', color: 'white' }}
            onClick={() => handlerRemoveImage()}
          />
        </>
      ) : (
        <>
          <Spinner size="large" color="blue" />
        </>
      )}
    </C.Container>
  )
}
