import React from 'react'
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom'
import ls from 'localstorage-slim'

interface RouteVisitantProps extends RouteProps {
  component: React.ComponentType
}

export const RoutePrivate = ({
  component: Component,
  ...rest
}: RouteVisitantProps) => {
  const token = ls.get('@Clinik:token')

  return (
    <Route
      {...rest}
      render={({ location }) =>
        token ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}
