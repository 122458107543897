/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'

import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'
import { Draft } from '../../components/molecules/Draft'
import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useAttendance } from './service'
import { SelectComponent } from '../../components/Form/SelectComponent'

type FormData = {
  is_active: boolean
  description: string
}

type Actions = {
  label: string
  value: string
}

export function CreateDocumentsServices() {
  const [t] = useTranslation('pageDocumentsServices')
  const attendanceService = useAttendance()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const optionsDefaultActions = [
    {
      value: '1',
      label: `${t('actions.1')}`
    },
    {
      value: '2',
      label: `${t('actions.2')}`
    },
    {
      value: '3',
      label: `${t('actions.3')}`
    },
    {
      value: '4',
      label: `${t('actions.4')}`
    }
  ]
  // state
  const [name, setName] = useState('')
  const [valueDraft, setValueDraft] = useState<any>()
  const [actions, setActions] = useState<Actions>({
    value: '1',
    label: `${t('actions.1')}`
  })
  const handleKeyUpEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value.toUpperCase()
    setName(values)
  }

  const handleSelectAction = (event: any) => {
    setActions(event)
  }

  const handleCreateTagService: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { is_active, description } = values

    const payload = {
      name,
      is_active,
      description,
      actions: actions.value,
      document: valueDraft === undefined ? '<p><br><p>' : valueDraft
    }

    attendanceService.createAttendance(payload)
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('create.title')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/services/document-service">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateTagService)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="40%" mr="8">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  fontSize="16"
                  placeholder={t('placeholder.name')}
                  isRequired
                  value={name}
                  onChange={handleKeyUpEvent}
                  maxLength={30}
                />
              </Box>
              <Stack direction="row" my="2" spacing="8" w="50%">
                <Box w="300px" mr="2">
                  <SelectComponent
                    options={optionsDefaultActions}
                    defaultValue={optionsDefaultActions[0]}
                    name="actions"
                    value={actions}
                    onChange={handleSelectAction}
                    title={t('label.actions')}
                    placeholder={t('placeholder.actions')}
                  />
                </Box>
                <Box ml="12">
                  <Switch
                    defaultChecked
                    label={t('create.container1.i2')}
                    {...register('is_active')}
                  />
                </Box>
              </Stack>
            </Stack>
            <Flex direction="column">
              <Text mb="2" fontSize="sm">
                {t('create.container1.i5')}
              </Text>
              <Textarea
                placeholder={t('create.container1.description')}
                {...register('description')}
              />
            </Flex>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Draft
              getValue={(value: any) => {
                setValueDraft(value)
              }}
            />
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
