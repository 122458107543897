import styled from 'styled-components'

export const Wrapper = styled.div`
  border: 1px solid #d1d2dc;
  z-index: 0;
  min-height: 20rem;
  width: 100%;
  cursor: text;

  .toolbarClassName {
  }

  .wrapperClassName {
  }

  .editorClassName {
    padding: 0 16px;
    width: 100%;
    height: 100%;
  }
`
