import React from 'react'
import Webcam from 'react-webcam'
import { Button, Flex, Box, Avatar, Text, Image, Icon } from '@chakra-ui/react'
import { BsFillCameraFill } from 'react-icons/bs'
import { useDocument } from '../../hooks/useDocument'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user'
}

export const WebcamCapture = () => {
  const { setImgSrc } = useDocument()
  const webcamRef = React.useRef<any>(null)
  const [image, setImage] = React.useState('')

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()

    if (imageSrc !== undefined) {
      setImage(imageSrc)
      setImgSrc(imageSrc)
    }
  }, [webcamRef, setImgSrc])

  return (
    <>
      <Flex
        w="100%"
        h="100%"
        position="relative"
        borderRadius={8}
        bg="black"
        zIndex="10"
      >
        <Flex>
          <Webcam
            audio={false}
            height={720}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1080}
            videoConstraints={videoConstraints}
          />
        </Flex>
        <Button
          zIndex="99"
          position="absolute"
          size="md"
          fontSize="md"
          bg="blue.500"
          color="#fff"
          _hover={{
            bg: 'blue.700'
          }}
          onClick={capture}
          bottom="2"
          right="2"
        >
          Tirar foto
        </Button>
      </Flex>
      {image && (
        <Box p="8" w="100%">
          <Flex flexDirection="row">
            <Text mx="4" as="samp" fontSize="18px">
              Resultado
            </Text>
            <Icon as={BsFillCameraFill} />
          </Flex>
          <Image mt="2" objectFit="cover" src={image} alt="Photo" />
          {image && (
            <>
              <Flex
                borderRadius={8}
                bg="gray.50"
                p="2"
                align="center"
                justify="space-around"
                flexDirection="row"
              >
                <Text mx="4" as="samp">
                  Foto de perfil
                  <Text mx="2" as="em">
                    (Avatar)
                  </Text>
                </Text>
                <Avatar mx="4" objectFit="cover" src={image} />
              </Flex>
            </>
          )}
        </Box>
      )}
    </>
  )
}
