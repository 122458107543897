export const StateOptions = [
  {
    value: 'Rondônia',
    label: 'Rondônia',
    sigla: 'RO'
  },
  {
    value: 'Acre',
    label: 'Acre',
    sigla: 'AC'
  },
  {
    value: 'Amazonas',
    label: 'Amazonas',
    sigla: 'AM'
  },
  {
    value: 'Roraima',
    label: 'Roraima',
    sigla: 'RR'
  },
  {
    value: 'Pará',
    label: 'Pará',
    sigla: 'PA'
  },
  {
    value: 'Amapá',
    label: 'Amapá',
    sigla: 'AP'
  },
  {
    value: 'Tocantins',
    label: 'Tocantins',
    sigla: 'TO'
  },
  {
    value: 'Maranhão',
    label: 'Maranhão',
    sigla: 'MA'
  },
  {
    value: 'Piauí',
    label: 'Piauí',
    sigla: 'PI'
  },
  {
    value: 'Ceará',
    label: 'Ceará',
    sigla: 'CE'
  },
  {
    value: 'Rio Grande do Norte',
    label: 'Rio Grande do Norte',
    sigla: 'RN'
  },
  {
    value: 'Paraíba',
    label: 'Paraíba',
    sigla: 'PB'
  },
  {
    value: 'Pernambuco',
    label: 'Pernambuco',
    sigla: 'PE'
  },
  {
    value: 'Alagoas',
    label: 'Alagoas',
    sigla: 'AL'
  },
  {
    value: 'Sergipe',
    label: 'Sergipe',
    sigla: 'SE'
  },
  {
    value: 'Bahia',
    label: 'Bahia',
    sigla: 'BA'
  },
  {
    value: 'Minas Gerais',
    label: 'Minas Gerais',
    sigla: 'MG'
  },
  {
    value: 'Espírito Santo',
    label: 'Espírito Santo',
    sigla: 'ES'
  },
  {
    value: 'Rio de Janeiro',
    label: 'Rio de Janeiro',
    sigla: 'RJ'
  },
  {
    value: 'São Paulo',
    label: 'São Paulo',
    sigla: 'SP'
  },
  {
    value: 'Paraná',
    label: 'Paraná',
    sigla: 'PR'
  },
  {
    value: 'Santa Catarina',
    label: 'Santa Catarina',
    sigla: 'SC'
  },
  {
    value: 'Rio Grande do Sul',
    label: 'Rio Grande do Sul',
    sigla: 'RS'
  },
  {
    value: 'Mato Grosso do Sul',
    label: 'Mato Grosso do Sul',
    sigla: 'MS'
  },
  {
    value: 'Mato Grosso',
    label: 'Mato Grosso',
    sigla: 'MT'
  },
  {
    value: 'Goiás',
    label: 'Goiás',
    sigla: 'GO'
  },
  {
    value: 'Distrito Federal',
    label: 'Distrito Federal',
    sigla: 'DF'
  }
]
