import React, { useState } from 'react'
import Webcam from 'react-webcam'
import {
  Button,
  Flex,
  Box,
  Avatar,
  Text,
  Image,
  Icon,
  Center,
  useToast
} from '@chakra-ui/react'
import { FiCamera } from 'react-icons/fi'
import { BsFillCameraFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import { apiAuth } from '../../services/apiAuth'

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: 'user'
}

type WebcamCaptureProps = {
  cardId: string
  maxSize: number
  onUpdate: () => void
}

export const WebcamCapture: React.FC<WebcamCaptureProps> = ({
  cardId,
  maxSize,
  onUpdate
}) => {
  const [t] = useTranslation('webcamStreamComponent')
  const toast = useToast()
  const webcamRef = React.useRef<any>(null)
  const [image, setImage] = React.useState('')
  const BASE_URL = `clinics/gallery-cards/${cardId}/images/`

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImage(imageSrc)
  }, [webcamRef])

  const handlerUploadFile = async () => {
    // generateArray Buffer to Uint8Array to file for upload
    const base64Response = await fetch(image)
    const blob = await base64Response.blob()

    const file = new File([blob], 'clinicImage')

    if (file.size > maxSize) {
      toast({
        duration: 3000,
        status: 'error',
        description: `${t('toast.errorSize')}`
      })

      return
    }

    const payload = new FormData()

    payload.append('file', file)
    payload.append('name', file.name)
    payload.append('date', format(new Date(), 'yyyy-MM-dd'))
    payload.append('description', file.name)

    try {
      const { data } = await apiAuth.post(BASE_URL, payload)

      if (data.id) {
        toast({
          duration: 3000,
          status: 'success',
          description: `${t('toast.success')}`
        })

        onUpdate()
        setImage('')
      }
    } catch (error: any) {
      toast({
        duration: 3000,
        status: 'error',
        title: `${t('toast.error')}`,
        description: `${error.response?.data?.message}`
      })
    }
  }

  return (
    <>
      {image ? (
        <Flex flexDirection="column" w="100%" h="100%" p="6">
          <Flex flexDirection="row" w="100%" justifyContent="center">
            <Text mx="4" as="samp" fontSize="18px">
              {t('result')}
            </Text>
            <Icon as={BsFillCameraFill} />
          </Flex>
          <Center p="8" w="100%" h="100%">
            <Image src={image} width={500} height={300} objectFit="cover" />
          </Center>
          <Flex mt="4" w="100%" gap={2} justifyContent="flex-start">
            <Button colorScheme="red" size="md" onClick={() => setImage('')}>
              {t('btnReset')}
            </Button>
            <Button colorScheme="blue" size="md" onClick={handlerUploadFile}>
              {t('btnSave')}
            </Button>
          </Flex>
        </Flex>
      ) : (
        <>
          <Flex
            w="100%"
            h="100%"
            position="relative"
            borderRadius={8}
            bg="black"
            zIndex="10"
          >
            <Flex>
              <Webcam
                audio={false}
                height={720}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={1080}
                videoConstraints={videoConstraints}
              />
            </Flex>
            <Button
              borderRadius="50%"
              w="60px"
              h="60px"
              zIndex="99"
              position="absolute"
              size="md"
              fontSize="md"
              bg="blue.500"
              color="#fff"
              _hover={{
                bg: 'blue.700'
              }}
              onClick={capture}
              bottom="2"
              right="2"
            >
              <Icon as={FiCamera} fontSize="24px" />
            </Button>
          </Flex>
        </>
      )}
    </>
  )
}
