/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'

interface Choices {
  value: string
  label: string
}

type Field = {
  id: number
  name: string
  label: string
  type: string
  is_required: boolean
  default_value: string
  max_length: number
  choices?: Choices[]
  value: string
}

type Group = {
  id: number
  name: string
  fields: Array<Field>
}

export type Form = {
  id: number
  name: string
  groups: Array<Group>
}

type GetResponseFormData = {
  results: Array<Form>
}

type GetResponsePrescriptions = {
  results: Array<{
    id: number
    name: string
    prescription_detail: string
  }>
}

type GetResponseExams = {
  results: Array<{
    id: number
    name: string
    exam_detail: string
  }>
}

type Prescription = {
  id: number
  name: string
  prescription_detail: string
}

type Exams = {
  id: number
  name: string
  exam_detail: string
}

type GetResponseDefaultData = Array<{
  name: string
  id: number
}>

type FormsSelect = {
  label: string
  value: string
}

export const useService = () => {
  const { clinicId } = useAuth()
  const [formsComplete, setFormsComplete] = useState<Form[]>([])
  const [formsSelect, setFormsSelect] = useState<FormsSelect[]>([])
  const [formsPreference, setFormsPreference] = useState<Form[]>([])
  const [formsSelectPreference, setFormsSelectPreference] = useState<
    FormsSelect[]
  >([])
  const [prescriptionsComplete, setPrescriptionsComplete] = useState<
    Prescription[]
  >([])
  const [prescriptionsSelect, setPrescriptionsSelect] = useState<FormsSelect[]>(
    []
  )
  const [prescriptionsPreference, setPrescriptionsPreference] = useState<
    Prescription[]
  >([])
  const [prescriptionsSelectPreference, setPrescriptionsSelectPreference] =
    useState<FormsSelect[]>([])
  const [examsComplete, setExamsComplete] = useState<Exams[]>([])
  const [examsSelect, setExamsSelect] = useState<FormsSelect[]>([])
  const [examsPreference, setExamsPreference] = useState<Exams[]>([])
  const [examsSelectPreference, setExamsSelectPreference] = useState<
    FormsSelect[]
  >([])
  const [allIndications, setAllIndications] = useState<FormsSelect[]>([])

  useEffect(() => {
    // forms

    apiAuth
      .get<GetResponseFormData>('clinics/forms/', {
        params: { is_active: true, status: 2 }
      })
      .then((response) => {
        setFormsComplete(response.data.results)

        const formattedFormsSelect = response.data.results.map((result) => {
          return {
            value: String(result.id),
            label: result.name
          }
        })

        setFormsSelect(formattedFormsSelect)
      })

    apiAuth
      .get<GetResponseFormData>('clinics/forms/', {
        params: { is_active: true, status: 2, preference: true }
      })
      .then((response) => {
        setFormsPreference(response.data.results)

        const formattedFormsSelect = response.data.results.map((result) => {
          return {
            value: String(result.id),
            label: result.name
          }
        })

        setFormsSelectPreference(formattedFormsSelect)
      })

    // prescriptions

    apiAuth
      .get<GetResponsePrescriptions>(`clinics/prescriptions-requests/`, {
        params: {
          is_active: true,
          status: 2
        }
      })
      .then((response) => {
        setPrescriptionsComplete(response.data.results)

        const formattedPrescriptionsSelect = response.data.results.map(
          (pres) => {
            return {
              value: String(pres.id),
              label: pres.name
            }
          }
        )

        setPrescriptionsSelect(formattedPrescriptionsSelect)
      })

    apiAuth
      .get<GetResponsePrescriptions>(`clinics/prescriptions-requests/`, {
        params: {
          is_active: true,
          status: 2,
          preference: true
        }
      })
      .then((response) => {
        setPrescriptionsPreference(response.data.results)
        console.log(response.data.results)

        const formattedPrescriptionsSelect = response.data.results.map(
          (pres) => {
            return {
              value: String(pres.id),
              label: pres.name
            }
          }
        )

        setPrescriptionsSelectPreference(formattedPrescriptionsSelect)
      })

    // exams

    apiAuth
      .get<GetResponseExams>(`clinics/exams/`, {
        params: {
          is_active: true,
          status: 2
        }
      })
      .then((response) => {
        setExamsComplete(response.data.results)

        const formattedExamsSelect = response.data.results.map((exam) => {
          return {
            value: String(exam.id),
            label: exam.name
          }
        })

        setExamsSelect(formattedExamsSelect)
      })

    apiAuth
      .get<GetResponseExams>(`clinics/exams/`, {
        params: {
          is_active: true,
          status: 2,
          preference: true
        }
      })
      .then((response) => {
        setExamsPreference(response.data.results)

        const formattedExamsSelect = response.data.results.map((exam) => {
          return {
            value: String(exam.id),
            label: exam.name
          }
        })

        setExamsSelectPreference(formattedExamsSelect)
      })

    // services

    apiAuth
      .get<GetResponseDefaultData>(`clinics/${clinicId}/services/`, {
        params: {
          is_active: true,
          noPaginate: 1,
          onlyFields: 'id,name'
        }
      })
      .then((response) => {
        const formattedServices = response.data.map((service) => {
          return {
            value: String(service.id),
            label: service.name
          }
        })

        setAllIndications(formattedServices)
      })

    return () => {}
  }, [clinicId])

  return {
    formsComplete,
    formsSelect,
    formsPreference,
    formsSelectPreference,
    allIndications,
    prescriptionsComplete,
    prescriptionsSelect,
    prescriptionsPreference,
    prescriptionsSelectPreference,
    examsComplete,
    examsSelect,
    examsPreference,
    examsSelectPreference
  }
}
