/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { Flex, Button, Text, CloseButton, Switch } from '@chakra-ui/react'
import React, { ReactNode, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { FiEdit } from 'react-icons/fi'
import { useDnd } from '../../hooks/useDnd'

interface GroupContainerProps {
  title: string | undefined
  children: ReactNode
  isEditable: boolean
  onAction?: () => void
  onExclude?: () => void
  index: number
  isVisible: boolean
}

interface DropAndDragItem {
  index: number
}

export const GroupContainer = ({
  title,
  children,
  isEditable,
  onExclude,
  onAction,
  isVisible,
  index
}: GroupContainerProps) => {
  const { MoveFormGroup, setListForms, listForms } = useDnd()
  const ref = useRef<any>(null)
  const styledGrabbing = {
    border: '2px dashed rgba(0, 0, 0, 0.2)',
    pt: '5px',
    borderRadius: '0',
    bg: '#d3d3d3',
    cursor: 'grabbing'
  }
  const [{ isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    type: 'CONTAINER',
    item: { type: 'CONTAINER', index }
  })

  const [, dropRef] = useDrop({
    accept: 'CONTAINER',
    hover(item: DropAndDragItem, monitor) {
      const draggedIndex = item.index
      const targetIndex = index

      if (draggedIndex === targetIndex) {
        return
      }

      const targetSize = ref?.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const coordY = draggedOffset !== undefined ? draggedOffset?.y : 1
      const coordX = draggedOffset !== undefined ? draggedOffset?.x : 1
      const draggedTop = coordY !== undefined ? coordY - targetSize.top : 1

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return
      }

      MoveFormGroup(0, draggedIndex, targetIndex)

      item.index = targetIndex
    }
  })

  const handlerVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...listForms]
    const valuesAux = values.slice(index, index + 1)
    const newValue = valuesAux.map((group) => {
      return {
        id: group.id,
        isVisible: event.target.checked,
        fields: group.fields,
        name: group.name
      }
    })

    values[index] = newValue[0]
    setListForms(values)
  }

  dragRef(dropRef(ref))

  return (
    <Flex
      px="6"
      bg="#fdfdfd"
      ref={ref}
      position="relative"
      py="4"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.08)"
      borderRadius={8}
      maxWidth="100%"
      style={isDragging ? styledGrabbing : undefined}
      direction="column"
      border={title === '' ? '1px solid red' : '1px solid #808080'}
      flex="1"
    >
      <>
        <Flex flexDirection="row" my="2">
          <Text mb="2" mx="4" fontSize="20px">
            {title}
          </Text>
          {isEditable && (
            <>
              <Button
                type="button"
                colorScheme="green"
                size="xs"
                color="white"
                onClick={() => {
                  onAction?.()
                }}
              >
                <FiEdit />
              </Button>
              <Switch
                mx="2"
                isChecked={listForms[index].isVisible}
                colorScheme="green"
                onChange={(event) => handlerVisibility(event)}
              />
              <Text fontSize="12px" fontWeight="hairline">
                mostrar ou esconder campos
              </Text>
              <CloseButton
                position="absolute"
                top="2"
                right="2"
                bg="red"
                color="white"
                onClick={() => {
                  onExclude?.()
                }}
              />
            </>
          )}
        </Flex>
        {isVisible && (
          <Flex flex="1" direction="column">
            {children}
          </Flex>
        )}
      </>
    </Flex>
  )
}
