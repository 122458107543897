/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'

export type SuppliersData = {
  id?: number
  name: string
  sale_price: string
  cost_price: string
  product_supplier: string
  is_active?: boolean
}

const BASE_URL = 'clinics/stock-items/'

export const useService = () => {
  const [loadingRest, setLoadingRest] = useState(false)

  // terceiros
  const router = useHistory()
  const toast = useToast()

  // função para criar
  async function createRequest(body: any) {
    try {
      const response = await apiAuth.post(BASE_URL, body)

      if (response.data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/items-products')
        }, 2000)
      }
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // função para editar
  async function updateRequest(body: any, id: number) {
    try {
      setLoadingRest(true)
      const res = await apiAuth.put(`${BASE_URL}${id}/`, body)

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/items-products')
      }, 2000)
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // export
  return {
    createRequest,
    updateRequest,
    loadingRest
  }
}
