/* eslint-disable no-param-reassign */
import React, { FC, useRef, useState } from 'react'
import {
  ListItem,
  Flex,
  Box,
  Image,
  Icon,
  Button,
  HStack,
  Textarea,
  Checkbox
} from '@chakra-ui/react'
import { AiFillCloseSquare } from 'react-icons/ai'
import { VscFilePdf } from 'react-icons/vsc'
import { FiEye } from 'react-icons/fi'
import { useDrag, useDrop } from 'react-dnd'
import { TFunction } from 'i18next'
import { useDnd } from '../../hooks/useDnd'
import { CardImage } from '../Cards/cardImage'

type ComparisonData = {
  id?: string | number
  name?: string
}

type ListItemComparisonsProps = {
  t: TFunction
  isChecked: boolean
  onCheckedChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  data: ComparisonData | undefined
  index: number
}

interface DropAndDragItem {
  type: string
  id: string | number
  index: number
  name: string
}

export const ListItemComparisons: FC<ListItemComparisonsProps> = ({
  t,
  isChecked,
  onCheckedChange,
  data,
  index
}) => {
  // styled isDragging
  const styledGrabbing = {
    border: '2px dashed rgba(0, 0, 0, 0.2)',
    pt: '5px',
    borderRadius: '0',
    bg: '#d3d3d3',
    cursor: 'grabbing'
  }
  const { Move } = useDnd()
  const ref = useRef<any>(null)
  const [{ isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    type: 'COMP',
    item: { type: 'COMP', id: data?.id, index, name: data?.name }
  })

  const [, dropRef] = useDrop({
    accept: 'COMP',
    hover(item: DropAndDragItem, monitor) {
      const draggedIndex = item.index
      const targetIndex = index

      if (draggedIndex === targetIndex) {
        return
      }

      const targetSize = ref?.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const coordY = draggedOffset !== undefined ? draggedOffset?.y : 1
      const coordX = draggedOffset !== undefined ? draggedOffset?.x : 1
      const draggedTop = coordY !== undefined ? coordY - targetSize.top : 1

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return
      }

      Move(0, draggedIndex, targetIndex)

      item.index = targetIndex
    }
  })

  dragRef(dropRef(ref))

  return (
    <ListItem
      style={isDragging ? styledGrabbing : undefined}
      my="2"
      p="2"
      _hover={{ cursor: 'grab' }}
      ref={ref}
    >
      {!isDragging && (
        <Flex
          w="100%"
          flexDirection={['column', 'row']}
          justifyContent="space-around"
          mx="auto"
        >
          <Checkbox
            isChecked={isChecked}
            onChange={(event) => onCheckedChange(event)}
            colorScheme="blue"
            mx="2"
          />
          <Box
            mx="auto"
            alignItems="center"
            justifyContent="flex-start"
            position="relative"
          >
            <CardImage
              src="https://clinik.net/media/gallery_card_images/imagem_teste_I6AOMXV.jpg"
              onOpenExclude={() => {}}
              onViewPhoto={() => {}}
              onOpenEdit={() => {}}
              noEdit
            />
            <Button m="2" colorScheme="blue" size="sm">
              {t('container1.add')}
            </Button>
          </Box>
          <Box
            mx="auto"
            alignItems="center"
            justifyContent="flex-start"
            position="relative"
          >
            <CardImage
              src="https://clinik.net/media/gallery_card_images/caminho_rkEPbs4.jpg"
              onOpenExclude={() => {}}
              onViewPhoto={() => {}}
              onOpenEdit={() => {}}
              noEdit
            />
            <Button m="2" colorScheme="blue" size="sm">
              {t('container1.add')}
            </Button>
          </Box>
          <Box mx="auto" alignItems="center" justifyContent="flex-start">
            <Textarea
              name="comparisonsDetail"
              placeholder={t('container1.placeholder.detail')}
            />
          </Box>
          <Box mx="auto" alignItems="center" justifyContent="flex-start">
            <HStack spacing={4}>
              <Icon
                as={FiEye}
                _hover={{ color: 'red', cursor: 'pointer' }}
                fontSize="24px"
              />
              <Icon
                as={VscFilePdf}
                _hover={{ color: 'red', cursor: 'pointer' }}
                fontSize="24px"
              />
              <Icon
                as={AiFillCloseSquare}
                _hover={{ color: 'red', cursor: 'pointer' }}
                fontSize="24px"
              />
            </HStack>
          </Box>
        </Flex>
      )}
    </ListItem>
  )
}
