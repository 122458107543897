/* eslint-disable no-param-reassign */
import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Text } from '@chakra-ui/react'
import { useDnd } from '../../../hooks/useDnd'
import * as C from './styles'

interface CardData {
  id?: string
  name?: string
}

interface BoardCardProps {
  data: CardData | undefined
  index: number
}

interface DropAndDragItem {
  type: string
  id: string | number
  index: number
  name: string
}

export const BoardCard: React.FC<BoardCardProps> = ({ data, index }) => {
  const { Move } = useDnd()
  const ref = useRef<any>(null)
  const [{ isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    type: 'CARD',
    item: { type: 'CARD', id: data?.id, index, name: data?.name }
  })

  const [, dropRef] = useDrop({
    accept: 'CARD',
    hover(item: DropAndDragItem, monitor) {
      const draggedIndex = item.index
      const targetIndex = index

      if (draggedIndex === targetIndex) {
        return
      }

      const targetSize = ref?.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const coordY = draggedOffset !== undefined ? draggedOffset?.y : 1
      const coordX = draggedOffset !== undefined ? draggedOffset?.x : 1
      const draggedTop = coordY !== undefined ? coordY - targetSize.top : 1

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return
      }

      Move(0, draggedIndex, targetIndex)

      item.index = targetIndex
    }
  })

  dragRef(dropRef(ref))

  return (
    <C.Container isDragging={isDragging} ref={ref}>
      <Text fontSize={18} fontWeight="500">
        {data?.name}
      </Text>
    </C.Container>
  )
}
