import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { apiAuth } from '../services/apiAuth'
import { queryClient } from '../services/reactQuery'

export const useUpdate = () => {
  // hooks
  const router = useHistory()
  const toast = useToast()

  async function UpdatePosting(
    payload: any,
    postingId: string,
    redirect: boolean = false
  ) {
    try {
      const response = await apiAuth.put(
        `clinics/postings/${postingId}/`,
        payload
      )

      queryClient.invalidateQueries('billsToPay')

      if (response.data.id !== undefined) {
        // Invalidate Cache
        queryClient.invalidateQueries('billsToPay')

        // Message Success
        toast({
          title: 'Atualização realizada com sucesso!',
          description: redirect ? 'Estamos lhe redirecionando.' : '',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        if (redirect) {
          // Redirect to List Payable
          setTimeout(() => {
            router.push('/finances/bills-to-pay')
          }, 2000)
        }
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu um Error!',
        description: `${error.message.replace(
          'Request failed with status code 500',
          'Este erro ocorreu por um erro no Servidor! (API)'
        )}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  return {
    UpdatePosting
  }
}
