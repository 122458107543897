import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalCreateCardProps {
  children: ReactNode
  title: string
  onCreate: () => void
  isOpen: boolean
  onClose: () => void
}

export const ModalCreateNewCard = ({
  children,
  title,
  onCreate,
  isOpen,
  onClose
}: ModalCreateCardProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? 'lg' : 'md'}
        blockScrollOnMount={false}
        isCentered
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="blue" onClick={onCreate}>
              Criar Card
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
