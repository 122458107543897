import { ReactNode } from 'react'

import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalArrivedTimeProps {
  children: ReactNode
  title: string
  onUpdate: () => void
  isOpen: boolean
  onClose: () => void
  notRenderFooter?: boolean
  isLoading?: boolean
  isLarge?: boolean
}

export const ModalArrivedTime = ({
  children,
  title,
  onUpdate,
  isOpen,
  onClose,
  isLoading,
  notRenderFooter,
  isLarge
}: ModalArrivedTimeProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isLarge ? '6xl' : '3xl'}
        isCentered
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent overflowY="auto" overflowX="hidden">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          {notRenderFooter ? null : (
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                isLoading={isLoading}
                colorScheme="blue"
                onClick={onUpdate}
              >
                Confirma
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
