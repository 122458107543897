/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import {
  ContentBlock,
  ContentState,
  convertToRaw,
  DefaultDraftBlockRenderMap,
  EditorState
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Map } from 'immutable'
import ls from 'localstorage-slim'
import { useEffect, useRef, useState } from 'react'
import { Editor, EditorProps } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { LineHorizontal } from '../../ButtonsDraftBar/LineHorizontal'

import * as S from './styles'

interface Props extends EditorProps {
  getValue?: (value: any) => void
  defaultValue?: any
  backContentRender?: any
}

const mapWithHrElement = Map({
  hr: {
    element: 'hr'
  }
}).merge(DefaultDraftBlockRenderMap)

export const Draft = ({ getValue, defaultValue, backContentRender }: Props) => {
  const location = ls.get<string>('@CLINIC:LANGUAGE_DEFAULT')
  const ref = useRef(null)
  const [editor, setEditor] = useState(EditorState.createEmpty())

  const customBlockRender = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType()
    if (type === 'hr') {
      return {
        component: () => {
          return (
            <hr
              style={{
                width: '100%',
                color: 'gray',
                height: '3px'
              }}
            />
          )
        },
        editable: false
      }
    }

    return contentBlock
  }

  const onContentStateChange = (editorState: EditorState) => {
    setEditor(editorState)
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    getValue!(content)
  }

  useEffect(() => {
    if (!!defaultValue) {
      // replace \
      const removeBar = defaultValue?.replace(/[\\]/g, '')
      const removeBarN = removeBar.replace('\n', '')
      const content = htmlToDraft(removeBarN)
      const contentState = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      )

      const editorState = EditorState.createWithContent(contentState)

      const contentHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )
      getValue!(contentHtml)
      setEditor(editorState)
    }
  }, [])

  useEffect(() => {
    if (!!backContentRender) {
      // replace \
      const removeBar = backContentRender?.replace(/[\\]/g, '')
      const removeBarN = removeBar.replace('\n', '')
      const content = htmlToDraft(removeBarN)
      const contentState = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      )

      const editorState = EditorState.createWithContent(contentState)

      const contentHtml = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      )
      getValue!(contentHtml)
      setEditor(editorState)
    }
  }, [backContentRender])

  return (
    <S.Wrapper>
      <Editor
        ref={ref}
        onEditorStateChange={onContentStateChange}
        editorState={editor}
        stripPastedStyles
        toolbar={{
          options: ['inline', 'list', 'fontSize', 'textAlign', 'history'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: [
              'bold',
              'italic',
              'underline',
              'monospace',
              'superscript',
              'subscript'
            ]
          },
          fontSize: {
            options: [10, 11, 12, 14, 16, 18, 24, 30, 36]
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered']
          }
        }}
        localization={{
          locale: location
        }}
        toolbarCustomButtons={[
          <LineHorizontal
            editorState={editor}
            onChange={(editorResult) => {
              setEditor(editorResult)
              const contentHtml = draftToHtml(
                convertToRaw(editorResult.getCurrentContent())
              )

              getValue!(contentHtml)
            }}
            map={mapWithHrElement}
          />
        ]}
        customBlockRenderFunc={customBlockRender}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </S.Wrapper>
  )
}
