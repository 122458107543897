import { Flex, Text } from '@chakra-ui/react'

export const Logo = () => {
  return (
    <Flex w="100%" justify="center" marginBottom="4">
      <Text color="blue.500" fontSize={40}>
        Clinik.net
      </Text>
    </Flex>
  )
}
