import styled from 'styled-components'

export const Container = styled.div`
  width: 160px;
  height: 190px;
  position: relative;
  border: 2px dashed #ccc8c8;
  box-shadow: 0px 4px 6px #00000014;
  background: #ffffff;
  padding: 3rem 1rem;
  border-radius: 8px;
  margin: 16px 0 12px;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: 8px;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99;
  }
`
