import {
  Box,
  CloseButton,
  Flex,
  Button,
  Center,
  Icon,
  Image,
  HStack,
  useToast
} from '@chakra-ui/react'
import saveAs from 'file-saver'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { FaPlay } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { apiAuth } from '../../services/apiAuth'

interface CardVideoProps {
  src: string | undefined
  title: string
  noEdit?: boolean
  file: string
  onOpenExclude: () => void
  onOpenEdit: () => void
  onPlayer: () => void
}

export const CardVideo = ({
  src,
  noEdit,
  file,
  onOpenEdit,
  title,
  onOpenExclude,
  onPlayer
}: CardVideoProps) => {
  const toast = useToast()

  const handlerDownloadVideo = () => {
    fetch(file, { mode: 'cors' })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, `${title || new Date().toLocaleTimeString()}`)
      })
      .catch((error) => {
        toast({
          status: 'error',
          title: 'Não foi possível fazer download do arquivo',
          duration: 3000,
          position: 'top-right'
        })
      })
  }

  return (
    <Box id={title} h="171px" w="304px" borderRadius={16} position="relative">
      <Center w="100%" h="100%">
        <HStack position="absolute" right="2" top="2" zIndex={10}>
          <Button
            w="24px"
            h="24px"
            size="sm"
            bg="transparent"
            color="white"
            _hover={{ bg: 'green', color: 'white' }}
            borderRadius={8}
            transition="0.2s ease-in"
            onClick={handlerDownloadVideo}
          >
            <Icon as={AiOutlineCloudDownload} />
          </Button>
          {!noEdit && (
            <Button
              w="24px"
              h="24px"
              bg="transparent"
              color="white"
              size="sm"
              onClick={() => onOpenEdit()}
              _hover={{ bg: 'green', color: 'white' }}
            >
              <Icon as={FiEdit} />
            </Button>
          )}
          <CloseButton
            zIndex={10}
            colorScheme="red"
            color="white"
            onClick={() => onOpenExclude()}
            _hover={{ bg: 'red', color: 'white' }}
          />
        </HStack>
        <Button
          size="md"
          bg="transparent"
          zIndex={99}
          _hover={{ bg: 'black', color: 'white' }}
          onClick={onPlayer}
        >
          <Icon as={FaPlay} />
        </Button>
        <Image
          w="100%"
          h="100%"
          position="absolute"
          src={src}
          borderRadius="16"
          objectFit="cover"
        />
      </Center>
    </Box>
  )
}
