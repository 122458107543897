import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Checkbox,
  CheckboxGroup as ChakraGroupCheckbox,
  CheckboxGroupProps as ChakraGroupCheckboxProps,
  Stack
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

interface Choices {
  value: string
  label: string
}

interface CheckBoxProps extends ChakraGroupCheckboxProps {
  label?: string
  error?: FieldError
  isRequired?: boolean
  children?: ReactNode
  choices?: Choices[]
}

const RadioBase: ForwardRefRenderFunction<HTMLInputElement, CheckBoxProps> = (
  { label, error, isRequired, children, choices, ...rest },
  ref
) => {
  return (
    <FormControl mr="4" isRequired={isRequired} isInvalid={!!error}>
      {!!label && <FormLabel mt={6}>{label}</FormLabel>}
      <ChakraGroupCheckbox colorScheme="blue" size="lg" {...rest}>
        <Stack spacing={[1, 5]} direction={['column', 'row']}>
          {choices?.length !== undefined &&
            choices.map((option) => (
              <Checkbox value={option.value}>{option.label}</Checkbox>
            ))}
        </Stack>
      </ChakraGroupCheckbox>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const RadioGroup = forwardRef(RadioBase)
