import React from 'react'
import * as C from './styles'
import { BoardList } from './BoardList'

interface BoardProps {
  content: Array<any> | undefined
  title: string
}

export const Board: React.FC<BoardProps> = ({ content, title }) => {
  return (
    <C.Container>
      <BoardList title={title} data={content} />
    </C.Container>
  )
}
