import { TIME_FORMAT, WEEKDAY_START } from '../common/enums'
import { Callbacks, Config } from '../common/interfaces'
import { DEFAULT_HOUR_HEIGHT } from '../common/constants'

const emptyFunction = () => {}

const parseTimeFormat = (
  timeFormatValue: string | undefined
): TIME_FORMAT | void => {
  if (timeFormatValue) {
    if (
      timeFormatValue.toLowerCase() ===
      TIME_FORMAT.H_24.toString().toLowerCase()
    ) {
      return TIME_FORMAT.H_24
    }

    return TIME_FORMAT.H_12
  }

  return TIME_FORMAT.H_24
}

const parseWeekDayStart = (
  weekDayStartValue: string | undefined
): WEEKDAY_START | void => {
  if (weekDayStartValue) {
    if (
      weekDayStartValue.toLowerCase() === WEEKDAY_START.MONDAY.toLowerCase()
    ) {
      return WEEKDAY_START.MONDAY
    }

    if (
      weekDayStartValue.toLowerCase() === WEEKDAY_START.SUNDAY.toLowerCase()
    ) {
      return WEEKDAY_START.SUNDAY
    }
  }

  return WEEKDAY_START.MONDAY
}

export const createConfig = (props?: any): Config => {
  return {
    hourHeight: props?.hourHeight || DEFAULT_HOUR_HEIGHT,
    timeFormat: parseTimeFormat(props?.timeFormat) || TIME_FORMAT.H_24,
    timezone:
      props?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    weekDayStart:
      parseWeekDayStart(props?.weekDayStart) || WEEKDAY_START.MONDAY,
    isDark: false, // props?.isDark,
    disableMobileDropdown: props?.disableMobileDropdown || false,
    disabledViews: props?.disabledViews,
    calendarIDsHidden: props?.calendarIDsHidden || null,
    hasExternalLayout: props?.eventLayouts !== undefined,
    focusHour: props?.focusHour || null,
    showTimeLine: props?.showTimeLine || false
  }
}

export const createCallbacks = (props: any): Callbacks => {
  return {
    onEventDragFinish: props?.onEventDragFinish || undefined,
    onPageChange: props?.onPageChange || undefined,
    onSelectView: props?.onSelectView || undefined,
    onEventClick: props?.onEventClick || emptyFunction,
    onNewEventClick: props?.onNewEventClick || emptyFunction,
    showMoreMonth: props?.showMoreMonth || emptyFunction,
    onStateChange: props?.onStateChange || undefined
  }
}
