/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

export type Data = {
  id?: number
  name: string
  is_active?: boolean
  document?: string
  description?: string
  actions?: string
}

export type Props = {
  init: boolean
}

const BASE_URL = 'clinics/documents-service/'

export const useAttendance = () => {
  // terceiros
  const router = useHistory()
  const toast = useToast()

  // funcao para criar
  async function createAttendance(payload: Data) {
    try {
      const { data } = await apiAuth.post(BASE_URL, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/services/document-service')
        }, 2000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // funcao para editar um conveio
  async function updateAttendance({
    id,
    name,
    is_active,
    document,
    actions,
    description
  }: Data) {
    try {
      const res = await apiAuth.put(`${BASE_URL}${id}/`, {
        name,
        is_active,
        document,
        actions,
        description
      })

      if (res.data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }

      setTimeout(() => {
        router.push('/services/document-service')
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }

  // export
  return {
    createAttendance,
    updateAttendance
  }
}
