/* eslint-disable camelcase */
/* eslint-disable import/no-duplicates */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Link as LinkChakra,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import format from 'date-fns/format'
import ls from 'localstorage-slim'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileSaver from 'file-saver'
import { Link } from 'react-router-dom'
import { Pagination } from '../../components/PaginationNew'
import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { NotFound } from '../../components/molecules/NotFound'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { useModal } from '../../hooks/useModal'
import { queryClient } from '../../services/reactQuery'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { useService } from './service'
import { useAuth } from '../../hooks/auth'
import { LayoutBlank } from '../../layouts/blank'
import { Input } from '../../components/Form/Input'
import {
  getIndications,
  useIndications
} from '../../hooks/indications/useIndications'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { apiAuth } from '../../services/apiAuth'

// debounce
let timer: any = null

interface FiltersIndicationsData {
  orderBy: string
  status: Array<string>
  startDate: string
  finishDate: string
  servicesIds: Array<number>
}

type ServicesData = Array<{
  value: number
  label: string
}>

export function ReportsIndications() {
  const { LocaleApp } = useModal()
  const services = useService()
  const date = new Date()
  const toast = useToast()
  const [t] = useTranslation('pageIndications')
  // state control bar
  // specialist state control filter
  const [startDate, setStartDate] = useState(
    format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd')
  )
  const [finalDate, setFinalDate] = useState(
    format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd')
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [listByIndication, setListByIndication] = useState('indication_date')
  const [orderByIndication, setOrderByIndication] = useState('')
  const [statusIndication, setStatusIndiction] = useState<any>([])
  const [servicesSelect, setServicesSelect] = useState<ServicesData>([])
  const [filtersIndication, setFiltersIndication] =
    useState<FiltersIndicationsData>({
      orderBy: 'indication_date',
      status: [],
      startDate,
      finishDate: finalDate,
      servicesIds: []
    })

  // style button selected
  const statusOptionsDocument = [
    { value: 1, label: `${t('status.1')}` },
    { value: 2, label: `${t('status.2')}` },
    { value: 3, label: `${t('status.3')}` },
    { value: 4, label: `${t('status.4')}` },
    { value: 5, label: `${t('status.5')}` }
  ]

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {}, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {},
    []
  )

  const handlerGeneratedExcel = async () => {
    try {
      const { data } = await apiAuth.get(
        `clinics/clinics/indications/export/`,
        {
          params: {
            order_by: filtersIndication.orderBy,
            status: filtersIndication.status?.join(','),
            startDate: filtersIndication?.startDate,
            finishDate: filtersIndication?.finishDate,
            services_ids: filtersIndication?.servicesIds?.join(',')
          },
          responseType: 'blob'
        }
      )

      FileSaver(data, `${t('title')} ${format(new Date(), 'dd-MM-yyyy')}.xlsx`)
    } catch (error: any) {
      toast({
        position: 'top',
        duration: 3000,
        title: `Não foi possível gerar arquivo`
      })
    }
  }

  const handlerApplyFilters = () => {
    const servicesIds = servicesSelect?.map((ser) => {
      return ser.value
    })

    setFiltersIndication({
      startDate,
      finishDate: finalDate,
      orderBy: `${orderByIndication}${listByIndication}`,
      servicesIds,
      status: statusIndication
    })
  }

  const getIndicationsData = useIndications(
    getIndications(
      currentPage,
      filtersIndication.orderBy,
      filtersIndication.status,
      filtersIndication.startDate,
      filtersIndication.finishDate,
      filtersIndication.servicesIds
    ),
    currentPage,
    filtersIndication.orderBy,
    filtersIndication.status,
    filtersIndication.startDate,
    filtersIndication.finishDate,
    filtersIndication.servicesIds
  )

  return (
    <>
      <LayoutBlank
        title={t('title')}
        placeholder={t('placeholder.search')}
        capturaChange={(event: any) => SearchInputCallbackValue(event)}
        captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
        noRenderIsActive
        isHaveData={
          getIndicationsData?.data?.indications !== undefined &&
          getIndicationsData?.data?.indications?.length > 0
        }
        generateExcel={handlerGeneratedExcel}
        isSearched={false}
      >
        <>
          <Flex mx="auto" w="100%" direction="row" p="4" justify="space-around">
            <HStack spacing={2}>
              <Box w="180px">
                <Input
                  name="startDate"
                  value={startDate}
                  onChange={(event) => setStartDate(event.target.value)}
                  type="date"
                  label="Data inicial"
                />
              </Box>
              <Box w="180px">
                <Input
                  name="finalDate"
                  value={finalDate}
                  onChange={(event) => setFinalDate(event.target.value)}
                  type="date"
                  label="Data final"
                />
              </Box>
              <Box w="250px">
                <Select
                  name="listBy"
                  options={[
                    {
                      value: 'indication_date',
                      label: `${t('t1')}`
                    },
                    {
                      value: 'medical_record__finish_date',
                      label: `${t('t2')}`
                    }
                  ]}
                  value={listByIndication}
                  onChange={(e) => setListByIndication(e.target.value)}
                  label="Listar por:"
                  mb="0"
                />
              </Box>
              <Box w="250px">
                <Select
                  name="orderer"
                  options={[
                    { value: '', label: 'Crescente' },
                    { value: '-', label: 'Decrescente' }
                  ]}
                  label="Ordenar por:"
                  mb="0"
                  value={orderByIndication}
                  onChange={(e) => setOrderByIndication(e.target.value)}
                />
              </Box>
            </HStack>
            <Flex w="100%" justify="flex-start" align="end">
              <Box>
                <MultiSelectMenuFilter
                  label="Status"
                  options={statusOptionsDocument?.map((itemStatus) => {
                    return {
                      id: String(itemStatus.value),
                      name: itemStatus.label
                    }
                  })}
                  backChange={(values) => {
                    setStatusIndiction(values)
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex w="100%" px="4" mb="2">
            <SelectComponent
              placeholder="Selecionar serviços para filtro"
              options={services?.services?.map((ser) => {
                return {
                  value: ser?.id,
                  label: ser?.name
                }
              })}
              value={servicesSelect}
              onChange={(event: ServicesData) => setServicesSelect(event)}
              isMulti
            />
            <Button
              bg="blue.300"
              color="white"
              ml="2"
              onClick={handlerApplyFilters}
            >
              Aplicar
            </Button>
          </Flex>
          <Box>
            <Table variant="striped" colorScheme="blue" size="sm">
              <Thead>
                <Tr>
                  <Th>{t('t1')}</Th>
                  <Th>{t('t2')}</Th>
                  <Th>{t('t3')}</Th>
                  <Th>{t('t4')}</Th>
                  <Th>{t('t5')}</Th>
                  <Th>{t('t6')}</Th>
                  <Th>{t('t7')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {getIndicationsData?.data?.indications !== undefined &&
                  !getIndicationsData?.isLoading &&
                  getIndicationsData?.data?.indications?.map(
                    (ind, indexInd) => (
                      <Tr key={`${ind?.id}${ind.service_name}`}>
                        <Td>
                          {ind?.indication_date ? (
                            format(
                              new Date(
                                Number(ind?.indication_date.substring(0, 4)),
                                Number(ind?.indication_date.substring(5, 7)) -
                                  1,
                                Number(ind?.indication_date.substring(8, 10))
                              ),
                              'dd/MM/yyyy',
                              {
                                locale: LocaleApp
                              }
                            )
                          ) : (
                            <Text>--/--/----</Text>
                          )}
                        </Td>
                        <Td>
                          {ind?.medical_record_finish_date ? (
                            format(
                              new Date(ind?.medical_record_finish_date),
                              'dd/MM/yyyy',
                              {
                                locale: LocaleApp
                              }
                            )
                          ) : (
                            <Text>--/--/----</Text>
                          )}
                        </Td>
                        <Td>
                          <Text>{ind?.specialist_name}</Text>
                        </Td>
                        <Td>
                          <Text>{ind?.patient_name || ''}</Text>
                        </Td>
                        <Td>
                          <Tooltip
                            aria-label={ind?.service_name}
                            label={ind?.service_name}
                          >
                            <Box>
                              <Text
                                noOfLines={1}
                                _hover={{ cursor: 'default' }}
                              >
                                {ind?.service_name}
                              </Text>
                            </Box>
                          </Tooltip>
                        </Td>
                        <Td>
                          <Tooltip
                            aria-label={ind?.observation || ''}
                            label={ind?.observation || ''}
                          >
                            <Box>
                              <Text
                                noOfLines={1}
                                _hover={{ cursor: 'default' }}
                              >
                                {ind?.observation}
                              </Text>
                            </Box>
                          </Tooltip>
                        </Td>
                        <Td>
                          {ind?.status === 1 && <Text>{t('status.1')}</Text>}
                          {ind?.status === 2 && <Text>{t('status.2')}</Text>}
                          {ind?.status === 3 && <Text>{t('status.3')}</Text>}
                          {ind?.status === 4 && <Text>{t('status.4')}</Text>}
                          {ind?.status === 5 && <Text>{t('status.5')}</Text>}
                        </Td>
                      </Tr>
                    )
                  )}
              </Tbody>
            </Table>
            {getIndicationsData?.isLoading && (
              <Center w="100%" p="4">
                <Spinner size="md" color="blue.300" />
              </Center>
            )}
            {!getIndicationsData?.isLoading &&
              getIndicationsData?.data?.totalCountOfPage === 0 && <NotFound />}
            {!(
              getIndicationsData.data?.totalCountOfPage === 0 &&
              !getIndicationsData.isLoading
            ) && (
              <Pagination
                totalCountOfRegisters={
                  getIndicationsData.data?.totalCountOfPage || 0
                }
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            )}
          </Box>
        </>
        <Flex w="100%" h="300px" />
      </LayoutBlank>
    </>
  )
}
