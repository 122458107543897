/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import {
  Avatar,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiOutlineBgColors } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { ArrowBack } from '../../../components/atoms/arrowBack'
import { Input } from '../../../components/Form/Input'
import { Switch } from '../../../components/Form/Switch'
import { Textarea } from '../../../components/Form/Textarea'
import { TextFieldControlled } from '../../../components/Form/TextFieldControlled'
import { Header } from '../../../components/Header'
import { ModalArrivedTime } from '../../../components/Modal/confirmArrivedTime'
import { ModalWebcam } from '../../../components/Modal/modalWebcam'
import { CardContainer } from '../../../components/molecules/CardContainer'
import { Sidebar } from '../../../components/Sidebar'
import { WebcamCapture } from '../../../components/Webcam'
import { ImageComponent } from '../../../components/Webcam/ImageComponent'
import { useDocument } from '../../../hooks/useDocument'
import { apiAuth } from '../../../services/apiAuth'
import Dropzone from './Dropzone'
import { useLogicUser } from './Logic'
import { useService } from './service'

interface UsersData {
  birth_date: string
  email: string
  observation: string
  contact: string
  password: string
  is_active: boolean
  is_specialist: boolean
}

interface ParamsProps {
  id: string
}

interface User {
  id: number
  user: {
    id: number
    name: string
    email: string
    is_active: boolean
    is_specialist: boolean
    is_superuser: boolean
  }
  document: string | null
  color: string | null
  birth_date: string | null
  telephone: string | null
  social_name: string | null
  role: string | null
  observation: string | null
  image: string | null
}

export function UpdateUser() {
  // hooks outers
  const { t } = useTranslation('pageUsers')
  const toast = useToast()
  const { imgSrc, setImgSrc } = useDocument()
  const { id } = useParams<ParamsProps>()
  // hooks
  const service = useService()
  const logic = useLogicUser()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { register, handleSubmit, formState } = useForm<UsersData>({})
  const { errors } = formState
  const [user, setUser] = useState<User | null>(null)
  const [userConfirmation, setUserConfirmation] = useState(false)
  // states modal
  const [isOpen, setIsOpen] = useState(false)
  // warning modal
  const [isOpenWarning, setIsOpenWarning] = useState(false)
  // color picker
  const [color, setColor] = useState('#fff')
  const [isOpenColorModal, setIsOpenColorModal] = useState(false)
  // handler change password visibility
  const [passwordVisibility, setPasswordVisibility] = useState(false)
  const [password, setPassword] = useState('')

  const handlerCloseModalWarning = () => {
    setIsOpenWarning(false)
  }

  const handleUpdateUser: SubmitHandler<UsersData> = async (values, event) => {
    event?.preventDefault()

    if (!userConfirmation) {
      if (user?.user?.is_specialist === true) {
        if (logic.isSpecialist === false) {
          setIsOpenWarning(true)

          return
        }
        if (logic.isActive === false) {
          setIsOpenWarning(true)

          return
        }
      }
    }

    const stringValue = imgSrc.substring(0, 4)
    const payload = {
      user: {
        id: user?.user?.id,
        name: logic.name,
        email: logic.email,
        password: password !== '' ? password : null,
        is_active: logic.isActive,
        is_specialist: logic.isSpecialist,
        is_superuser: logic.superuser
      },
      document: logic.document === '' ? null : logic.document,
      color: color === '#fff' ? undefined : color,
      social_name: logic.socialName,
      birth_date: values.birth_date !== '' ? values.birth_date : undefined,
      role: logic.occupation,
      observation: values.observation !== '' ? values.observation : undefined,
      telephone: values.contact !== '' ? values.contact : undefined,
      image: stringValue === 'http' ? undefined : imgSrc,
      user_contacts: [],
      id: user?.id
    }

    service.handleUpdateUser(payload, id)
  }

  // Close modal
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    apiAuth.get<User>(`users/${id}/`).then((response) => {
      setUser(response.data)
    })
  }, [id])

  useEffect(() => {
    if (user?.image) {
      setImgSrc(user?.image)
    }

    if (user?.document) {
      logic.setDocument(user?.document)
    }

    if (user?.color) {
      setColor(user?.color)
    }

    if (user?.user.name) {
      logic.setName(user?.user.name)
    }

    if (user?.user?.email) {
      logic.setEmail(user?.user?.email)
    }

    if (user?.social_name) {
      logic.setSocialName(user?.social_name)
    }

    if (user?.role) {
      logic.setOccupation(user?.role)
    }

    if (user?.user?.is_active) {
      logic.setIsActive(user?.user?.is_active)
    }

    if (user?.user?.is_specialist) {
      logic.setIsSpecialist(user?.user?.is_specialist)
    }

    if (user?.user?.is_superuser) {
      logic.SetSuperuser(user?.user?.is_superuser)
    }
  }, [user])

  return (
    <>
      <Box overflow="hidden" position="relative">
        <Header>
          <Flex
            mx="auto"
            w="100%"
            direction="row"
            d="flex"
            my="6"
            align="center"
            justify="space-around"
          >
            <Box mx="auto">
              <Text fontSize="24px" fontWeight="600" height="100%">
                {t('editTitle')}
              </Text>
            </Box>
            <Stack direction="row" align="center" justify="center">
              <ArrowBack href="/settings/users">
                <Text fontSize="md" fontWeight="bold">
                  Voltar
                </Text>
              </ArrowBack>
            </Stack>
          </Flex>
        </Header>
        <Sidebar />
        <Flex
          flex="1"
          mx="auto"
          as="form"
          ml={isWideVersion ? '75px' : '2'}
          mt="15px"
          mb="8px"
          mr="2"
          bg="white"
          h="cal(100vh - 60px)"
          onSubmit={handleSubmit(handleUpdateUser)}
        >
          <Stack spacing="4" w="100%">
            <CardContainer title={t('create.container1.title')}>
              <Stack position="absolute" top="4" right="4" direction="row">
                <Tooltip
                  label={t('create.color')}
                  aria-label={t('create.color')}
                  placement="left-end"
                >
                  <Flex
                    w="25px"
                    h="25px"
                    borderRadius="full"
                    border="1px solid #121214"
                    justifyContent="center"
                    alignItems="center"
                    bg="white"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => setIsOpenColorModal(true)}
                  >
                    <AiOutlineBgColors color="#121214" />
                  </Flex>
                </Tooltip>
                {color !== '#fff' && (
                  <Box w="25px" h="25px" borderRadius="6" bg={color} />
                )}
              </Stack>
              <Flex direction="row">
                <Flex flexDirection="column">
                  {/* Image for user */}
                  {imgSrc ? <ImageComponent /> : <Dropzone />}
                  {/* Open Modal Webcam */}
                  <Button
                    size="md"
                    fontSize="md"
                    bg="blue.300"
                    color="#fff"
                    onClick={() => setIsOpen(true)}
                  >
                    {t('create.container1.btnWebcam')}
                  </Button>
                </Flex>
                <Flex ml="8" flex="1" direction="column">
                  <Stack direction="row" my="2" spacing="6">
                    <Box w="80%">
                      <TextFieldControlled
                        label={t('create.container1.i1')}
                        isRequired
                        textAlign="left"
                        placeholder={t('create.container1.i1')}
                        name="name"
                        value={logic.name}
                        onChange={(event) => {
                          logic.setName(event.target.value.toUpperCase())
                          if (event.currentTarget.value.length <= 20) {
                            logic.setSocialName(
                              event.target.value.toUpperCase()
                            )
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      <Input
                        label={t('create.container1.i2')}
                        defaultValue={user?.birth_date || ''}
                        type="date"
                        {...register('birth_date')}
                        error={errors.birth_date}
                      />
                    </Box>
                  </Stack>
                  <Stack direction="row" my="2" spacing="6">
                    <Box w="40%">
                      <Input
                        label={t('create.container1.i3')}
                        defaultValue={user?.user?.email || ''}
                        isRequired
                        type="email"
                        name="email"
                        value={logic.email}
                        onChange={(event) => logic.setEmail(event.target.value)}
                        error={errors.email}
                      />
                    </Box>
                    <Box w="30%">
                      <Input
                        label={t('create.container1.i7')}
                        isRequired
                        name="socialName"
                        value={logic.socialName}
                        onChange={(event) =>
                          logic.setSocialName(event.target.value.toUpperCase())
                        }
                        maxLength={25}
                      />
                    </Box>
                    <Box w="30%">
                      <Input
                        label={t('create.container1.i8')}
                        name="document"
                        value={logic.document}
                        onChange={(event) =>
                          logic.setDocument(event.target.value)
                        }
                        maxLength={25}
                      />
                    </Box>
                    <Box>
                      <Input
                        masker="phone"
                        textAlign="left"
                        defaultValue={user?.telephone || ''}
                        label={t('create.container1.i4')}
                        {...register('contact')}
                        error={errors.contact}
                        maxLength={20}
                      />
                    </Box>
                  </Stack>
                  <Stack
                    direction="row"
                    my="2"
                    spacing="6"
                    alignItems="center"
                    position="relative"
                  >
                    <Box w="86%">
                      <Textarea
                        label={t('create.container1.i5')}
                        defaultValue={user?.observation || ''}
                        {...register('observation')}
                        error={errors.observation}
                      />
                    </Box>
                    {passwordVisibility ? (
                      <Box>
                        <Input
                          label={t('create.container1.i6')}
                          type="password"
                          name="password"
                          value={password}
                          onChange={(event) => setPassword(event.target.value)}
                          error={errors.password}
                        />
                      </Box>
                    ) : (
                      <Button
                        size="xs"
                        p="2"
                        top="6"
                        right="8"
                        position="absolute"
                        bg="blue.300"
                        color="white"
                        onClick={() => setPasswordVisibility(true)}
                      >
                        {t('changePassword')}
                      </Button>
                    )}
                  </Stack>
                </Flex>
              </Flex>
            </CardContainer>

            <CardContainer title={t('create.containerPer.title')}>
              <Stack
                w="100%"
                direction={['column', 'row']}
                my="2"
                spacing="4"
                justifyContent="space-between"
              >
                <Stack>
                  <Stack
                    direction="row"
                    my="2"
                    spacing="6"
                    w="100%"
                    justifyContent="space-between"
                  >
                    <Box w="400px">
                      <Input
                        label={t('create.containerPer.iFunction')}
                        name="occupation"
                        value={logic.occupation}
                        onChange={(event) =>
                          logic.setOccupation(event.target.value.toUpperCase())
                        }
                      />
                    </Box>
                    <Box>
                      <Switch
                        label={t('create.containerPer.specialist')}
                        isChecked={logic.isSpecialist}
                        name="isSpecialist"
                        onChange={(event) =>
                          logic.setIsSpecialist(event?.target.checked)
                        }
                      />
                    </Box>
                    <Box>
                      <Switch
                        label={t('create.containerPer.superuser')}
                        name="superuser"
                        isChecked={logic.superuser}
                        onChange={(event) => {
                          logic.SetSuperuser(event.target.checked)
                        }}
                      />
                    </Box>
                    <Box>
                      <Switch
                        name="isActive"
                        mx="4"
                        label={t('create.containerPer.active')}
                        isChecked={logic.isActive}
                        onChange={(event) => {
                          logic.setIsActive(event.target.checked)
                        }}
                      />
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </CardContainer>

            <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState.isSubmitting}
              >
                {t('create.button')}
              </Button>
            </Flex>
          </Stack>
        </Flex>
      </Box>
      <ModalWebcam
        title={t('modal.title')}
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <Flex mx="2px" h="500px">
          <WebcamCapture />
        </Flex>
      </ModalWebcam>
      <ModalArrivedTime
        title={t('create.titleWarning')}
        isOpen={isOpenWarning}
        onClose={handlerCloseModalWarning}
        onUpdate={() => {
          setUserConfirmation(true)
          handlerCloseModalWarning()

          setTimeout(() => {
            handleSubmit(handleUpdateUser)
          }, 1000)
        }}
      >
        <Flex mx="2">
          <Text>{t('create.warning')}</Text>
        </Flex>
      </ModalArrivedTime>
      <ModalArrivedTime
        title={t('modalColorPicker.title')}
        isOpen={isOpenColorModal}
        onClose={() => setIsOpenColorModal(false)}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Flex mx="2px" h="400px">
          <Stack
            w="100%"
            direction={['column', 'row']}
            my="2"
            spacing="4"
            justifyContent="space-between"
          >
            <Flex flexDir="row" gap="2">
              <SketchPicker
                color={color}
                onChangeComplete={({ hex }) => setColor(hex)}
              />
            </Flex>
            <Stack w="100%">
              <Stack
                direction="row"
                my="2"
                spacing="4"
                w="100%"
                justifyContent="flex-start"
              >
                {color !== '#fff' && (
                  <Box w="100px" borderRadius="full" dir="row">
                    <Avatar
                      name={logic.name}
                      color="white"
                      bg={color}
                      w="80px"
                      h="80px"
                      height="100%"
                    />
                  </Box>
                )}
                <Text ml="6" fontWeight="hairline">
                  {t('modalColorPicker.message')}
                </Text>
              </Stack>
              <Box w="100%" bg={color} borderRadius={16} h="200px" />
            </Stack>
          </Stack>
        </Flex>
      </ModalArrivedTime>
    </>
  )
}
