import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

interface InputProps extends ChakraTextareaProps {
  name: string
  label?: string
  error?: FieldError
  isRequired?: boolean
  children?: ReactNode
}

const TextareaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  InputProps
> = ({ name, label, error, isRequired, children, ...rest }, ref) => {
  return (
    <FormControl mr="4" isRequired={isRequired} isInvalid={!!error}>
      {!!label && (
        <FormLabel mt={6} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <ChakraTextarea
        name={name}
        id={name}
        color="gray.500"
        focusBorderColor="gray.900"
        bgColor="AppWorkspace"
        variant="flushed"
        _hover={{ bgColor: 'gray.50' }}
        size="lg"
        ref={ref}
        {...rest}
      >
        {children}
      </ChakraTextarea>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Textarea = forwardRef(TextareaBase)
