/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Divider,
  Checkbox,
  Spinner,
  HStack
} from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from 'react-hook-form'
import { LayoutDefault } from '../../../layouts/Default'
import { apiAuth } from '../../../services/apiAuth'
import { CardContainer } from '../../../components/molecules/CardContainer'
import { useAuth } from '../../../hooks/auth'
import { MultiSelectPermissions } from '../../../components/MultiSelectPermissions'

interface FormData {}

interface ParamsProps {
  id: string
}

interface User {
  id: number
  user: {
    id: number
    name: string
    email: string
    is_active: boolean
    is_specialist: boolean
    is_superuser: boolean
  }
  birth_date: string | null
  telephone: string | null
  social_name: string | null
  role: string | null
  observation: string | null
  image: string | null
}

interface PermissionsData {
  id: number
  name_en: string
  name_es: string
  name_pt_br: string
  system_permission_items: Array<{
    id: number
    code: string
    name_en: string
    name_es: string
    name_pt_br: string
    items_permission: Array<{
      id: number
      name_en: string
      name_es: string
      name_pt_br: string
    }>
  }>
}

interface PermissionsUserData {
  id: number
  system_group_id: number
  item_permission: {
    id: number
  }
  system_permission_item: {
    id: number
  }
}

interface SelectPermissionsData {
  system_group_id: number
  system_permission_item: {
    id: number
  }
  item_permission: {
    id: number
  }
}

type GetPermissions = Array<PermissionsData>

export const UpdatePermissions = () => {
  const { t } = useTranslation('pageUsers')
  const toast = useToast()
  const router = useHistory()
  const { formState, handleSubmit } = useForm<FormData>({})
  const { id } = useParams<ParamsProps>()
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [language] = useState<string | null>(() => {
    const languageDefault = localStorage.getItem('i18nextLng')

    return languageDefault
  })
  const [arrayPermission, setArrayPermission] = useState<PermissionsData[]>([])
  const [arrayConfigs, setArrayConfigs] = useState<SelectPermissionsData[]>([])
  const [arrayOpenTabs, setArrayOpenTabs] = useState<Array<number> | undefined>(
    undefined
  )

  useEffect(() => {
    apiAuth.get<User>(`users/${id}/`).then((response) => {
      setUser(response.data)
    })

    return () => {}
  }, [id])

  useEffect(() => {
    const getPermissions = () => {
      apiAuth
        .get<GetPermissions>(`system-permissions/`)
        .then((response) => {
          setArrayPermission(response.data)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    getPermissions()

    setIsLoading(false)

    return () => {}
  }, [id])

  useEffect(() => {
    if (
      arrayPermission[0]?.system_permission_items[0]?.items_permission[0]
        ?.name_pt_br
    ) {
      const GetPermissionsUser = () => {
        apiAuth
          .get<PermissionsUserData[]>(`users/${user?.user.id}/permissions/`)
          .then((response) => {
            const formattedPermissions = response.data.map((config) => {
              return {
                system_group_id: config.system_group_id,
                system_permission_item: {
                  id: config.system_permission_item.id
                },
                item_permission: {
                  id: config.item_permission.id
                }
              }
            })
            setArrayConfigs(formattedPermissions)
          })
      }
      GetPermissionsUser()
    }

    return () => {}
  }, [arrayPermission, user])

  const handlerAddAllPermissionsInGroup = (groupId: number) => {
    arrayPermission.forEach((group) => {
      if (group.id === groupId) {
        let permissionsSelect: SelectPermissionsData[] = []
        group.system_permission_items.forEach((permissionItem) => {
          permissionItem.items_permission.forEach((itemPermission) => {
            permissionsSelect = [
              ...permissionsSelect,
              {
                system_group_id: groupId,
                system_permission_item: {
                  id: permissionItem.id
                },
                item_permission: {
                  id: itemPermission.id
                }
              }
            ]
          })
        })

        const filterArrayConfigsByGroup = arrayConfigs.filter((config) => {
          return config.system_group_id !== groupId
        })

        setArrayConfigs([...filterArrayConfigsByGroup, ...permissionsSelect])
      }
    })
  }

  const handlerRemoveAllPermissionsInGroup = (groupId: number) => {
    const allConfigsValues = [...arrayConfigs]
    const arrayBySystemItemFilter = allConfigsValues.filter((config) => {
      return config.system_group_id !== groupId
    })

    setArrayConfigs(arrayBySystemItemFilter)
  }

  const handlerSelectPermissionsInComboBox = (
    groupId: number,
    idSystemItem: number,
    arrayNumbers: string[]
  ) => {
    // generate array configs
    const arrayConfigsGenerate = arrayNumbers.map((idPermission) => {
      return {
        system_group_id: groupId,
        system_permission_item: {
          id: idSystemItem
        },
        item_permission: {
          id: Number(idPermission)
        }
      }
    })

    const allConfigsValues = [...arrayConfigs]

    if (allConfigsValues.length > 0) {
      const arrayBySystemItemFilter = allConfigsValues.filter((config) => {
        return config.system_permission_item.id !== idSystemItem
      })

      setArrayConfigs([...arrayBySystemItemFilter, ...arrayConfigsGenerate])
    } else {
      setArrayConfigs(arrayConfigsGenerate)
    }
  }

  const handlerClickButtonView = () => {
    if (arrayOpenTabs?.length === undefined) {
      const arrayNumbers = arrayPermission.map((group, index) => {
        return index
      })
      setArrayOpenTabs(arrayNumbers)
    } else {
      setArrayOpenTabs(undefined)
    }
  }

  const renderAllMarksArrayByRealSelect = (systemItemId: number) => {
    const allConfigsValues = [...arrayConfigs]

    const arrayBySystemItemFilter = allConfigsValues.filter((config) => {
      return config.system_permission_item.id === systemItemId
    })

    const allValuesSelected = arrayBySystemItemFilter.map((config) => {
      return String(config.item_permission.id)
    })

    return allValuesSelected
  }

  // format for body
  const formattedPermissions = () => {
    const formattedValues = arrayConfigs.map((config) => {
      return {
        system_permission_item: {
          id: config.system_permission_item.id
        },
        item_permission: {
          id: config.item_permission.id
        }
      }
    })

    return formattedValues
  }

  const handlerSubmitUpdate: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    const payload = formattedPermissions()

    try {
      const { data } = await apiAuth.post(
        `users/${user?.user.id}/permissions/`,
        payload
      )
      if (data !== undefined) {
        toast({
          title: 'Atualização realizada com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/users')
        }, 2000)
      }
    } catch (err: any) {
      toast({
        title: 'Aconteceu um erro!',
        description: `${err.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  const renderPermissionsByLanguage = () => {
    switch (language) {
      case 'en':
        return (
          <CardContainer title={t('permissionConfig.container1.title')}>
            <Accordion index={arrayOpenTabs} allowMultiple w="100%">
              {arrayPermission.map((group) => (
                <>
                  <AccordionItem key={group.id}>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            _expanded={{ bg: 'blue.500', color: 'white' }}
                          >
                            <Box flex="1" textAlign="left">
                              {group.name_en}
                            </Box>
                            {isExpanded ? (
                              <MinusIcon fontSize="12px" />
                            ) : (
                              <AddIcon fontSize="12px" />
                            )}
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Flex w="100%" justifyContent="space-between" p="4">
                            <Box>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => {
                                  handlerAddAllPermissionsInGroup(group.id)
                                }}
                              >
                                {t(
                                  'permissionConfig.container1.allPermissionGroup'
                                )}
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => {
                                  handlerRemoveAllPermissionsInGroup(group.id)
                                }}
                              >
                                {t(
                                  'permissionConfig.container1.clearPermissionGroup'
                                )}
                              </Button>
                            </Box>
                          </Flex>
                          {group.system_permission_items?.map((item) => (
                            <>
                              <Flex key={item.id} w="100%" flexDirection="row">
                                <Flex
                                  w="40%"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Text
                                    mx="4"
                                    fontSize="14px"
                                    fontWeight="bold"
                                  >
                                    {item.name_en}
                                  </Text>
                                </Flex>
                                <MultiSelectPermissions
                                  label={t(
                                    'permissionConfig.container1.permission'
                                  )}
                                  options={item.items_permission.map(
                                    (permissionItem) => {
                                      return {
                                        id: String(permissionItem.id),
                                        name: permissionItem.name_en
                                      }
                                    }
                                  )}
                                  backChange={(values: any) => {
                                    handlerSelectPermissionsInComboBox(
                                      group.id,
                                      item.id,
                                      values
                                    )
                                  }}
                                  render={() => {
                                    const allMarks =
                                      renderAllMarksArrayByRealSelect(item.id)

                                    return allMarks
                                  }}
                                />
                              </Flex>
                              <Divider my="2" w="100%" />
                            </>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </>
              ))}
            </Accordion>
          </CardContainer>
        )
      case 'es':
        return (
          <CardContainer title={t('permissionConfig.container1.title')}>
            <Accordion index={arrayOpenTabs} allowMultiple w="100%">
              {arrayPermission.map((group) => (
                <>
                  <AccordionItem key={group.id}>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            _expanded={{ bg: 'blue.500', color: 'white' }}
                          >
                            <Box flex="1" textAlign="left">
                              {group.name_es}
                            </Box>
                            {isExpanded ? (
                              <MinusIcon fontSize="12px" />
                            ) : (
                              <AddIcon fontSize="12px" />
                            )}
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Flex w="100%" justifyContent="space-between" p="4">
                            <Box>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => {
                                  handlerAddAllPermissionsInGroup(group.id)
                                }}
                              >
                                {t(
                                  'permissionConfig.container1.allPermissionGroup'
                                )}
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => {
                                  handlerRemoveAllPermissionsInGroup(group.id)
                                }}
                              >
                                {t(
                                  'permissionConfig.container1.clearPermissionGroup'
                                )}
                              </Button>
                            </Box>
                          </Flex>
                          {group.system_permission_items?.map((item) => (
                            <>
                              <Flex key={item.id} w="100%" flexDirection="row">
                                <Flex
                                  w="40%"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Text
                                    mx="4"
                                    fontSize="14px"
                                    fontWeight="bold"
                                  >
                                    {item.name_es}
                                  </Text>
                                </Flex>
                                <MultiSelectPermissions
                                  label={t(
                                    'permissionConfig.container1.permission'
                                  )}
                                  options={item.items_permission.map(
                                    (permissionItem) => {
                                      return {
                                        id: String(permissionItem.id),
                                        name: permissionItem.name_es
                                      }
                                    }
                                  )}
                                  backChange={(values: any) => {
                                    handlerSelectPermissionsInComboBox(
                                      group.id,
                                      item.id,
                                      values
                                    )
                                  }}
                                  render={() => {
                                    const allMarks =
                                      renderAllMarksArrayByRealSelect(item.id)

                                    return allMarks
                                  }}
                                />
                              </Flex>
                              <Divider my="2" w="100%" />
                            </>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </>
              ))}
            </Accordion>
          </CardContainer>
        )
      default:
        return (
          <CardContainer title={t('permissionConfig.container1.title')}>
            <Button
              size="xs"
              colorScheme="blue"
              position="absolute"
              top="2"
              right="2"
              onClick={() => {
                handlerClickButtonView()
              }}
            >
              {arrayOpenTabs?.length === undefined
                ? t('permissionConfig.expanded')
                : t('permissionConfig.recall')}
            </Button>
            <Accordion index={arrayOpenTabs} allowMultiple w="100%">
              {arrayPermission.map((group) => (
                <>
                  <AccordionItem key={group.id}>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton
                            _expanded={{ bg: 'blue.500', color: 'white' }}
                          >
                            <Box flex="1" textAlign="left">
                              {group.name_pt_br}
                            </Box>
                            {isExpanded ? (
                              <MinusIcon fontSize="12px" />
                            ) : (
                              <AddIcon fontSize="12px" />
                            )}
                          </AccordionButton>
                        </h2>
                        <AccordionPanel>
                          <Flex w="100%" justifyContent="space-between" p="4">
                            <Box>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => {
                                  handlerAddAllPermissionsInGroup(group.id)
                                }}
                              >
                                {t(
                                  'permissionConfig.container1.allPermissionGroup'
                                )}
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                size="xs"
                                colorScheme="blue"
                                onClick={() => {
                                  handlerRemoveAllPermissionsInGroup(group.id)
                                }}
                              >
                                {t(
                                  'permissionConfig.container1.clearPermissionGroup'
                                )}
                              </Button>
                            </Box>
                          </Flex>
                          {group.system_permission_items?.map((item) => (
                            <>
                              <Flex key={item.id} w="100%" flexDirection="row">
                                <Flex
                                  w="40%"
                                  alignItems="center"
                                  justifyContent="flex-start"
                                >
                                  <Text
                                    mx="4"
                                    fontSize="14px"
                                    fontWeight="bold"
                                  >
                                    {item.name_pt_br}
                                  </Text>
                                </Flex>
                                <MultiSelectPermissions
                                  label={t(
                                    'permissionConfig.container1.permission'
                                  )}
                                  options={item.items_permission.map(
                                    (permissionItem) => {
                                      return {
                                        id: String(permissionItem.id),
                                        name: permissionItem.name_pt_br
                                      }
                                    }
                                  )}
                                  backChange={(values: any) => {
                                    handlerSelectPermissionsInComboBox(
                                      group.id,
                                      item.id,
                                      values
                                    )
                                  }}
                                  render={() => {
                                    const allMarks =
                                      renderAllMarksArrayByRealSelect(item.id)

                                    return allMarks
                                  }}
                                />
                              </Flex>
                              <Divider my="2" w="100%" />
                            </>
                          ))}
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                </>
              ))}
            </Accordion>
          </CardContainer>
        )
    }
  }

  return (
    <LayoutDefault
      onSubmit={handleSubmit(handlerSubmitUpdate)}
      title={`${t('permissionConfig.title')} ${
        user?.social_name ? `(${user.social_name})` : ''
      }`}
      urlBack="/settings/users"
    >
      <Stack w="100%" spacing="4" justifyContent="space-around">
        <Flex
          w="100%"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap="4"
          flexDir="row"
        >
          <Text>{t('permissionConfig.userSelect')}</Text>
          <Text>{user?.user?.name}</Text>
        </Flex>
        {isLoading ? (
          <Flex w="100%" justifyContent="center" alignItems="center">
            <Spinner color="blue.300" size="md" />
          </Flex>
        ) : (
          renderPermissionsByLanguage()
        )}
        <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
          <Button
            type="submit"
            size="md"
            fontSize="md"
            bg="blue.300"
            color="#fff"
            isLoading={formState.isSubmitting}
          >
            {t('create.button')}
          </Button>
        </Flex>
        <Flex w="100%" h="300px" />
      </Stack>
    </LayoutDefault>
  )
}
