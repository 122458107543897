/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../../components/molecules/CardContainer'
import { Input } from '../../../components/Form/Input'

import { ArrowBack } from '../../../components/atoms/arrowBack'
import { Sidebar } from '../../../components/Sidebar'
import { Header } from '../../../components/Header'

import { Switch } from '../../../components/Form/Switch'
import { useService } from './service'

import { remaskCaractersAll } from '../../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../../components/Permissions'

type FormData = {
  name: string
  is_active: boolean
  is_default: boolean
  observation: string
}

export function CreateAndUpdateCostCenters() {
  const [t] = useTranslation('pageCostCenters')
  const service = useService()
  const history = useHistory()
  const [localMethod, setLocal] = useState('')
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const [name, setName] = useState('')

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  const handleCreateOrUpdateCostOfCenter: SubmitHandler<FormData> = async (
    values
  ) => {
    const payload = {
      name,
      is_active: values.is_active,
      is_default: values.is_default,
      observation: values.observation
    }

    if (localMethod === 'create') {
      service.createRequest(payload)
    } else {
      const { id } = state

      service.updateRequest(payload, id)
    }
  }

  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }
  }, [])

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/financial/cost-centers">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateOrUpdateCostOfCenter)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="40%">
                <Input
                  label={t('create.container1.i1')}
                  onChange={(event) =>
                    setName(event.target.value.toUpperCase())
                  }
                  value={name}
                  isRequired
                  maxLength={40}
                  name="name"
                  error={errors.name}
                />
              </Box>
              <Flex w="50%" direction="row">
                {localMethod === 'create' ? (
                  <Switch
                    defaultChecked={
                      state?.is_active !== undefined ? state?.is_active : true
                    }
                    label={t('create.container1.i3')}
                    {...register('is_active')}
                  />
                ) : (
                  <PermissionComponent
                    spiCode="COST_CENTERS"
                    ipCode="INACTIVATE"
                  >
                    <Switch
                      defaultChecked={
                        state?.is_active !== undefined ? state?.is_active : true
                      }
                      label={t('create.container1.i3')}
                      {...register('is_active')}
                    />
                  </PermissionComponent>
                )}
                <Switch
                  defaultChecked={state?.is_default}
                  label={t('create.container1.i4')}
                  {...register('is_default')}
                />
              </Flex>
            </Stack>
            <Box my="4" w="100%">
              <Textarea
                placeholder={t('create.container1.observation')}
                {...register('observation')}
                defaultValue={state?.observation}
              />
            </Box>
            <Flex />
          </CardContainer>
          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
