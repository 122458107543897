import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router'
import { apiAuth } from '../services/apiAuth'
import { queryClient } from '../services/reactQuery'
import { useAuth } from './auth'

export const useCreate = () => {
  // hooks
  const { clinicId } = useAuth()
  const router = useHistory()
  const toast = useToast()

  async function createNewBill(payload: any) {
    try {
      const response = await apiAuth.post(`clinics/postings-group/`, payload)

      console.log(payload)
      if (response.data.id !== undefined) {
        // Invalidate Cache
        queryClient.invalidateQueries('billsToPay')

        // Message Success
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
        // Redirect to List Payable
        setTimeout(() => {
          router.push('/finances/bills-to-pay')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu um Error!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
      console.log(error.message)
    }
  }

  return {
    createNewBill
  }
}
