import styled, { css } from 'styled-components'

const dragActive = css`
  border-color: #78e5d5;
`
const dragReject = css`
  border-color: #e57878;
`
interface DropContainerProps {
  isDragActive: boolean
  isDragReject: boolean
}

export const DropContainer = styled.div.attrs<DropContainerProps>({
  className: 'dropzone'
})`
  border: 2px dashed #ddd;
  border-radius: 8px;
  cursor: 'pointer';
  transition: height 0.2s ease-in;
  position: relative;
  ${(props: DropContainerProps) => !!props.isDragActive && dragActive};
  ${(props: DropContainerProps) => !!props.isDragReject && dragReject};
`
export const ContentLeft = styled.div`
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      width: 100%;
    }
  }
  h4 {
    margin-top: 9px;
    margin-bottom: 4px;
    color: #4f4c4c;
    font-size: 16px;
  }
  p {
    color: #848080;
    font-size: 14px;
    text-align: center;
    width: 100%;
  }
  @media (max-width: 1180px) {
    p {
      margin-top: 24px;
    }
  }
`
const messageColors = {
  default: '#4F4C4C',
  error: '#e57878',
  success: '#78e5d5'
}

interface ContentDropzoneProps {
  type?: 'default' | 'error' | 'success'
}

export const ContentDropzone = styled.div<ContentDropzoneProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80px;
  color: ${(props) => messageColors[props.type || 'default']};

  p {
    font-size: 14px;
    margin: 0 1rem;
  }
`
