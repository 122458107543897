/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../../services/apiAuth'

export type SpotData = {
  id?: number
  name: string
  is_active?: boolean
  description?: string | null
}

export type Props = {
  init: boolean
}

const BASE_URL = 'clinics/attendance-spots/'

export const useAttendance = () => {
  const [loadingRest, setLoadingRest] = useState(false)

  // terceiros
  const router = useHistory()
  const toast = useToast()

  // funcao para criar um conveio
  async function createAttendance({ name, is_active, description }: SpotData) {
    try {
      setLoadingRest(true)
      const { data } = await apiAuth.post(BASE_URL, {
        name,
        is_active,
        description
      })

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/attendance')
        }, 2000)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // funcao para editar um conveio
  async function updateAttendance({
    id,
    name,
    is_active,
    description
  }: SpotData) {
    try {
      setLoadingRest(true)
      await apiAuth.put(`${BASE_URL}${id}/`, { name, is_active, description })

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/settings/attendance')
      }, 2000)
    } catch (error: any) {
      toast({
        title: 'Erro ao Atualizar!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // export
  return {
    createAttendance,
    updateAttendance,
    loadingRest
  }
}
