/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import {
  Stack,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Flex,
  Center,
  Tag,
  Skeleton,
  Tooltip,
  Box,
  Heading,
  Checkbox,
  Button,
  MenuItem,
  useToast
} from '@chakra-ui/react'
import React, { FC, useState, useCallback, useEffect } from 'react'
import format from 'date-fns/format'
import { useTranslation } from 'react-i18next'
import { FcInfo } from 'react-icons/fc'
import { FiPercent, FiDollarSign, FiEdit } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { LayoutBlank } from '../../layouts/blank'
import { NotFound } from '../../components/molecules/NotFound'
import {
  useConciliation,
  getConciliation
} from '../../hooks/conciliation/useConciliation'
import { useModal } from '../../hooks/useModal'
import { Input } from '../../components/Form/Input'
import { MultiSelectMenuFilter } from '../../components/MultiSelectFilters'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { ModalArrivedTime } from '../../components/Modal/confirmArrivedTime'
import { apiAuth } from '../../services/apiAuth'
import { WaysData } from '../../hooks/waysReceives/useWays'
import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { useAuth } from '../../hooks/auth'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { useProducts, getProducts } from '../../hooks/products/useProducts'
import { Pagination } from '../../components/PaginationNew'

// debounce
let timer: any = null

export const ProductsHistory: FC = () => {
  const [t] = useTranslation('pageProductsHistory')
  const { currency } = useAuth()
  const toast = useToast()
  const {
    isOpenConciliation,
    handlerCloseModal,
    endAtConciliation,
    statusConciliation,
    startAtConciliation,
    setEndAtConciliation,
    setStatusConciliation,
    setStartAtConciliation,
    statusReceiveConciliation,
    setStatusReceiveConciliation,
    endAtScheduleConciliation,
    startAtScheduleConciliation,
    setEndAtScheduleConciliation,
    setStartAtScheduleConciliation,
    waysParam,
    setWaysParam
  } = useModal()
  const [currentPage, setCurrentPage] = useState(1)
  const [searchState, setSearchState] = useState('')
  const [idItem, setIdItem] = useState(0)
  const [status, setStatus] = useState(1)
  const [isChange, setChange] = useState(false)
  // wayToReceive states
  const [selectWaysToReceives, setSelectWaysToReceives] = useState<
    WaysData[] | []
  >([])
  // modal edit
  const [isOpen, setIsOpen] = useState(false)
  const [indexEdit, setIndexEdit] = useState(0)
  const [dueDate, setDueDate] = useState('')
  const [taxAbsolute, setTaxAbsolute] = useState('')
  const [taxPercent, setTaxPercent] = useState('')
  const [interestAbsolute, setInterestAbsolute] = useState('')
  const [interestPercent, setInterestPercent] = useState('')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]

  // get all wayToReceives selected
  useEffect(() => {
    const getWaysToReceives = async () => {
      const { data } = await apiAuth.get('clinics/ways-receives/')

      setSelectWaysToReceives(data.results)
    }

    getWaysToReceives()

    return () => {}
  }, [])

  const getProductsHistory = useProducts(
    currentPage,
    getProducts(currentPage, searchState),
    searchState,
    true
  )

  useEffect(() => {
    if (isChange === false) {
      return () => {}
    }

    if (isChange) {
      getProductsHistory.refetch()
      getProductsHistory.refetch()

      setChange(false)
    }

    return () => {}
  }, [isChange])

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setSearchState(value)
      }, 500)
    },
    []
  )

  return (
    <>
      <LayoutBlank
        title={t('title')}
        placeholder={t('search')}
        isHaveFilter
        nameFilter="Conciliation"
        capturaChange={(event) => SearchInputCallbackValue(event)}
        noRenderIsActive
        refetch={getProductsHistory.refetch}
        isFetching={getProductsHistory.isFetching}
      >
        <Stack w="100%" spacing="4" direction={['row', 'column']}>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th>{t('t1')}</Th>
                <Th>{t('t2')}</Th>
                <Th>{t('t3')}</Th>
                <Th>{t('t4')}</Th>
                <Th>{t('t5')}</Th>
                <Th>{t('t6')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getProductsHistory?.isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr key={skeleton}>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <></>
              )}
            </Tbody>
          </Table>
          {getProductsHistory.data?.totalCountOfPage === 0 &&
            !getProductsHistory.isLoading && <NotFound />}
          {!(
            getProductsHistory.data?.totalCountOfPage === 0 &&
            !getProductsHistory.isLoading
          ) && (
            <Pagination
              totalCountOfRegisters={
                getProductsHistory.data?.totalCountOfPage || 0
              }
              currentPage={currentPage}
              onPageChange={setCurrentPage}
            />
          )}
        </Stack>
      </LayoutBlank>
      {/*       <ModalArrivedTime
        title={t('filters.title')}
        isOpen={isOpenConciliation}
        onClose={handlerCloseModalBillsToReceive}
        onUpdate={() => {}}
        notRenderFooter
      >
        <Stack w="100%" p="4" dir="column" h="450px">
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('filters.selects')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="flex-start">
            <MultiSelectMenuFilter
              label={t('filters.ways')}
              options={selectWaysToReceives.map((way) => {
                return {
                  id: String(way.id),
                  name: way?.name
                }
              })}
              backChange={(values) => {}}
              render={() => {
                const ways: any = waysParam
                return ways
              }}
            />

            <MultiSelectMenuFilter
              label={t('filters.statusReceive')}
              options={[]}
              backChange={(values) => {}}
              render={() => {
                const statusRender: any = statusReceiveConciliation
                return statusRender
              }}
            />

            <MultiSelectMenuFilter
              label={t('filters.statusConciliation')}
              options={[]}
              backChange={(values) => {}}
              render={() => {
                const statusRender: any = statusConciliation
                return statusRender
              }}
            />
          </Flex>
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('filters.rangeDate')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="space-between" gap={2}>
            <Input
              name="dateInitial"
              type="date"
              isRequired
              label={t('filters.dateInitial')}
              value={startAtConciliation.substring(0, 10)}
              onChange={(event) =>
                setStartAtConciliation(`${event.target.value}T00:00`)
              }
            />
            <Input
              name="dateFinal"
              type="date"
              isRequired
              label={t('filters.dateFinal')}
              value={endAtConciliation.substring(0, 10)}
              onChange={(event) =>
                setEndAtConciliation(`${event.target.value}T23:59`)
              }
            />
          </Flex>
          <Center>
            <Text fontWeight="bold" fontSize="lg">
              {t('filters.rangeDateSchedule')}
            </Text>
          </Center>
          <Flex w="100%" justifyContent="space-between" gap={2}>
            <Input
              name="dateInitialSchedule"
              type="date"
              label={t('filters.dateInitial')}
              value={startAtScheduleConciliation}
              onChange={(event) =>
                setStartAtScheduleConciliation(event.target.value)
              }
            />
            <Input
              name="dateFinalSchedule"
              type="date"
              label={t('filters.dateFinal')}
              value={endAtScheduleConciliation}
              onChange={(event) =>
                setEndAtScheduleConciliation(event.target.value)
              }
            />
          </Flex>
        </Stack>
      </ModalArrivedTime> */}
    </>
  )
}
