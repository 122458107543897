import { Center, Heading, HStack, Text, VStack } from '@chakra-ui/react'

import { useHistory } from 'react-router-dom'
import { NotFound } from '../../components/molecules/NotFound'
import { LayoutDefault } from '../../layouts/Default'

export function PageNotFound() {
  return (
    <>
      <LayoutDefault title="" urlBack="/scheduling" noRenderHelp goBack>
        <Center flex="1">
          <VStack mt="100px">
            <Heading size="2xl" color="blue.300">
              Página não encontrada
            </Heading>
            <HStack spacing="12">
              <Heading fontSize="7xl" color="blue.300">
                404
              </Heading>
              <Text color="blue.300" fontWeight="bold">
                Não conseguimos encontrar o que você estava procurando.
              </Text>
            </HStack>
            <NotFound />
          </VStack>
        </Center>
      </LayoutDefault>
    </>
  )
}
