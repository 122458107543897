/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Heading
} from '@chakra-ui/react'
import { FiEdit } from 'react-icons/fi'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  ProductsGroupData,
  getProductsGroup,
  useProductsGroup
} from '../../hooks/groupProducts/useGroupProducts'
import { useAuth } from '../../hooks/auth'
import { LayoutList } from '../../layouts/List'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { formatCurrency } from '../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../components/Permissions'

// debounce
let timer: any = null

export function ProductsGroup() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageProducts')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  // state
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const getProductsGroupData = useProductsGroup(
    currentPage,
    getProductsGroup(currentPage, searchState, isActive),
    searchState,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  return (
    <LayoutList
      title={t('title')}
      urlNew="/group-products/create"
      refetch={getProductsGroupData.refetch}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      placeholder={t('search')}
      spiCode="PRODUCT_GROUPS"
    >
      <Box>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getProductsGroupData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {getProductsGroupData.data?.products.map(
                  (group: ProductsGroupData, index: number) => (
                    <Tr key={group.id} maxHeight="40px">
                      <Td>
                        <Text fontSize="sm">{group.name}</Text>
                      </Td>
                      {!group?.stock_type && <Td />}
                      {group?.stock_type.id === 1 && (
                        <Td>
                          <Text fontSize="sm">{t('type.1')}</Text>
                        </Td>
                      )}
                      {group?.stock_type.id === 2 && (
                        <Td>
                          <Text fontSize="sm">{t('type.2')}</Text>
                        </Td>
                      )}
                      {group?.stock_type.id === 3 && (
                        <Td>
                          <Text fontSize="sm">{t('type.3')}</Text>
                        </Td>
                      )}
                      <Td>
                        <Text fontSize="sm" noOfLines={1}>
                          {group.description}
                        </Text>
                      </Td>
                      <Td>
                        <PermissionComponent
                          spiCode="PRODUCT_GROUPS"
                          ipCode="EDIT"
                        >
                          <Link
                            to={{
                              pathname: `/group-products/update/${group.id}`,
                              state: group
                            }}
                          >
                            <Tooltip
                              label="Editar"
                              aria-label="editar"
                              hasArrow
                              placement="top-start"
                            >
                              <Box ml="1rem" w="24px">
                                <FiEdit
                                  color="#9699B0"
                                  size={16}
                                  style={{ cursor: 'pointer' }}
                                />
                              </Box>
                            </Tooltip>
                          </Link>
                        </PermissionComponent>
                      </Td>
                    </Tr>
                  )
                )}
              </>
            )}
          </Tbody>
        </Table>
        {getProductsGroupData.data?.totalCountOfPage === 0 &&
          !getProductsGroupData.isLoading && <NotFound />}
      </Box>
      {!(
        getProductsGroupData.data?.totalCountOfPage === 0 &&
        !getProductsGroupData.isLoading
      ) && (
        <Pagination
          totalCountOfRegisters={
            getProductsGroupData.data?.totalCountOfPage || 0
          }
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </LayoutList>
  )
}
