/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-param-reassign */
import { FC, useEffect, useRef, useState } from 'react'
import {
  Button,
  CloseButton,
  Flex,
  Text,
  VStack,
  Box,
  Checkbox
} from '@chakra-ui/react'
import { v4 as uuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { FiEdit, FiGrid } from 'react-icons/fi'
import { useDrag, useDrop } from 'react-dnd'
import { Input } from '../../Form/Input'
import { useDnd } from '../../../hooks/useDnd'

interface InputBuildProps {
  data: {
    id: string
    name: string
    isRequired: boolean
    placeholder: string
    type: string
    value: string
    defaultValue: string
    label: string
    error?: boolean
  }
  onExclude?: () => void
  index: number
  indexList: number
}

interface DropAndDragItem {
  type: string
  id: string | number
  index: number
  indexList: number
  name: string
}

export const InputBuild: FC<InputBuildProps> = ({
  data,
  onExclude,
  index,
  indexList
}) => {
  const [t] = useTranslation('pageCreatingForms')
  const [isRequired, setIsRequired] = useState(data.isRequired)
  const [placeholder, setPlaceholder] = useState(data.placeholder)
  const [defaultValue, setDefaultValue] = useState(data.defaultValue)
  const [label, setLabel] = useState(data.label)
  const [showEdit, setShowEdit] = useState(false)
  const [error, setError] = useState(data?.error)

  const { MoveForm, listForms, setListForms } = useDnd()
  const ref = useRef<any>(null)
  const styledGrabbing = {
    border: '2px dashed rgba(0, 0, 0, 0.2)',
    pt: '5px',
    borderRadius: '0',
    bg: '#d3d3d3',
    cursor: 'grabbing'
  }
  const [{ isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    type: 'GROUP',
    item: { type: 'GROUP', id: data?.id, index, name: data?.name, indexList }
  })

  const [, dropRef] = useDrop({
    accept: 'GROUP',
    hover(item: DropAndDragItem, monitor) {
      const draggedListIndex = item.indexList
      const targetListIndex = indexList

      const draggedIndex = item.index
      const targetIndex = index

      if (
        draggedIndex === targetIndex &&
        draggedListIndex === targetListIndex
      ) {
        return
      }

      const targetSize = ref?.current.getBoundingClientRect()
      const targetCenter = (targetSize.bottom - targetSize.top) / 2

      const draggedOffset = monitor.getClientOffset()
      const coordY = draggedOffset !== undefined ? draggedOffset?.y : 1
      const coordX = draggedOffset !== undefined ? draggedOffset?.x : 1
      const draggedTop = coordY !== undefined ? coordY - targetSize.top : 1

      if (draggedIndex < targetIndex && draggedTop < targetCenter) {
        return
      }

      if (draggedIndex > targetIndex && draggedTop > targetCenter) {
        return
      }

      MoveForm(draggedListIndex, targetListIndex, draggedIndex, targetIndex)

      item.index = targetIndex
      item.indexList = targetListIndex
    }
  })

  dragRef(dropRef(ref))

  const handlerChangeFieldValue = () => {
    const group = listForms[indexList]
    const object = {
      isRequired,
      defaultValue,
      name: uuidV4(),
      label,
      placeholder,
      maxLength: 150,
      error: label !== '' ? false : true
    }

    const fields = group.fields.map((field) => {
      return field.id === data.id ? { ...field, ...object } : field
    })

    const objectField = {
      fields
    }

    const newGroupValues = listForms.map((groupList, indexGroup) => {
      return indexList === indexGroup
        ? { ...groupList, ...objectField }
        : groupList
    })

    return newGroupValues
  }

  return (
    <>
      <Flex
        w="100%"
        h="60px"
        ref={ref}
        bgColor="white"
        p="2"
        justifyContent="space-between"
        style={isDragging ? styledGrabbing : undefined}
        border={error ? '1px solid red' : '1px solid #808080'}
        alignItems="center"
        borderRadius={8}
      >
        <Flex gap={2} direction="row">
          <FiGrid size={22} color="gray" />
          {/* question or label form */}
          <Text>{label}</Text>
          <Text fontSize="14px">{t('fields.1')}</Text>
          <Text fontSize="14px" fontWeight="hairline">
            {`(150 ${t('characters')})`}
          </Text>
          {isRequired ? <Text fontSize="14px">{t('isRequired')}</Text> : null}
          {label === '' ? (
            <Text fontSize="14px" color="red">
              {t('errors.type.1')}
            </Text>
          ) : null}
        </Flex>
        <Flex gap={4}>
          <Button
            colorScheme={!showEdit ? 'green' : 'blue'}
            color={!showEdit ? 'white' : 'white'}
            size="sm"
            fontSize={14}
            onClick={() => {
              setShowEdit(!showEdit)
              if (showEdit) {
                const newValuesGroups = handlerChangeFieldValue()

                setListForms(newValuesGroups)
              }
            }}
          >
            {!showEdit ? <FiEdit /> : `${t('saveEdit')}`}
          </Button>
          <CloseButton
            bg="red"
            color="white"
            onClick={() => {
              onExclude?.()
            }}
          />
        </Flex>
      </Flex>
      {showEdit ? (
        <VStack
          w="100%"
          p="2"
          spacing="2"
          alignItems="baseline"
          justifyContent="flex-start"
        >
          <Input
            name="label"
            label={`${t('question')}`}
            value={label}
            onChange={(event) => {
              setLabel(event.target.value)
            }}
          />
          <Box>
            <Text fontSize="14px">{t('questionIsRequired')}</Text>
            <Checkbox
              name="isRequired"
              defaultChecked={isRequired}
              onChange={(event) => setIsRequired(event.target.checked)}
            />
          </Box>
        </VStack>
      ) : null}
    </>
  )
}
