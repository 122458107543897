/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  useBreakpointValue,
  Textarea
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useService } from './service'
import { PermissionComponent } from '../../components/Permissions'

type FormData = {
  description: string
  is_active: boolean
  days: string
}

export function CreateAndUpdateMeans() {
  const [t] = useTranslation('pageMeansToReceipts')
  const service = useService()
  const history = useHistory()
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const [localMethod, setLocal] = useState('')
  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const [name, setName] = useState('')

  useEffect(() => {
    if (state?.name) {
      setName(state?.name)
    }
  }, [])

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  const handlerCreateMeans: SubmitHandler<FormData> = async (values) => {
    const payload = {
      name: name || state?.name,
      observation: values.description || state?.description,
      is_active: values.is_active,
      way_to_receive_parcels: state?.way_to_receive_parcels || [],
      day_to_receive: Number(values.days)
    }

    if (localMethod === 'create') {
      service.createRequest(payload)
    } else {
      const { id } = state

      service.updateRequest(payload, id)
    }
  }

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/financial/receiving-method">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>

      <Flex w="100%" mx="auto" bg="#f4f4f4">
        <Flex>
          <Sidebar />
        </Flex>
        <Flex
          display="flex"
          direction="column"
          width="100%"
          ml={isWideVersion ? '75px' : '2'}
          mr="15px"
          max-width="1125px"
          height="calc(100vh - 80px)"
          overflow="auto"
        >
          <Flex
            as="form"
            display="flex"
            direction="column"
            width="100%"
            max-width="1440px"
            pr="2"
            mt="4"
            pb="6"
            height="calc(100vh - 80px)"
            onSubmit={handleSubmit(handlerCreateMeans)}
          >
            <Stack spacing="4">
              <CardContainer title={t('create.container1.title')}>
                <Stack direction="row" my="2" spacing="6">
                  <Box w="40%">
                    <Input
                      label={t('create.container1.i1')}
                      name="name"
                      isRequired
                      value={name}
                      onChange={(event) =>
                        setName(event.target.value.toUpperCase())
                      }
                    />
                  </Box>
                  <Box w="30%">
                    <Input
                      label={t('create.container1.i2')}
                      type="number"
                      defaultValue={state?.day_to_receive}
                      placeholder="0"
                      maxLength={3}
                      {...register('days')}
                    />
                  </Box>
                  {localMethod === 'create' ? (
                    <Box w="30%" ml="8">
                      <Switch
                        defaultChecked={
                          state?.is_active !== undefined
                            ? state?.is_active
                            : true
                        }
                        label={t('create.container1.i3')}
                        {...register('is_active')}
                      />
                    </Box>
                  ) : (
                    <PermissionComponent
                      spiCode="WAYS_TO_RECEIVE"
                      ipCode="INACTIVATE"
                    >
                      <Box w="30%" ml="8">
                        <Switch
                          defaultChecked={
                            state?.is_active !== undefined
                              ? state?.is_active
                              : true
                          }
                          label={t('create.container1.i3')}
                          {...register('is_active')}
                        />
                      </Box>
                    </PermissionComponent>
                  )}
                </Stack>
                <Stack direction="row" my="2" spacing="6">
                  <Box w="100%">
                    <Text my="2" fontSize="14px">
                      {t('create.container1.i4')}
                    </Text>
                    <Textarea
                      defaultValue={state?.observation}
                      placeholder={t('create.container1.description')}
                      maxLength={300}
                      {...register('description')}
                    />
                  </Box>
                </Stack>
                <Flex />
              </CardContainer>
            </Stack>
            <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
              <Button
                type="submit"
                size="md"
                fontSize="md"
                bg="blue.300"
                color="#fff"
                isLoading={formState.isSubmitting}
              >
                {t('create.button')}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
}
