/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import React, { FC, useEffect, useState } from 'react'
import {
  Flex,
  Button,
  Stack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Switch
} from '@chakra-ui/react'
import { useHistory, useParams } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LayoutDefault } from '../../layouts/Default'
import { CardContainer } from '../../components/molecules/CardContainer'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'
import { mask } from '../../utils/mask'
import { Parcels } from '../../hooks/waysReceives/useWays'
import {
  formatNumberToFixedTwo,
  formatToFixedTwo,
  remaskCaractersAll
} from '../../utils/fns/removeCaracters'
import { useService } from './service'

type FormData = {}

interface Installments {
  id: string
  percentageRate: string
  flatRate: string
  percentageInterest: string
  index: number
  flatInterest: string
  isActive: boolean
}

type InstallmentsData = Array<Installments>

export const MeansInstallments: FC = () => {
  // hooks
  const history = useHistory()
  const services = useService()
  const [t] = useTranslation('pageMeansToReceipts')
  const { handleSubmit, formState } = useForm<FormData>({})
  const { state }: any = history.location
  const installmentsArray = [
    {
      id: '1',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 0,
      flatInterest: '',
      isActive: false
    },
    {
      id: '2',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 1,
      flatInterest: '',
      isActive: false
    },
    {
      id: '3',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 2,
      flatInterest: '',
      isActive: false
    },
    {
      id: '4',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 3,
      flatInterest: '',
      isActive: false
    },
    {
      id: '5',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 4,
      flatInterest: '',
      isActive: false
    },
    {
      id: '6',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 5,
      flatInterest: '',
      isActive: false
    },
    {
      id: '7',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 6,
      flatInterest: '',
      isActive: false
    },
    {
      id: '8',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 7,
      flatInterest: '',
      isActive: false
    },
    {
      id: '9',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 8,
      flatInterest: '',
      isActive: false
    },
    {
      id: '10',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 9,
      flatInterest: '',
      isActive: false
    },
    {
      id: '11',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 10,
      flatInterest: '',
      isActive: false
    },
    {
      id: '12',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 11,
      flatInterest: '',
      isActive: false
    },
    {
      id: '13',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 12,
      flatInterest: '',
      isActive: false
    },
    {
      id: '14',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 13,
      flatInterest: '',
      isActive: false
    },
    {
      id: '15',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 14,
      flatInterest: '',
      isActive: false
    },
    {
      id: '16',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 15,
      flatInterest: '',
      isActive: false
    },
    {
      id: '17',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 16,
      flatInterest: '',
      isActive: false
    },
    {
      id: '18',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 17,
      flatInterest: '',
      isActive: false
    },
    {
      id: '19',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 18,
      flatInterest: '',
      isActive: false
    },
    {
      id: '20',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 19,
      flatInterest: '',
      isActive: false
    },
    {
      id: '21',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 20,
      flatInterest: '',
      isActive: false
    },
    {
      id: '22',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 21,
      flatInterest: '',
      isActive: false
    },
    {
      id: '23',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 22,
      flatInterest: '',
      isActive: false
    },
    {
      id: '24',
      percentageRate: '',
      flatRate: '',
      percentageInterest: '',
      index: 23,
      flatInterest: '',
      isActive: false
    }
  ]

  // state
  const [installments, setInstallments] =
    useState<InstallmentsData>(installmentsArray)

  // format way_to_receive_parcels
  const formatWayToReceiveParcels = () => {
    const arrayFormatBody = installments.map((parcel, index) => {
      return {
        parcel_number: index,
        tax_percentage:
          parcel?.percentageRate !== ''
            ? formatToFixedTwo(parcel.percentageRate)
            : null,
        tax_absolute:
          parcel?.flatRate !== '' ? formatToFixedTwo(parcel.flatRate) : null,
        interest_rate_percentage:
          parcel?.percentageInterest !== ''
            ? formatToFixedTwo(parcel.percentageInterest)
            : null,
        interest_rate_absolule:
          parcel?.flatInterest !== ''
            ? formatToFixedTwo(parcel.flatInterest)
            : null,
        is_active: parcel?.isActive
      }
    })

    return arrayFormatBody
  }

  useEffect(() => {
    if (state?.way_to_receive_parcels.length > 0) {
      const backValuesInstallment = installments.map((parcel, index) => {
        return state?.way_to_receive_parcels[index]?.id
          ? {
              id: parcel.id,
              percentageRate:
                state?.way_to_receive_parcels[index]?.tax_percentage !== null
                  ? formatNumberToFixedTwo(
                      state?.way_to_receive_parcels[index]?.tax_percentage
                    )
                  : '',
              flatRate:
                state?.way_to_receive_parcels[index]?.tax_absolute !== null
                  ? formatNumberToFixedTwo(
                      state?.way_to_receive_parcels[index].tax_absolute
                    )
                  : '',
              percentageInterest:
                state?.way_to_receive_parcels[index]
                  ?.interest_rate_percentage !== null
                  ? formatNumberToFixedTwo(
                      state?.way_to_receive_parcels[index]
                        ?.interest_rate_percentage
                    )
                  : '',
              index,
              flatInterest:
                state?.way_to_receive_parcels[index]?.interest_rate_absolule !==
                null
                  ? formatNumberToFixedTwo(
                      state?.way_to_receive_parcels[index]
                        ?.interest_rate_absolule
                    )
                  : '',
              isActive: state?.way_to_receive_parcels[index]?.is_active
            }
          : {
              id: parcel.id,
              index,
              percentageRate: '',
              flatRate: '',
              percentageInterest: '',
              flatInterest: '',
              isActive: false
            }
      })

      setInstallments(backValuesInstallment)
    } else {
      setInstallments(installmentsArray)
    }
  }, [])

  const handlerEditInstallments: SubmitHandler<FormData> = async (
    values,
    event
  ) => {
    event?.preventDefault()

    const way_to_receive_parcels = formatWayToReceiveParcels()

    const payload = {
      name: state?.name,
      day_to_receive: state?.day_to_receive,
      observation: state?.observation,
      is_active: state?.is_active,
      way_to_receive_parcels
    }

    await services.updateRequest(payload, state?.id)
  }

  const handlerInputChange = (
    event: React.FormEvent<HTMLInputElement>,
    index: number,
    type?: string
  ) => {
    const values: any = [...installments]

    if (type === 'checked') {
      values[index][event.currentTarget.name] = event.currentTarget.checked
    } else {
      values[index][event.currentTarget.name] = event.currentTarget.value
    }

    setInstallments(values)
  }

  return (
    <>
      <LayoutDefault
        urlBack="/financial/receiving-method"
        title={`${t('installments.title')} (${state?.name || ''})`}
        onSubmit={handleSubmit(handlerEditInstallments)}
      >
        <Stack justify="space-evenly" spacing="4" w="100%">
          <CardContainer title={t('installments.container1.title')}>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('installments.container1.i1')}</Th>
                  <Th>{t('installments.container1.i2')}</Th>
                  <Th>{t('installments.container1.i3')}</Th>
                  <Th>{t('installments.container1.i4')}</Th>
                  <Th>{t('installments.container1.i5')}</Th>
                  <Th>{t('installments.container1.i6')}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {installments.map((item: Installments, index) => (
                  <Tr key={item.id}>
                    <Td>{item.index + 1}</Td>
                    <Td>
                      <Box>
                        <TextFieldControlled
                          name="flatRate"
                          mask="double"
                          initialValue={item.flatRate}
                          inputOnChange={(event) => {
                            handlerInputChange(event, index)
                          }}
                          placeholder="0.00"
                          maxLength={10}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <TextFieldControlled
                          name="percentageRate"
                          mask="porcentagem"
                          initialValue={item.percentageRate}
                          placeholder="0.00"
                          inputOnChange={(event) => {
                            handlerInputChange(event, index)
                          }}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <TextFieldControlled
                          name="flatInterest"
                          initialValue={item.flatInterest}
                          mask="double"
                          placeholder="0.00"
                          maxLength={10}
                          inputOnChange={(event) => {
                            handlerInputChange(event, index)
                          }}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Box>
                        <TextFieldControlled
                          name="percentageInterest"
                          mask="double"
                          initialValue={item.percentageInterest}
                          placeholder="0.00"
                          inputOnChange={(event) => {
                            handlerInputChange(event, index)
                          }}
                        />
                      </Box>
                    </Td>
                    <Td>
                      <Switch
                        isChecked={item.isActive}
                        name="isActive"
                        onChange={(event) => {
                          handlerInputChange(event, index, 'checked')
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </CardContainer>
          <Flex mt="4" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>

          <Stack w="100%" my="2" h="300px" />
        </Stack>
      </LayoutDefault>
    </>
  )
}
