import {
  AspectRatio,
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalDocumentProps {
  isOpen: boolean
  onClose: () => void
  src: string
}

export const ModalDocument = ({ isOpen, onClose, src }: ModalDocumentProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size="full"
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="transparent"
          justifyContent="center"
          alignItems="center"
        >
          <ModalBody
            justifyContent="center"
            alignItems="center"
            position="relative"
          >
            <CloseButton
              onClick={onClose}
              position="fixed"
              bg="red.500"
              _hover={{
                bg: 'red.700'
              }}
              color="white"
              top="6"
              right="12"
              size="md"
            />
            <AspectRatio
              mt="8"
              w="750px"
              maxH="650px"
              justifyContent="center"
              alignItems="center"
            >
              <iframe title="Document" src={src} width="100%" height={1050} />
            </AspectRatio>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
