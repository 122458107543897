import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  error?: FieldError
  isRequired?: boolean
  children?: ReactNode
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, label, error, isRequired, children, ...rest },
  ref
) => {
  return (
    <FormControl mr="4" isRequired={isRequired} isInvalid={!!error}>
      {!!label && (
        <FormLabel mt={6} htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <ChakraInput
        name={name}
        id={name}
        color="gray.500"
        focusBorderColor="gray.900"
        bgColor="AppWorkspace"
        variant="flushed"
        _hover={{ bgColor: 'gray.50' }}
        size="lg"
        ref={ref}
        {...rest}
      >
        {children}
      </ChakraInput>

      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
