/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'
import { Draft } from '../../components/molecules/Draft'
import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useAttendance } from './service'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { PermissionComponent } from '../../components/Permissions'

type FormData = {
  is_active: boolean
  is_special: boolean
  description: string
}

type Actions = {
  label: string
  value: string
}

type Specialist = {
  label: string
  value: number
}

type SpecialistData = Array<Specialist>

export function UpdateRecipe() {
  const [t] = useTranslation('pageRecipe')
  const attendanceService = useAttendance()
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const optionsDefaultActions = [
    {
      value: '1',
      label: `${t('status.1')}`
    },
    {
      value: '2',
      label: `${t('status.2')}`
    }
  ]
  const optionsDefaultRecipes = [
    {
      value: '1',
      label: `${t('recipes.1')}`
    },
    {
      value: '2',
      label: `${t('recipes.2')}`
    }
  ]
  // state
  const [name, setName] = useState('')
  const [actions, setActions] = useState<Actions>()
  const [specialists, setSpecialists] = useState<SpecialistData>([])
  const [valueDraft, setValueDraft] = useState<any>()
  const [recipesType, setRecipesType] = useState<Actions>()
  const router = useHistory()
  const { state }: any = router.location

  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }

    if (state?.prescription_detail !== undefined) {
      if (state.prescription_detail === 'undefined') {
        setValueDraft('')
      } else {
        setValueDraft(state.prescription_detail)
      }
    }

    if (state?.status !== undefined) {
      optionsDefaultActions.forEach((action) => {
        if (String(state.status) === action.value) {
          setActions(action)
        }
      })
    }

    if (state?.type !== undefined) {
      optionsDefaultRecipes.forEach((recipe) => {
        if (String(state.type) === recipe.value) {
          setRecipesType(recipe)
        }
      })
    }

    if (state?.users !== undefined) {
      setSpecialists(
        state?.users.map((user: any) => {
          return {
            value: user?.id,
            label: user?.social_name
          }
        })
      )
    }
  }, [])

  const handleSelectAction = (event: any) => {
    setActions(event)
  }

  const handleSelectRecipesType = (event: any) => {
    setRecipesType(event)
  }

  const handlerSelectSpecialist = (event: any) => {
    setSpecialists(event)
  }

  // format users specialist for preferences body
  const returnUsersSpecialist = () => {
    const users = specialists.map((user) => {
      return {
        id: user.value,
        name: user.label
      }
    })

    return users
  }

  const handlerUpdateRecipe: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))
    const { is_active, description } = values

    const users = returnUsersSpecialist()

    const payload = {
      id: state.id,
      name,
      is_active,
      users,
      prescription_detail: valueDraft === undefined ? '<p><br><p>' : valueDraft,
      status: actions?.value !== undefined ? actions.value : '1',
      type: recipesType?.value,
      description: description === '' ? null : description
    }

    attendanceService.updateRecipe(payload)
  }

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {t('updateTitle')}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/settings/recipes">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handlerUpdateRecipe)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="50%" mr="2">
                <Input
                  label={t('create.container1.i1')}
                  name="name"
                  value={name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setName(event.target.value.toUpperCase())
                  }
                  placeholder={t('placeholder.name')}
                  maxLength={30}
                />
              </Box>
              <Stack direction="row" my="2" spacing="4" w="60%">
                <Box w="300px" mr="2">
                  <SelectComponent
                    options={optionsDefaultRecipes}
                    name="recipesType"
                    value={recipesType}
                    fontSize="12"
                    onChange={handleSelectRecipesType}
                    title={t('label.recipes')}
                    placeholder={t('placeholder.recipes')}
                  />
                </Box>
                <Box w="300px" mr="2">
                  <SelectComponent
                    options={optionsDefaultActions}
                    name="status"
                    value={actions}
                    onChange={handleSelectAction}
                    fontSize="12"
                    title={t('label.status')}
                    placeholder={t('placeholder.status')}
                  />
                </Box>
                <PermissionComponent
                  spiCode="PRESCRIPTIONS"
                  ipCode="INACTIVATE"
                >
                  <Box ml="12">
                    <Switch
                      defaultChecked={state?.is_active === true}
                      label={t('create.container1.i2')}
                      {...register('is_active')}
                    />
                  </Box>
                </PermissionComponent>
              </Stack>
            </Stack>
            <Flex direction="column">
              <Text mb="2" fontSize="sm">
                {t('create.container1.i5')}
              </Text>
              <Textarea
                defaultValue={state?.description}
                placeholder={t('create.container1.description')}
                {...register('description')}
                maxLength={200}
              />
            </Flex>
          </CardContainer>

          <CardContainer title={t('create.container2.title')}>
            <Stack direction="column" my="2" spacing="6" w="100%">
              <SelectComponent
                options={
                  attendanceService.specialists?.length &&
                  attendanceService.specialists.map((specialist) => {
                    return {
                      value: specialist?.user?.id,
                      label: specialist?.social_name || specialist?.user?.name
                    }
                  })
                }
                value={specialists}
                title={t('create.container2.i1')}
                isMulti
                onChange={handlerSelectSpecialist}
                name="specialists"
                placeholder={t('create.container2.specialist')}
              />
            </Stack>
          </CardContainer>

          <CardContainer title={t('create.container3.title')}>
            <Draft
              defaultValue={state?.prescription_detail}
              getValue={(value: any) => {
                setValueDraft(value)
              }}
            />
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
