/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  useBreakpointValue,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useService } from './service'
import { InputCurrency } from '../../components/Form/InputCurrency'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../components/Permissions'
import { SelectComponent } from '../../components/Form/SelectComponent'
import { apiAuth } from '../../services/apiAuth'

type FormData = {
  name: string
  is_active: boolean
  description: string
  minimumInventory: string
}

type Group = {
  id: number
  name: string
  stock_type: {
    id: number
    type: 'Insumo' | 'Consumo' | 'Revenda'
  }
}

type Unit = {
  id: number
  index: number
  symbol: string
  unit_en: string
  unit_es: string
  unit_ptbr: string
}

type ResponseGroups = {
  results: Group[]
}

type SelectType = {
  label: string
  value: number
}

export function CreateProductItem() {
  const [t] = useTranslation('pageProductsItems')
  const [language] = useState<string | null>(() => {
    const languageDefault = localStorage.getItem('i18nextLng')

    return languageDefault
  })
  const toast = useToast()
  const service = useService()
  const history = useHistory()
  const [localMethod, setLocal] = useState('')
  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const defaultTypes = [
    { value: 1, label: `${t('type.1')}` },
    { value: 2, label: `${t('type.2')}` },
    { value: 3, label: `${t('type.3')}` }
  ]
  const [isActive, setIsActive] = useState(() => {
    if (localMethod === 'create') {
      return true
    }

    return false
  })
  const [type, setType] = useState<SelectType | null>(defaultTypes[0])
  const [minimumUnit, setMinimumUnit] = useState<SelectType | null>(null)
  const [minimunQuantity, setMinimunQuantity] = useState('')
  const [group, setGroup] = useState<SelectType | null>(null)
  const [groups, setGroups] = useState<Group[]>([])
  const [units, setUnits] = useState<Unit[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  useEffect(() => {
    if (state?.is_active) {
      setIsActive(state?.is_active)
    } else if (localMethod === 'update') {
      setIsActive(false)
    }

    if (state?.stock_type !== undefined) {
      setType({
        label: state?.stock_type?.type,
        value: Number(state?.stock_type?.id)
      })
    }

    if (state?.stock_groups !== undefined) {
      setGroup({
        label: state?.stock_groups?.[0]?.name,
        value: state?.stock_groups?.[0]?.id
      })
    }

    if (state?.minimun_quantity !== undefined) {
      setMinimunQuantity(state?.minimun_quantity)
    }

    if (state?.stock_unit !== undefined) {
      if (language === 'en') {
        setMinimumUnit({
          label: state?.stock_unit?.unit_en,
          value: state?.stock_unit?.id
        })
      }

      if (language === 'es') {
        setMinimumUnit({
          label: state?.stock_unit?.unit_es,
          value: state?.stock_unit?.id
        })
      }

      if (language === 'pt') {
        setMinimumUnit({
          label: state?.stock_unit?.unit_ptbr,
          value: state?.stock_unit?.id
        })
      }
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => {}
  }, [])

  useEffect(() => {
    const getUnits = async () => {
      const { data } = await apiAuth.get<Unit[]>(`stock-units/`)

      setUnits(data)
    }

    const getGroups = async () => {
      const { data } = await apiAuth.get<ResponseGroups>(
        `/clinics/stock-groups/`
      )
      setGroups(data.results)
    }

    getUnits()
    getGroups()

    return () => {}
  }, [])

  useEffect(() => {
    if (!isLoading) {
      setGroup(null)
    }

    return () => {}
  }, [type])

  const handleCreateItem: SubmitHandler<FormData> = async (values, e) => {
    e?.preventDefault()
    setIsSubmitting(true)

    if (minimumUnit === null) {
      toast({
        duration: 3000,
        title: 'Selecione uma unidade mínima',
        status: 'info'
      })

      return
    }

    if (group === null) {
      toast({
        duration: 3000,
        title: 'Selecione um grupo',
        status: 'info'
      })

      return
    }

    const payload = {
      name: values?.name?.toUpperCase() || state?.name,
      is_active: isActive,
      description: values?.description !== '' ? values?.description : undefined,
      stock_type: {
        id: type?.value
      },
      stock_groups: [{ id: group?.value }] || undefined,
      stock_unit: {
        id: minimumUnit?.value
      },
      minimun_quantity: minimunQuantity !== '' ? minimunQuantity : undefined
    }

    if (localMethod === 'create') {
      await service.createRequest(payload)

      setIsSubmitting(false)
    } else {
      const { id } = state

      await service.updateRequest(payload, id)

      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/items-products">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateItem)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="50%">
                <Input
                  label={t('create.container1.i1')}
                  placeholder={t('create.container1.placeholder.name')}
                  isRequired
                  {...register('name')}
                  defaultValue={state?.name}
                  textTransform="uppercase"
                  maxLength={45}
                />
              </Box>
              <Box w="25%">
                <SelectComponent
                  title={t('create.container1.i2')}
                  isRequired
                  options={defaultTypes}
                  value={type}
                  onChange={(event: any) => setType(event)}
                />
              </Box>
              <Box w="25%">
                <SelectComponent
                  title={t('create.container1.i7')}
                  options={groups
                    .filter((op) => type?.value === op.stock_type.id)
                    .map((option) => {
                      return {
                        value: option.id,
                        label: option.name
                      }
                    })}
                  value={group}
                  isRequired
                  onChange={(event: any) => setGroup(event)}
                  placeholder={t('create.container1.placeholder.group')}
                />
              </Box>
              <Box w="25%">
                {localMethod === 'create' ? (
                  <Box w="30%">
                    <Switch
                      name="is_active"
                      isChecked={isActive}
                      label={t('create.container1.i4')}
                      onChange={(event) => setIsActive(event.target.checked)}
                    />
                  </Box>
                ) : (
                  <PermissionComponent spiCode="PRODUCTS" ipCode="INACTIVATE">
                    <Box w="30%">
                      <Switch
                        isChecked={isActive}
                        name="is_active"
                        label={t('create.container1.i4')}
                        onChange={(event) => setIsActive(event.target.checked)}
                      />
                    </Box>
                  </PermissionComponent>
                )}
              </Box>
            </Stack>
            <Stack direction="row" my="2" spacing="6">
              <SelectComponent
                title={t('create.container1.i5')}
                name="minimumUnit"
                isRequired
                options={units.map((unit) => {
                  if (language === 'en') {
                    return {
                      value: unit.id,
                      label: unit.unit_es
                    }
                  }

                  if (language === 'es') {
                    return {
                      value: unit.id,
                      label: unit.unit_es
                    }
                  }

                  return {
                    value: unit.id,
                    label: unit.unit_ptbr
                  }
                })}
                value={minimumUnit}
                onChange={(event: any) => setMinimumUnit(event)}
                placeholder={t('create.container1.placeholder.minimumUnit')}
              />
              <TextFieldControlled
                name="minimun_quantity"
                isRequired
                initialValue={minimunQuantity}
                inputOnChange={(e) => setMinimunQuantity(e.currentTarget.value)}
                mask="number"
                maxLength={30}
                textAlign="left"
                label={t('create.container1.i6')}
                placeholder={t(
                  'create.container1.placeholder.minimumInventory'
                )}
              />
            </Stack>
            <Flex w="100%" my="2" gap="2" direction="column">
              <Text fontSize="14px">{t('create.container1.i3')}</Text>
              <Textarea
                {...register('description')}
                defaultValue={state?.description}
                placeholder={t('create.container1.placeholder.description')}
              />
            </Flex>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={isSubmitting || formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
