import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;

  ul {
    list-style: none;
  }
`
