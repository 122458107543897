/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  useBreakpointValue,
  Textarea
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useService } from './service'
import { InputCurrency } from '../../components/Form/InputCurrency'
import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../components/Permissions'
import { SelectComponent } from '../../components/Form/SelectComponent'

type FormData = {
  is_active: boolean
  all_outs: boolean
  description: string
}

type SelectType = {
  label: string
  value: number
}

export function CreateProduct() {
  const [t] = useTranslation('pageProducts')
  const service = useService()
  const history = useHistory()
  const [localMethod, setLocal] = useState('')
  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const defaultTypes = [
    { value: 1, label: `${t('type.1')}` },
    { value: 2, label: `${t('type.2')}` },
    { value: 3, label: `${t('type.3')}` }
  ]
  const [name, setName] = useState('')
  const [type, setType] = useState<SelectType | null>(defaultTypes[0])

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }
  }, [])

  const handleCreateProduct: SubmitHandler<FormData> = async (values) => {
    await new Promise((resolve) => setTimeout(resolve, 2000))

    const payload = {
      name,
      stock_type: {
        id: type?.value
      },
      description: values?.description || undefined
    }

    if (localMethod === 'create') {
      service.createRequest(payload)
    } else {
      const { id } = state

      service.updateRequest(payload, id)
    }
  }

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/group-products">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />

      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateProduct)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="25%">
                <Input
                  label={t('create.container1.i1')}
                  placeholder={t('create.container1.placeholder.name')}
                  isRequired
                  value={name}
                  name="name"
                  maxLength={40}
                  onChange={(event) =>
                    setName(event?.target.value.toUpperCase())
                  }
                />
              </Box>
              <Box w="25%">
                <SelectComponent
                  title={t('create.container1.i2')}
                  options={defaultTypes}
                  value={type}
                  onChange={(event: any) => setType(event)}
                />
              </Box>
              <Box w="25%">
                {localMethod === 'create' ? (
                  <Box w="30%">
                    <Switch
                      defaultChecked={
                        state?.is_active !== undefined ? state?.is_active : true
                      }
                      label={t('create.container1.i4')}
                      {...register('is_active')}
                    />
                  </Box>
                ) : (
                  <PermissionComponent spiCode="PRODUCTS" ipCode="INACTIVATE">
                    <Box w="30%">
                      <Switch
                        defaultChecked={
                          state?.is_active !== undefined
                            ? state?.is_active
                            : true
                        }
                        label={t('create.container1.i4')}
                        {...register('is_active')}
                      />
                    </Box>
                  </PermissionComponent>
                )}
              </Box>
            </Stack>
            <Flex w="100%" my="2" gap="2" direction="column">
              <Text fontSize="14px">{t('create.container1.i3')}</Text>
              <Textarea
                {...register('description')}
                placeholder={t('create.container1.placeholder.description')}
                defaultValue={state?.description}
              />
            </Flex>
          </CardContainer>

          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
          <Flex w="100%" h="300px" />
        </Stack>
      </Flex>
    </Box>
  )
}
