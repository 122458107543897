import {
  FormControl,
  FormLabel,
  Switch as SwitchChakra,
  SwitchProps as ChakraSwitchProps
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface SwitchProps extends ChakraSwitchProps {
  name: string
  label?: string
  isRequired?: boolean
}

const SwitchBase: ForwardRefRenderFunction<HTMLInputElement, SwitchProps> = (
  { name, label, isRequired = false, ...rest },
  ref
) => {
  return (
    <FormControl isRequired={isRequired}>
      {!!label && (
        <FormLabel fontSize="14px" htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <SwitchChakra
        name={name}
        ref={ref}
        id={name}
        size="lg"
        colorScheme="green"
        {...rest}
      />
    </FormControl>
  )
}

export const Switch = forwardRef(SwitchBase)
