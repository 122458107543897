import { useState, ReactNode, ElementType, useEffect } from 'react'
import { Flex, Box, Text, Stack, Collapse, Icon } from '@chakra-ui/react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

interface NavSectionProps {
  icon?: ElementType
  title: string
  isMouseHover?: boolean
  children: ReactNode
}

export function NavSection({
  title,
  children,
  icon,
  isMouseHover = false
}: NavSectionProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box w="100%">
      <Flex
        as="button"
        display="flex"
        justify="space-between"
        align="center"
        width="100%"
        paddingRight="4"
        marginTop="2px"
        cursor="pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Flex direction="row">
          {!!icon && <Icon as={icon} fontSize="18px" mt="2px" mr="4" />}
          <Text fontSize="14px" color="gray.500">
            {title}
          </Text>
        </Flex>

        {isMouseHover && (
          <>
            {isOpen ? (
              <button type="button">
                <FaChevronUp size={12} color="#616480" />
              </button>
            ) : (
              <button type="button">
                <FaChevronDown size={12} color="#616480" />
              </button>
            )}
          </>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="4" mt="8" align="stretch">
          {isMouseHover && children}
        </Stack>
      </Collapse>
    </Box>
  )
}
