import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Stack,
  Switch,
  Text,
  Tooltip,
  useBreakpointValue,
  useMediaQuery
} from '@chakra-ui/react'
import React, { FormEventHandler, ReactNode, useCallback } from 'react'
import { FaFilter } from 'react-icons/fa'
import { FcClearFilters } from 'react-icons/fc'
import { FiRefreshCw } from 'react-icons/fi'
import { RiSearchLine } from 'react-icons/ri'
import { SiMicrosoftexcel } from 'react-icons/si'
import { UseQueryResult } from 'react-query'
import { Input } from '../components/Form/Input'
import { Header } from '../components/Header'
import { SpiOptions } from '../components/Permissions'
import { Sidebar } from '../components/Sidebar'
import { ModalActivesFiltersNames } from '../contexts/ModalContext'
import { useModal } from '../hooks/useModal'

interface BlankProps {
  title: string
  children: ReactNode
  spiCode?: SpiOptions
  onSubmit?: FormEventHandler<HTMLDivElement> | undefined
  capturaChange?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  captureActiveChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  generateExcel?: () => void
  isHaveData?: boolean
  refetch?: (options?: {
    throwOnError: boolean
    cancelRefetch: boolean
  }) => Promise<UseQueryResult>
  isHaveFilter?: boolean
  defaultSearch?: string
  placeholder?: string
  nameFilter?: ModalActivesFiltersNames
  noRenderIsActive?: boolean
  isFetching?: boolean
  isActions?: boolean
  menuItems?: ReactNode
  isSearched?: boolean
}

export function LayoutBlank({
  title,
  children,
  onSubmit,
  capturaChange,
  captureActiveChange,
  placeholder,
  isHaveFilter,
  nameFilter,
  noRenderIsActive,
  defaultSearch,
  isActions,
  menuItems,
  isFetching,
  refetch,
  spiCode,
  isSearched = true,
  generateExcel,
  isHaveData
}: BlankProps) {
  const { verifyModalFilterActive, handlerOpenModal } = useModal()
  const [mediaQuery1024] = useMediaQuery('(min-width: 1200px)')
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const styleActiveFilter = {
    background: 'transparent'
  }

  const handleInputChange = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      capturaChange!(e)
    },
    [capturaChange]
  )

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          {isWideVersion ? (
            <Stack as="form" direction="row">
              <Box w="400px">
                {isSearched && (
                  <Input
                    placeholder={placeholder}
                    borderRadius={8}
                    name="searchList"
                    size="md"
                    defaultValue={defaultSearch}
                    iconRight={RiSearchLine}
                    onKeyPress={(event) => {
                      if (event.code === 'Enter') {
                        event.preventDefault()
                        event.stopPropagation()
                      }
                    }}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      handleInputChange(event)
                    }}
                  />
                )}
              </Box>
              {noRenderIsActive ? null : (
                <Flex align="center" justify="center">
                  <Tooltip
                    label="Ativo"
                    aria-label="Ativo"
                    hasArrow
                    placement="top-start"
                  >
                    <Box mx="2">
                      <Switch
                        defaultChecked
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          captureActiveChange!(event)
                        }}
                        name="active"
                        size="sm"
                      />
                    </Box>
                  </Tooltip>
                </Flex>
              )}
              {isHaveFilter && (
                <>
                  <Button
                    mr="2"
                    top="1"
                    size="sm"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="0"
                    bg="blue.300"
                    color="#fff"
                    onClick={() => {
                      handlerOpenModal(nameFilter)
                    }}
                    style={
                      verifyModalFilterActive(nameFilter)
                        ? styleActiveFilter
                        : undefined
                    }
                  >
                    {verifyModalFilterActive(nameFilter) ? (
                      <Icon as={FcClearFilters} fontSize="16" color="white" />
                    ) : (
                      <Icon as={FaFilter} fontSize="16" color="white" />
                    )}
                  </Button>
                </>
              )}
            </Stack>
          ) : null}
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {title}
            </Text>
          </Box>
          {isHaveData && (
            <Button
              size={!mediaQuery1024 ? 'xs' : 'sm'}
              mr="2"
              alignItems="center"
              justifyContent="center"
              fontSize="0"
              bg="blue.300"
              color="#fff"
              onClick={() => generateExcel?.()}
            >
              <Icon as={SiMicrosoftexcel} fontSize="18" color="white" />
            </Button>
          )}
          {isActions && (
            <Stack direction="row">
              {isFetching && (
                <Box alignItems="center" justifyContent="center">
                  <Spinner mx="4" color="gray.200" />
                </Box>
              )}
              <Button
                mr="2"
                size="sm"
                alignItems="center"
                justifyContent="center"
                fontSize="0"
                bg="blue.300"
                color="#fff"
                onClick={() => refetch?.()}
              >
                <Icon as={FiRefreshCw} fontSize="18" color="white" />
              </Button>
              <Menu>
                <MenuButton
                  bg="blue.300"
                  size="sm"
                  color="white"
                  colorScheme="facebook"
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                >
                  Ações
                </MenuButton>
                <MenuList>{menuItems}</MenuList>
              </Menu>
            </Stack>
          )}
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        direction="column"
        pr="6"
        pb="6"
        flex="1"
        mx="auto"
        ml={isWideVersion ? '75px' : '2'}
        mt="10px"
        mb="8px"
        mr="4"
        borderRadius={8}
        bg="white"
        p="2"
        px="4"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.16)"
        h="cal(100vh - 60px)"
      >
        {children}
      </Flex>
    </Box>
  )
}
