/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'
import {
  getProductsItems,
  ProductsItemsData,
  useProductsItems
} from '../../hooks/ItemsProducts/useItemsProducts'
import { useModal } from '../../hooks/useModal'
import { LayoutBlank } from '../../layouts/blank'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
// debounce
let timer: any = null

export function ProductsResale() {
  const { setSearchStateProducts, searchStateProducts } = useModal()
  const [t] = useTranslation('pageProductsItemsResale')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  // state
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const getProductsData = useProductsItems(
    currentPage,
    getProductsItems(currentPage, searchStateProducts, isActive),
    searchStateProducts,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setCurrentPage(1)
        setSearchStateProducts(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  const returnColorBgLineInTable = (minimum: number, balance: number) => {
    if (minimum > balance) {
      return 'red.500'
    }

    return 'green.500'
  }

  return (
    <>
      <LayoutBlank
        title={t('title')}
        refetch={getProductsData.refetch}
        capturaChange={(event: any) => SearchInputCallbackValue(event)}
        captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
        placeholder={t('search')}
        spiCode="PRODUCTS"
        defaultSearch={searchStateProducts}
      >
        <Box>
          <Table variant="striped" colorScheme="blue" size="sm">
            <Thead>
              <Tr>
                <Th>{t('t1')}</Th>
                <Th>{t('t3')}</Th>
                <Th>{t('t4')}</Th>
                <Th textAlign="center">{t('t5')}</Th>
                <Th textAlign="right">{t('t6')}</Th>
                <Th textAlign="right">{t('t7')}</Th>
                <Th textAlign="right">{t('t8')}</Th>
                <Th>{t('t9')}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {getProductsData.isLoading ? (
                <>
                  {skeletonRepeat.map((skeleton) => (
                    <Tr>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" />
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <>
                  {getProductsData.data?.products.map(
                    (product: ProductsItemsData, index: number) => (
                      <Tr key={product.id} maxHeight="40px">
                        <Td w="250px">
                          <Tooltip
                            label={product.name}
                            aria-label={product.name}
                          >
                            <Text
                              _hover={{
                                cursor: 'default'
                              }}
                              fontSize="sm"
                              noOfLines={1}
                            >
                              {product.name}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td>
                          <Text fontSize="sm">
                            {product?.stock_groups?.[0]?.name || ''}
                          </Text>
                        </Td>
                        <Td w="80px">
                          <Tooltip
                            label={product?.description}
                            aria-label={product?.description}
                          >
                            <Text
                              fontSize="sm"
                              noOfLines={1}
                              _hover={{ cursor: 'default' }}
                            >
                              {product?.description}
                            </Text>
                          </Tooltip>
                        </Td>
                        <Td textAlign="center">
                          <Heading fontSize="sm" noOfLines={1}>
                            {product?.stock_unit?.symbol}
                          </Heading>
                        </Td>
                        <Td>
                          <Text fontSize="sm" textAlign="right">
                            {product?.minimun_quantity || '0'}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            textAlign="right"
                            fontSize="sm"
                            color={returnColorBgLineInTable(
                              product.minimun_quantity,
                              product.balance_units
                            )}
                          >
                            {product?.balance_units || '0'}
                          </Text>
                        </Td>
                        <Td>
                          <Text fontSize="sm" textAlign="right">
                            {formatBrOrDollarDefault(
                              product?.balance_value.toFixed(2)
                            ) || '---'}
                          </Text>
                        </Td>
                        <Td>
                          <PermissionComponent spiCode="PRODUCTS" ipCode="EDIT">
                            <Flex />
                          </PermissionComponent>
                        </Td>
                      </Tr>
                    )
                  )}
                </>
              )}
            </Tbody>
          </Table>
          {getProductsData.data?.totalCountOfPage === 0 &&
            !getProductsData.isLoading && <NotFound />}
        </Box>
        {!(
          getProductsData.data?.totalCountOfPage === 0 &&
          !getProductsData.isLoading
        ) && (
          <Pagination
            totalCountOfRegisters={getProductsData.data?.totalCountOfPage || 0}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        )}
      </LayoutBlank>
    </>
  )
}
