/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { apiAuth } from '../../../services/apiAuth'

export type SuppliersData = {
  id?: number
  name: string
  is_active?: boolean
}

const BASE_URL = 'clinics/suppliers/'

export const useService = () => {
  // terceiros
  const router = useHistory()
  const toast = useToast()

  // função para criar
  async function createRequest(body: any) {
    try {
      const response = await apiAuth.post(BASE_URL, body)

      console.log(response)

      if (response.data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/financial/suppliers')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // função para editar
  async function updateRequest(body: any, id: number) {
    try {
      const { data: res } = await apiAuth.put(`${BASE_URL}${id}/`, body)

      if (res.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/financial/suppliers')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Aconteceu um Erro!',
        description: `${error.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // export
  return {
    createRequest,
    updateRequest
  }
}
