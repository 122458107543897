/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../../services/apiAuth'
import { useAuth } from '../../../hooks/auth'

export type HealthInsuranceData = {
  id?: number
  name: string
  is_active?: boolean
}

export type Props = {
  init: boolean
}

const BASE_URL = 'clinics/health-insurances/'

export const useHealthInsurance = () => {
  const [loadingRest, setLoadingRest] = useState(false)
  const router = useHistory()
  const toast = useToast()

  // função para criar um conveio
  async function createHealthInsurance({
    name,
    is_active
  }: HealthInsuranceData) {
    try {
      setLoadingRest(true)
      await apiAuth.post(BASE_URL, { name, is_active })

      toast({
        title: 'Cadastro realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/settings/covenants')
      }, 2000)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // função para editar um conveio
  async function updateHealthInsurance({
    id,
    name,
    is_active
  }: HealthInsuranceData) {
    try {
      setLoadingRest(true)
      await apiAuth.put(`${BASE_URL}${id}/`, { name, is_active })

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/settings/covenants')
      }, 2000)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // export
  return {
    createHealthInsurance,
    updateHealthInsurance,
    loadingRest
  }
}
