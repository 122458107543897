import { Flex, Text, Image } from '@chakra-ui/react'
import { FC } from 'react'

import NotFoundImg from '../../assets/img/not-found.svg'

type NotFoundProps = {
  renderMessage?: boolean
}

export const NotFound: FC<NotFoundProps> = ({ renderMessage }) => {
  return (
    <Flex w="100%" align="center" h="100%">
      <Flex p="6" direction="column" align="center" justify="center" w="100%">
        <Image src={NotFoundImg} w="40" h="40" />
        {renderMessage && <Text mt="4">Nenhum resultado encontrado</Text>}
      </Flex>
    </Flex>
  )
}
