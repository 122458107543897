/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../../services/apiAuth'
import { useAuth } from '../../../hooks/auth'

export type HelthInssuranceData = {
  id?: number
  name: string
  is_active?: boolean
}

export type Props = {
  init: boolean
}

const BASE_URL = 'clinics/origins/'

export const useAttendance = () => {
  // hooks
  const { clinicId } = useAuth()

  // estados
  const [attendance, setAttendance] = useState<HelthInssuranceData[]>([])
  const [loading, setLoading] = useState(true)
  const [loadingRest, setLoadingRest] = useState(false)
  // estados paginacao
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [offset, setOffset] = useState(0)
  const [filter, setFilter] = useState(false)

  // terceiros
  const router = useHistory()
  const toast = useToast()

  // funcao para criar um conveio
  async function createAttendance({ name, is_active }: HelthInssuranceData) {
    try {
      setLoadingRest(true)
      await apiAuth.post(BASE_URL, { name, is_active })

      toast({
        title: 'Cadastro realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/settings/origins')
      }, 2000)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // funcao para editar um conveio
  async function updateAttendance({
    id,
    name,
    is_active
  }: HelthInssuranceData) {
    try {
      setLoadingRest(true)
      await apiAuth.put(`${BASE_URL}${id}/`, { name, is_active })

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/settings/origins')
      }, 2000)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRest(false)
    }
  }

  // paginacao
  // paginacao
  async function capturePagination(limitArg?: any, offsetArg?: any) {
    try {
      const response = await apiAuth.get(
        `${BASE_URL}?limit=${limitArg}&offset=${offsetArg || offset}`
      )
      setAttendance(response.data.results)

      console.log(response)
    } catch (error) {
      console.log('Erro ao buscar os tags (hook)')
    } finally {
      setLoading(false)
    }
  }

  async function onPrev() {
    const offsetAux = offset - 1
    setOffset(offsetAux)
    setPage(page - 1)
    setLoading(true)

    capturePagination(limit, offsetAux)
  }

  async function onNext() {
    const offsetAux = offset + 1
    setOffset(offsetAux)
    setPage(page + 1)
    setLoading(true)

    capturePagination(limit, offsetAux)
  }

  // buscar todos os convenios
  useEffect(() => {
    ;(async () => {
      try {
        const response = await apiAuth.get(`clinics/${clinicId}/origins/`)
        setAttendance(response.data.results)
      } catch (error) {
        console.log('Erro ao buscar os tags (hook)')
      } finally {
        setLoading(false)
      }
    })()
  }, [clinicId])

  // export
  return {
    attendance,
    createAttendance,
    updateAttendance,
    loading,
    loadingRest,
    capturePagination,
    onNext,
    onPrev,
    page
  }
}
