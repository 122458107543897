import styled, { css } from 'styled-components'
import { FormControl } from '@chakra-ui/react'

interface ContainerProps {
  isFocused?: boolean
  width?: number
  height?: number
  medium: boolean
}

export const Container = styled.div<ContainerProps>`
  z-index: 99px;

  ${({ medium, isFocused }) => css`
    div.react-select__control {
      width: 100% !important;
      border-width: 1px;
      border-radius: none !important;
    }

    .react-select__control--is-focused {
      border-width: 1px !important;
    }

    ${isFocused &&
    css`
      border-color: #03719c;
    `}
  `}
`

export const customStyles = {
  control: () => ({
    width: '100%',
    height: '100vh',
    borderRadius: 'none',
    borderWidth: 1,
    display: 'flex',
    borderColor: '#D1D2DC'
  })
}
