/* eslint-disable react-hooks/exhaustive-deps */
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'
import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

interface SidebarDrawerProviderProps {
  children: ReactNode
}

type SidebarDrawerData = {
  disclosure: UseDisclosureReturn
  isOpen: boolean
  handlerOpenSideBarDrawer: () => void
  handlerCloseSideBarDrawer: () => void
}

const SidebarDrawerContext = createContext({} as SidebarDrawerData)

export function SideBarDrawerProvider({
  children
}: SidebarDrawerProviderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const disclosure = useDisclosure()
  const router = useHistory()

  const handlerOpenSideBarDrawer = () => {
    setIsOpen(true)
  }

  const handlerCloseSideBarDrawer = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    handlerCloseSideBarDrawer()
  }, [router.location])

  return (
    <SidebarDrawerContext.Provider
      value={{
        disclosure,
        isOpen,
        handlerOpenSideBarDrawer,
        handlerCloseSideBarDrawer
      }}
    >
      {children}
    </SidebarDrawerContext.Provider>
  )
}

export const useSidebarDrawer = () => useContext(SidebarDrawerContext)
