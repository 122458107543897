import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select as ChakraSelect,
  SelectProps as ChakraSelectProps
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

interface SelectProps extends ChakraSelectProps {
  name: string
  label?: string
  error?: FieldError
  isRequired?: boolean
  children?: ReactNode
  options: OptionsData[]
}

interface OptionsData {
  value: string | number
  label: string
}

const SelectBase: ForwardRefRenderFunction<HTMLSelectElement, SelectProps> = (
  {
    name,
    label,
    error = null,
    isRequired = false,
    size = 'md',
    options,
    children,
    ...rest
  },
  ref
) => {
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      {!!label && (
        <FormLabel fontSize="14px" htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <ChakraSelect
        name={name}
        id={name}
        focusBorderColor="blue.400"
        borderRadius="4px"
        isRequired={isRequired}
        bgColor="white"
        borderColor="gray.100"
        size={size}
        variant="filled"
        _hover={{
          bgColor: 'transparent'
        }}
        ref={ref}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
        {children}
      </ChakraSelect>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase)
