import { Flex, Text } from '@chakra-ui/react'
import {
  BlockMapBuilder,
  convertFromHTML,
  DraftBlockRenderMap,
  EditorState,
  Modifier
} from 'draft-js'
import { FC } from 'react'

type LineHorizontalProps = {
  onChange?: (editorState: EditorState) => void
  editorState: EditorState
  map?: DraftBlockRenderMap
}

export const LineHorizontal: FC<LineHorizontalProps> = ({
  onChange,
  editorState,
  map
}) => {
  const data = '<p>*</p><hr/><p>*</p>'

  const handleHzInEditor = () => {
    const htmlContent = convertFromHTML(data, undefined, map)

    const currentContent = editorState.getCurrentContent()
    const currentSelection = editorState.getSelection()
    const htmlContentMap = BlockMapBuilder.createFromArray(
      htmlContent.contentBlocks
    )
    const newContent = Modifier.replaceWithFragment(
      currentContent,
      currentSelection,
      htmlContentMap
    )
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      'insert-fragment'
    )

    onChange!(
      EditorState.forceSelection(
        newEditorState,
        newContent.getSelectionBefore()
      )
    )
  }

  return (
    <Flex
      borderWidth={1}
      borderColor="gray.50"
      rounded="sm"
      mt="2px"
      w="10"
      h="6"
      onClick={handleHzInEditor}
      justifyContent="center"
      alignItems="center"
      _hover={{
        cursor: 'pointer',
        boxShadow: '0 3px 6px rgb(0 0 0 / 0.1)'
      }}
    >
      <Text fontSize="sm" fontWeight="extrabold">
        line
      </Text>
    </Flex>
  )
}
