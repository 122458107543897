import React from 'react'
import { InputBuild } from './Fields/Input'
import { TextareaBuild } from './Fields/Textarea'
import { SelectBuild } from './Fields/Select'
import { RadioBuild } from './Fields/Radio'

interface FieldProps {
  data: {
    id: string
    name: string
    isRequired: boolean
    placeholder: string
    type: string
    value: string
    defaultValue: string
    label: string
  }
  indexList: number
  index: number
  onExclude?: () => void
}

export const Field: React.FC<FieldProps> = ({
  data,
  index,
  indexList,
  onExclude
}) => {
  switch (data.type) {
    case 'text':
      return (
        <InputBuild
          index={index}
          indexList={indexList}
          data={data}
          onExclude={onExclude}
        />
      )

    case 'radio':
      return (
        <RadioBuild
          index={index}
          indexList={indexList}
          data={data}
          onExclude={onExclude}
        />
      )

    case 'textarea':
      return (
        <TextareaBuild
          index={index}
          indexList={indexList}
          data={data}
          onExclude={onExclude}
        />
      )

    case 'select':
      return (
        <SelectBuild
          index={index}
          indexList={indexList}
          data={data}
          onExclude={onExclude}
        />
      )

    default:
      return (
        <InputBuild
          index={index}
          indexList={indexList}
          data={data}
          onExclude={onExclude}
        />
      )
  }
}
