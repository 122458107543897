import format from 'date-fns/format'
import * as XLSX from 'xlsx'

export const handlerExportXlsx = (
  data: Array<object> | undefined,
  tableName: string,
  dateEvent?: string
) => {
  const event = format(new Date(), 'yyyy-MM-dd')

  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data || [])

  XLSX.utils.book_append_sheet(wb, ws, tableName)

  if (dateEvent) {
    XLSX.writeFile(wb, `${tableName}_${dateEvent}.xlsx`)
  } else {
    XLSX.writeFile(wb, `${tableName}_${event}.xlsx`)
  }
}
