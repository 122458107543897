/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { List, Flex } from '@chakra-ui/react'
import { FileListItem } from '../FileListItem'
import { UploadFilesData } from '../../contexts/DocumentContext'

type FileListProps = {
  files: Array<UploadFilesData>
}

export const FileList: React.FC<FileListProps> = ({ files }) => {
  return (
    <Flex mt="12px">
      <List w="100%">
        {files.map((file) => (
          <FileListItem file={file} />
        ))}
      </List>
    </Flex>
  )
}
