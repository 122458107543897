export function remaskCaractersAll(value: string): string {
  const removePonto = value?.replaceAll('.', '')
  const removeVir = removePonto?.replaceAll(',', '')
  const removeBarra = removeVir?.replaceAll('/', '')
  const removeHi = removeBarra?.replaceAll('-', '')
  const removeTwoPoint = removeHi?.replaceAll(':', '')
  const v1 = removeTwoPoint?.replaceAll('(', '')
  const v2 = v1?.replaceAll(')', '')
  const v3 = v2?.replaceAll('%', '')
  const v4 = v3?.replaceAll('R', '')
  const v5 = v4?.replaceAll('$', '')
  const valueFormat = v5?.replaceAll(' ', '')

  return valueFormat
}

export function formatCurrency(value: any, format: any) {
  let currencyAuxFormat = ''

  if (format === 'pt-BR') {
    currencyAuxFormat = 'BRL'
  } else {
    currencyAuxFormat = 'USD'
  }

  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currencyAuxFormat
  }).format(value)
}

export function formatCurrencyUSDDefault(
  value: number | string,
  format: string = 'pt-BR'
) {
  let currencyAux = ''

  if (format === 'pt-BR') {
    currencyAux = 'BRL'
  } else {
    currencyAux = 'USD'
  }

  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currencyAux
  }).format(Number(value))
}

export function formatToFixedTwo(value: string) {
  const realValue = Number(remaskCaractersAll(value)) / 100
  const valueAux = parseFloat(realValue.toFixed(2))
  return valueAux
}

export function formatNumberToFixedTwo(value: number) {
  const valueAux = value.toFixed(2)
  return valueAux
}
