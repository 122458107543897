/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-children-prop */
import {
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useState,
  ElementType,
  useEffect
} from 'react'
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon,
  Stack,
  Box,
  Text,
  Flex
} from '@chakra-ui/react'
import { FieldError } from 'react-hook-form'

import { mask } from '../../utils/mask'
import { useAuth } from '../../hooks/auth'

interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  error?: FieldError
  type?: string
  isRequired?: boolean
  iconRight?: ElementType
  _active?: any
  disabled?: boolean
  size?: string
  defaultValue?: any
}

const maskCurrencyBR = [
  '9,99',
  '99,99',
  '999,99',
  '9.999,99',
  '99.999,99',
  '999.999,99',
  '9.999.999,99'
]

const maskDolar = [
  '9.99',
  '99.99',
  '999.99',
  '9,999.99',
  '99,999.99',
  '999,999.99',
  '9,999,999.99'
]

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = null,
    type = 'text',
    isRequired = false,
    iconRight,
    disabled,
    size = 'md',
    defaultValue,
    ...rest
  },
  ref
) => {
  const [typeInput, setTypeInput] = useState(type)
  const [value, setValue] = useState('')
  const [masker, setMasker] = useState<string | string[]>('')
  const { decodeToken } = useAuth()

  // aplicar mascaras
  const changeValueApplyMask = useCallback(
    (e: any) => {
      const valueModifers = e.target.value
      setValue(mask(valueModifers, masker))
    },
    [masker]
  )

  // mascara phone br
  function maskerCurrency() {
    if (decodeToken.clinic_country === 'United States of America') {
      setMasker(maskDolar)
    } else {
      setMasker(maskCurrencyBR)
    }
  }

  useEffect(() => {
    if (decodeToken !== undefined) {
      maskerCurrency()
    }
  }, [decodeToken])

  useEffect(() => {
    if (!!defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <Stack direction="row" w="100%" spacing="4" placeholder="">
      <Box w="75%" flex="1">
        <FormControl
          isInvalid={!!error}
          isRequired={isRequired}
          bgColor="white"
        >
          {!!label && (
            <FormLabel fontSize="14px" htmlFor={name}>
              {label}
            </FormLabel>
          )}
          <InputGroup
            display="flex"
            onChange={(e: any) => changeValueApplyMask(e)}
          >
            <Flex w="100%" align="center">
              <ChakraInput
                w="100%"
                borderRadius="none"
                name={name}
                id={name}
                focusBorderColor="blue.400"
                bgColor="white"
                borderColor="gray.100"
                borderWidth="1px"
                variant="filled"
                textAlign="right"
                disabled={disabled}
                type={typeInput}
                _hover={{
                  bgColor: 'blue.50'
                }}
                size={size}
                ref={ref}
                value={value}
                onChange={(e: any) => changeValueApplyMask(e.target.value)}
                {...rest}
              />
              {!!iconRight && (
                <InputRightElement
                  mt={size === 'md' ? '0px' : '4px'}
                  children={
                    <Icon
                      as={iconRight}
                      fontSize={size === 'md' ? '20px' : '24px'}
                      color="#9699B0"
                    />
                  }
                />
              )}
            </Flex>
          </InputGroup>
          {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
      </Box>
    </Stack>
  )
}

export const InputCurrency = forwardRef(InputBase)
