import { Flex, Spinner, Text } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface CardContainerProps {
  title: string
  children: ReactNode
  isLoading?: boolean
  isRow?: boolean
}

export const CardContainer = ({
  title,
  children,
  isLoading = false,
  isRow = false
}: CardContainerProps) => {
  return (
    <Flex
      px="6"
      borderRadius={8}
      bg="white"
      position="relative"
      py="4"
      boxShadow="0px 8px 20px rgba(0, 0, 0, 0.24)"
      maxWidth="100%"
      direction="column"
      flex="1"
    >
      <Text mb="2" fontSize="20px">
        {title} {isLoading && <Spinner mx="2" color="gray.100" />}
      </Text>
      <Flex flex="1" direction={isRow ? 'row' : 'column'}>
        {children}
      </Flex>
    </Flex>
  )
}
