import { useEffect, useState } from 'react'
import { apiAuth } from '../../../services/apiAuth'
import { PermissionsData } from './service'

interface Images {
  file: {
    path: string
  }
  id: number
  name: string
  readbleSize: string
  preview: string
  progress: number
  uploaded: boolean
  error: boolean
  url: string | null
}

interface AccessSpecialist {
  id: number
  user: {
    id: number
    name: string
  }
  schedule: {
    id: number
    name: string
  }
  financial: {
    id: number
    name: string
  }
  confirmation: boolean
}

type Access = AccessSpecialist[]

export const useLogicUser = () => {
  // state
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [contact, setContact] = useState('')
  const [document, setDocument] = useState('')
  const [occupation, setOccupation] = useState('')
  const [socialName, setSocialName] = useState('')
  const [superuser, SetSuperuser] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isSpecialist, setIsSpecialist] = useState(false)
  const [image, setImage] = useState<Images[]>([])
  // Array default back permissions
  const [userPermissions, setUserPermissions] = useState<PermissionsData>([])
  const [accessSpecialist, setAccessSpecialist] = useState<Access>([
    {
      id: 1,
      user: {
        id: 0,
        name: ''
      },
      schedule: {
        id: 0,
        name: ''
      },
      financial: {
        id: 0,
        name: ''
      },
      confirmation: false
    }
  ])

  async function getPermissionsState() {
    try {
      const { data } = await apiAuth.get(`permissions/`, {
        params: {
          is_active: true
        }
      })

      setUserPermissions(data.results)
    } catch (err: any) {
      console.log(err)
    }
  }

  useEffect(() => {
    getPermissionsState()
  }, [])

  function onRemoveFieldAccessSpecialistDateIndex(index: number) {
    const values = [...accessSpecialist]
    values.splice(index, 1)

    // reordering the number of launch
    const valuesNewArray = values.map((value) => {
      return {
        id: value.id + 1,
        user: {
          id: value.user.id,
          name: value.user.name
        },
        schedule: {
          id: value.schedule.id,
          name: value.schedule.name
        },
        financial: {
          id: value.financial.id,
          name: value.financial.name
        },
        confirmation: value.confirmation
      }
    })

    setAccessSpecialist(valuesNewArray)
  }

  function handleAddFieldAccessSpecialistDateIndex() {
    const values = [...accessSpecialist]
    const lengthAux = values.length - 1

    // Get
    setAccessSpecialist([
      ...accessSpecialist,
      {
        id: values[lengthAux].id + 1,
        user: {
          id: 0,
          name: ''
        },
        schedule: {
          id: values[lengthAux].schedule.id,
          name: values[lengthAux].schedule.name
        },
        financial: {
          id: values[lengthAux].financial.id,
          name: values[lengthAux].financial.name
        },
        confirmation: values[lengthAux].confirmation
      }
    ])
  }

  const handleSelectUserPermissions = (event: any, index: number) => {
    const userPermissionSelect = userPermissions[index]
    console.log(userPermissionSelect, event)
  }

  // format date for body
  const convertDate = (data: string) => {
    const dia = data.substring(0, 2)
    const mes = data.substring(3, 5)
    const ano = data.substring(6, 12)
    return `${ano}-${mes}-${dia}`
  }

  return {
    name,
    setName,
    image,
    setEmail,
    email,
    setImage,
    contact,
    SetSuperuser,
    superuser,
    setOccupation,
    occupation,
    setContact,
    isActive,
    setIsActive,
    setIsSpecialist,
    isSpecialist,
    setUserPermissions,
    userPermissions,
    setDocument,
    document,
    accessSpecialist,
    setSocialName,
    socialName,
    convertDate,
    onRemoveFieldAccessSpecialistDateIndex,
    handleAddFieldAccessSpecialistDateIndex,
    handleSelectUserPermissions
  }
}
