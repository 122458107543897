import React, { createContext, ReactNode, useReducer, useState } from 'react'
import { DateTime } from 'luxon'
import {
  CALENDAR_NAVIGATION_DIRECTION,
  CALENDAR_VIEW
} from '../../common/enums'
import {
  Callbacks,
  Config,
  DraggingDisabledConditions,
  EventLayout,
  ShowMoreEvents,
  Style
} from '../../common/interfaces'
import { createConfig, createCallbacks } from '../../layers/ConfigLayers'
import Reducer from './reducer'

interface ReactSchedulingProps {
  children: ReactNode
}

type Store = {
  isLoading: boolean
  headerEventRowsCount: number
  initialView: CALENDAR_VIEW | null
  selectedView: CALENDAR_VIEW | null
  selectedDate: DateTime
  calendarDays: DateTime[]
  width: number
  rawWidth: number
  height: number
  isMobile: boolean
  events: any
  // layouts
  daysViewLayout: EventLayout | null
  headerLayout: EventLayout | null
  monthLayout: EventLayout[] | null
  monthOverflowEvents: any
  layoutUpdateSequence: number
  config: Config
  callbacks: Callbacks
  showMoreEvents: ShowMoreEvents | null
  direction: CALENDAR_NAVIGATION_DIRECTION
  draggingDisabledConditions: DraggingDisabledConditions | null
  isNewEventOpen: boolean
  style: Style
}

type ReactSchedulingData = {
  store: Store
  dispatch: React.Dispatch<any>
}

export const ReactSchedulingContext = createContext({} as ReactSchedulingData)

export function ReactSchedulingProvider({ children }: ReactSchedulingProps) {
  const initialContext: Store = {
    isLoading: false,
    headerEventRowsCount: 0,
    initialView: null,
    selectedView: null,
    selectedDate: DateTime.now(),
    calendarDays: [],
    width: 0,
    rawWidth: 0,
    height: 0,
    isMobile: false,
    events: {},
    daysViewLayout: null,
    headerLayout: null,
    monthLayout: null,
    monthOverflowEvents: null,
    showMoreEvents: null,
    layoutUpdateSequence: 1,
    config: createConfig(),
    callbacks: createCallbacks({}),
    direction: CALENDAR_NAVIGATION_DIRECTION.TODAY,
    isNewEventOpen: false,
    draggingDisabledConditions: null,
    style: {
      primaryColor: '#ec407a',
      baseColor: '#424242FF',
      inverseBaseColor: '#E5E5E5FF'
    }
  }

  const [store, dispatch] = useReducer(Reducer, initialContext)

  return (
    <ReactSchedulingContext.Provider value={{ store, dispatch }}>
      {children}
    </ReactSchedulingContext.Provider>
  )
}
