import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useBreakpointValue
} from '@chakra-ui/react'
import ReactPlayer from 'react-player'

interface ModalMediaPlayerProps {
  isOpen: boolean
  onClose: () => void
  isPhoto: boolean
  src: string
}

export const ModalMediaPlayer = ({
  isOpen,
  onClose,
  isPhoto,
  src
}: ModalMediaPlayerProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? '2xl' : 'full'}
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg="transparent">
          <ModalBody p={isPhoto ? '12' : '6'} position="relative">
            {isPhoto ? (
              <Image src={src} objectFit="cover" w="100%" h="100%" />
            ) : (
              <ReactPlayer url={src} controls height="100%" width="100%" />
            )}
            {!isWideVersion && (
              <Button
                onClick={onClose}
                position="absolute"
                bottom="2"
                right="2"
                size="xs"
                colorScheme="red"
              >
                Fechar
              </Button>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
