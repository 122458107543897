import styled, { css } from 'styled-components'

interface ContainerProps {
  isDragging: boolean
}

export const Container = styled.li<ContainerProps>`
  background: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
  border-top: 20px solid rgba(230, 236, 245, 0.4);
  cursor: grab;

  h1 {
    font-weight: 500;
    line-height: 20px;
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 2px dashed rgba(0, 0, 0, 0.2);
      padding-top: 31px;
      border-radius: 0;
      background: none;
      cursor: grabbing;

      p {
        opacity: 0;
      }
    `}
`
