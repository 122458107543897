import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction } from 'react'
import { FieldError } from 'react-hook-form'
import { OptionTypeBase, Props as SelectProps } from 'react-select'
import Select from 'react-select/async'

import { Container } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  title: string
  defaultValueSelect?: {
    value?: string
    label?: string
    sigla?: string
    code?: string
    mask?: string | string[]
  }
  onInputChange?: (value: string) => void
  onLoadingData?: (value: string, callback: (options: any[]) => void) => void
  name?: string
  placeholder?: string
  isRequired?: boolean
  medium?: boolean
  error?: FieldError
}

const SelectC: ForwardRefRenderFunction<HTMLSelectElement, Props> = (
  {
    name,
    defaultValueSelect,
    title,
    placeholder,
    isRequired = false,
    medium = true,
    error,
    onInputChange,
    onLoadingData,
    ...rest
  },
  ref
) => {
  return (
    <FormControl isInvalid={!!error} isRequired={isRequired}>
      <Container medium={medium}>
        {!!title && (
          <FormLabel fontSize="14px" htmlFor={name}>
            {title}
          </FormLabel>
        )}
        <Select
          name={name}
          id={name}
          placeholder={placeholder}
          cacheOptions
          defaultOptions
          onInputChange={onInputChange}
          loadOptions={onLoadingData}
          noOptionsMessage={() => 'sem opções para selecionar'}
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: ' #3182CE'
            }
          })}
          {...rest}
        />
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </Container>
    </FormControl>
  )
}

export const SelectComponentAsync = forwardRef(SelectC)
