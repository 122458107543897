/* eslint-disable no-useless-escape */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-children-prop */
import {
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  useCallback,
  ElementType,
  useEffect
} from 'react'
import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon
} from '@chakra-ui/react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { FieldError } from 'react-hook-form'
import { mask } from '../../utils/mask'

interface InputProps extends ChakraInputProps {
  name: string
  label?: string
  error?: FieldError
  type?: string
  isRequired?: boolean
  iconRight?: ElementType
  _active?: any
  disabled?: boolean
  size?: string
  masker?: 'cpf' | 'cep' | 'foneBr' | 'data' | any
  defaultValue?: string
  handleChange?: (e: any) => void
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    error = null,
    type = 'text',
    isRequired = false,
    iconRight,
    disabled,
    size = 'md',
    masker = '',
    defaultValue,
    handleChange,
    ...rest
  },
  ref
) => {
  const [typeInput, setTypeInput] = useState(type)
  const [value, setValue] = useState('')
  const [maskerState, setMasker] = useState('')

  const handleChangeType = useCallback(() => {
    if (typeInput === 'password') {
      setTypeInput('text')
    } else {
      setTypeInput('password')
    }
  }, [typeInput])

  const changeValueApplyMask = (e: any) => {
    const valueModifers = e.target.value

    if (!!handleChange) {
      handleChange!(e)
    }

    if (maskerState === 'cpf') {
      setValue(mask(valueModifers, '999.999.999-99'))
    } else if (maskerState === 'cep') {
      setValue(mask(valueModifers, '99999-999'))
    } else if (maskerState === 'phoneBr') {
      setValue(mask(valueModifers, '(99) 99999-9999'))
    } else if (maskerState === 'phone') {
      setValue(mask(valueModifers, '999999999999999'))
    } else if (maskerState === 'data') {
      setValue(mask(valueModifers, '99/99/9999'))
    } else if (!maskerState) {
      setValue(valueModifers)
    } else {
      setValue(mask(valueModifers, maskerState))
    }
  }

  useEffect(() => {
    setMasker(masker)
  }, [masker])

  useEffect(() => {
    if (!!defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  const defaultMaxLength = () => {
    switch (type) {
      case 'text':
        return 150
      case 'number':
        return 50
      default:
        return 200
    }
  }

  const length = defaultMaxLength()

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired} bgColor="white">
      {!!label && (
        <FormLabel fontSize="14px" htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <InputGroup display="flex" onChange={(e: any) => changeValueApplyMask(e)}>
        <ChakraInput
          borderRadius="none"
          name={name}
          id={name}
          focusBorderColor="blue.400"
          bgColor="white"
          borderColor="gray.100"
          borderWidth="1px"
          variant="filled"
          disabled={disabled}
          type={typeInput}
          maxLength={length || 200}
          _hover={{
            bgColor: 'blue.50'
          }}
          size={size}
          ref={ref}
          defaultValue={value}
          {...rest}
        />
        {!!iconRight && (
          <InputRightElement
            mt={size === 'md' ? '0px' : '4px'}
            children={
              <Icon
                as={iconRight}
                fontSize={size === 'md' ? '15px' : '18px'}
                color="#9699B0"
              />
            }
          />
        )}
        {type === 'password' && (
          <button type="button" onClick={handleChangeType}>
            {typeInput === 'password' ? (
              <InputRightElement
                mt={size === 'md' ? '0px' : '4px'}
                children={
                  <FiEye
                    size={size === 'md' ? '15px' : '18px'}
                    color="#9699B0"
                  />
                }
              />
            ) : (
              <InputRightElement
                mt={size === 'md' ? '0px' : '4px'}
                children={
                  <FiEyeOff
                    size={size === 'md' ? '15px' : '18px'}
                    color="#9699B0"
                  />
                }
              />
            )}
          </button>
        )}
      </InputGroup>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
