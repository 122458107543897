/* eslint-disable radix */
/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Stack,
  Textarea,
  useBreakpointValue
} from '@chakra-ui/react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import { CardContainer } from '../../components/molecules/CardContainer'
import { Input } from '../../components/Form/Input'

import { ArrowBack } from '../../components/atoms/arrowBack'
import { Sidebar } from '../../components/Sidebar'
import { Header } from '../../components/Header'

import { Switch } from '../../components/Form/Switch'
import { useService } from './service'

import { remaskCaractersAll } from '../../utils/fns/removeCaracters'
import { PermissionComponent } from '../../components/Permissions'
import { Select } from '../../components/Form/Select'
import { TextFieldControlled } from '../../components/Form/TextFieldControlled'

type FormData = {
  name: string
  is_active: boolean
  is_default: boolean
  observation: string
}

type Masks = 'cpf' | 'cnpj' | undefined

export function CreateAndUpdateUnitControl() {
  const [t] = useTranslation('pageTaxUnitControl')
  const typesDocument = [
    { value: '1', label: `${t('documents.1')}` },
    { value: '2', label: `${t('documents.2')}` },
    { value: '3', label: `${t('documents.3')}` }
  ]
  const service = useService()
  const history = useHistory()
  const [localMethod, setLocal] = useState('')
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })
  const { state }: any = history.location
  const { register, handleSubmit, formState } = useForm<FormData>({})
  const { errors } = formState
  const [name, setName] = useState('')
  const [document, setDocument] = useState('')
  const [typeDocument, setTypeDocument] = useState('')
  const [maskDocument, setMaskDocument] = useState<Masks>(undefined)

  useEffect(() => {
    switch (typeDocument) {
      case '1':
        setMaskDocument('cpf')
        break
      case '2':
        setMaskDocument('cnpj')
        break
      default:
        setMaskDocument(undefined)
    }

    return () => {}
  }, [typeDocument])

  function handleGetMethod() {
    const local = history.location.pathname

    if (local.indexOf('create') !== -1) {
      setLocal('create')
    } else {
      setLocal('update')
    }
  }

  const handleCreateOrUpdateTaxUnits: SubmitHandler<FormData> = async (
    values
  ) => {
    const payload = {
      name,
      document_type: Number(typeDocument),
      is_active: values.is_active,
      document_number: document,
      description: values.observation === '' ? null : values.observation
    }

    if (localMethod === 'create') {
      service.createRequest(payload)
    } else {
      const { id } = state

      service.updateRequest(payload, id)
    }
  }

  // Get values
  useEffect(() => {
    if (state?.name !== undefined) {
      setName(state.name)
    }

    if (state?.document_type) {
      setTypeDocument(String(state?.document_type))
    }

    if (state?.document_number) {
      setDocument(state?.document_number)
    }
  }, [])

  useEffect(() => {
    handleGetMethod()
  }, [history.location.pathname])

  return (
    <Box overflow="hidden" position="relative">
      <Header>
        <Flex
          mx="auto"
          w="100%"
          direction="row"
          d="flex"
          my="6"
          align="center"
          justify="space-around"
        >
          <Box mx="auto">
            <Text fontSize="24px" fontWeight="600" height="100%">
              {localMethod === 'create'
                ? `${t('create.title')}`
                : `${t('updateTitle')}`}
            </Text>
          </Box>
          <Stack direction="row" align="center" justify="center">
            <ArrowBack href="/financial/tax/control/unit">
              <Text fontSize="md" fontWeight="bold">
                Voltar
              </Text>
            </ArrowBack>
          </Stack>
        </Flex>
      </Header>
      <Sidebar />
      <Flex
        flex="1"
        mx="auto"
        as="form"
        ml={isWideVersion ? '75px' : '2'}
        mt="15px"
        mb="8px"
        mr="2"
        bg="white"
        h="cal(100vh - 60px)"
        onSubmit={handleSubmit(handleCreateOrUpdateTaxUnits)}
      >
        <Stack spacing="4" w="100%">
          <CardContainer title={t('create.container1.title')}>
            <Stack direction="row" my="2" spacing="6">
              <Box w="35%">
                <Input
                  label={t('create.container1.i1')}
                  onChange={(event) =>
                    setName(event.target.value.toUpperCase())
                  }
                  value={name}
                  isRequired
                  maxLength={40}
                  name="name"
                  error={errors.name}
                />
              </Box>
              <Box w="160px">
                <Select
                  label={t('create.container1.i2')}
                  options={typesDocument}
                  value={typeDocument}
                  placeholder={t('create.container1.document')}
                  onChange={(event) => setTypeDocument(event.target.value)}
                  isRequired
                  name="typeDocument"
                />
              </Box>
              <Box w="320px">
                <TextFieldControlled
                  textAlign="left"
                  label={t('create.container1.i2')}
                  mask={maskDocument}
                  initialValue={document}
                  placeholder=""
                  inputOnChange={(event) =>
                    setDocument(event.currentTarget.value)
                  }
                  isRequired
                  name="document"
                />
              </Box>
              <Flex direction="row">
                {localMethod === 'create' ? (
                  <Switch
                    defaultChecked={
                      state?.is_active !== undefined ? state?.is_active : true
                    }
                    label={t('create.container1.i4')}
                    {...register('is_active')}
                  />
                ) : (
                  <PermissionComponent
                    spiCode="COST_CENTERS"
                    ipCode="INACTIVATE"
                  >
                    <Switch
                      defaultChecked={
                        state?.is_active !== undefined ? state?.is_active : true
                      }
                      label={t('create.container1.i4')}
                      {...register('is_active')}
                    />
                  </PermissionComponent>
                )}
              </Flex>
            </Stack>
            <Box w="100%">
              <Text mb="2" fontSize="14">
                {t('create.container1.i5')}
              </Text>
              <Textarea
                placeholder={t('create.container1.observation')}
                {...register('observation')}
                defaultValue={state?.description}
              />
            </Box>
            <Flex />
          </CardContainer>
          <Flex mt="6" w="100%" align="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              size="md"
              fontSize="md"
              bg="blue.300"
              color="#fff"
              isLoading={formState.isSubmitting}
            >
              {t('create.button')}
            </Button>
          </Flex>
        </Stack>
      </Flex>
    </Box>
  )
}
