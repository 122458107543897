import React, { useState, FC, useEffect } from 'react'
import {
  MenuButton,
  MenuList,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  Menu,
  MenuGroup,
  MenuItem,
  Text,
  MenuButtonProps
} from '@chakra-ui/react'

interface ButtonPropsMenu extends MenuButtonProps {}

interface Options {
  id: string
  name: string
}

export interface MultiSelectMenuProps {
  label: string
  options: Options[]
  backChange?: (values: String[] | String) => void
  render?: () => string[]
  buttonProps?: ButtonPropsMenu
}

export const MultiSelectPermissions: FC<MultiSelectMenuProps> = ({
  label,
  options,
  buttonProps,
  backChange,
  render
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  useEffect(() => {
    if (render?.() !== undefined) {
      const renderValues = render()
      setSelectedOptions(renderValues)
    }

    return () => {}
  }, [render])

  const renderPermissions = (ids: string[]) => {
    const optionsSelect = ids.map((id) =>
      options.find((option) => option.id === id)
    )

    const nameOptions = optionsSelect.map((option) => {
      return option?.name
    })

    const namesOptionsSelect = nameOptions.join(', ')

    return namesOptionsSelect || ''
  }

  return (
    <Menu closeOnSelect={false}>
      {({ onClose }) => (
        <>
          <MenuButton
            type="button"
            backgroundColor={selectedOptions.length ? 'blue.500' : 'white'}
            color={selectedOptions.length ? 'white' : 'gray.600'}
            borderRadius={8}
            textAlign="left"
            px="4"
            py="8px"
            mx="2"
            my="2px"
            w="100%"
            border={selectedOptions.length ? 'none' : '1px solid gray'}
            _focus={{ outline: 'none' }}
            {...buttonProps}
          >
            {selectedOptions.length > 0
              ? renderPermissions(selectedOptions)
              : `${label}`}
          </MenuButton>
          <MenuList zIndex="modal">
            <MenuGroup title={undefined}>
              <MenuItem
                onClick={() => {
                  const allOption = options.map((item) => {
                    return item.id
                  })

                  setSelectedOptions(allOption)
                  backChange?.(allOption)
                  onClose()
                }}
              >
                Selecionar tudo
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedOptions([])
                  backChange?.([])
                  onClose()
                }}
              >
                Limpar tudo
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuOptionGroup
              type="checkbox"
              title={undefined}
              value={selectedOptions}
              onChange={(values) => {
                const allValues: any = values
                if (allValues.length > 0) {
                  const allValuesFilter: string[] = allValues.filter(
                    (id: string) => {
                      return id !== options[0].id
                    }
                  )
                  const newValues = [options[0].id, ...allValuesFilter]

                  setSelectedOptions(newValues)
                  backChange?.(newValues)
                } else {
                  setSelectedOptions(allValues)
                  backChange?.(allValues)
                }
              }}
            >
              {options.map((option) => (
                <MenuItemOption
                  key={option.id}
                  type="checkbox"
                  value={option.id}
                >
                  <Text fontSize="14px" noOfLines={1}>
                    {option.name}
                  </Text>
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}
