import {
  Box,
  Image,
  CloseButton,
  Button,
  Link as LinkChakra,
  Icon,
  HStack,
  useToast
} from '@chakra-ui/react'
import { saveAs } from 'file-saver'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import { FiEdit } from 'react-icons/fi'
import { apiAuth } from '../../services/apiAuth'

interface CardImageProps {
  src: string
  alt?: string
  noEdit?: boolean
  onOpenExclude: () => void
  onOpenEdit: () => void
  onViewPhoto: () => void
}

export const CardImage = ({
  src,
  alt,
  onOpenExclude,
  onOpenEdit,
  onViewPhoto,
  noEdit
}: CardImageProps) => {
  const toast = useToast()

  const handlerDownloadImage = () => {
    fetch(src, { mode: 'cors', method: 'GET' })
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, `${alt || new Date().toLocaleTimeString()}`)
      })
      .catch((error) => {
        toast({
          status: 'error',
          title: 'Não foi possível fazer download do arquivo',
          duration: 3000,
          position: 'top-right'
        })
      })
  }

  return (
    <Box h="171px" w="304px" borderRadius={16} position="relative" bg="black">
      <HStack position="absolute" right="2" top="2" zIndex={10}>
        <Button
          w="24px"
          h="24px"
          size="sm"
          bg="transparent"
          color="white"
          _hover={{ bg: 'green', color: 'white' }}
          borderRadius={8}
          transition="0.2s ease-in"
          onClick={handlerDownloadImage}
        >
          <Icon as={AiOutlineCloudDownload} />
        </Button>
        {!noEdit && (
          <Button
            w="24px"
            h="24px"
            bg="transparent"
            color="white"
            size="sm"
            onClick={() => onOpenEdit()}
            _hover={{ bg: 'green', color: 'white' }}
          >
            <Icon as={FiEdit} />
          </Button>
        )}
        <CloseButton
          zIndex={10}
          colorScheme="red"
          color="white"
          onClick={() => onOpenExclude()}
          _hover={{ bg: 'red', color: 'white' }}
        />
      </HStack>
      <Image
        w="100%"
        h="100%"
        src={src}
        alt={alt}
        borderRadius="16"
        objectFit="cover"
        _hover={{ cursor: 'pointer' }}
        onClick={onViewPhoto}
      />
    </Box>
  )
}
