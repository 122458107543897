/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Tooltip,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Text,
  Skeleton,
  Heading,
  Stack,
  Flex,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  ListIcon
} from '@chakra-ui/react'
import { FiDollarSign, FiEdit } from 'react-icons/fi'
import { BiMoveVertical } from 'react-icons/bi'

import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { te } from 'date-fns/locale'
import {
  useRealizedCashFlow,
  getRealizedCashFlow,
  RealizedCashFlowData
} from '../../hooks/realizedCashFlow/useRealizedCashFlow'
import { useAuth } from '../../hooks/auth'
import { LayoutList } from '../../layouts/List'
import { NotFound } from '../../components/molecules/NotFound'
import { Pagination } from '../../components/PaginationNew'
import { PermissionComponent } from '../../components/Permissions'
import { formatBrOrDollarDefault } from '../../utils/fns/formatBrOrDollarDefault'
import { LayoutBlank } from '../../layouts/blank'
// debounce
let timer: any = null

export function RealizedCashFlow() {
  const { clinicId } = useAuth()
  const [t] = useTranslation('pageRealizedCashFlow')
  const skeletonRepeat = [1, 2, 3, 4, 5, 6, 7]
  // state
  const [searchState, setSearchState] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [isActive, setIsActive] = useState(true)

  const getRealizedCashFlowData = useRealizedCashFlow(
    currentPage,
    getRealizedCashFlow(currentPage, searchState, isActive),
    searchState,
    isActive
  )

  const SearchInputCallbackValue = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget

      clearTimeout(timer)

      timer = setTimeout(() => {
        setSearchState(value)
      }, 500)
    },
    []
  )

  const ActiveCheckboxCallbackValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsActive(event.target.checked)
    },
    []
  )

  const returnColorBgLineInTable = (minimum: number, balance: number) => {
    if (minimum > balance) {
      return 'red.500'
    }

    return 'green.500'
  }

  return (
    <LayoutBlank
      title={t('title')}
      refetch={getRealizedCashFlowData.refetch}
      capturaChange={(event: any) => SearchInputCallbackValue(event)}
      captureActiveChange={(event: any) => ActiveCheckboxCallbackValue(event)}
      placeholder={t('search')}
      noRenderIsActive
      spiCode="PRODUCTS"
    >
      <Stack w="100%" spacing="4" justifyContent="space-evenly">
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t0')}</Th>
              <Th>
                <Text>2022</Text>
              </Th>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
              <Th>{t('t5')}</Th>
              <Th>{t('t6')}</Th>
              <Th>{t('t7')}</Th>
              <Th>{t('t8')}</Th>
              <Th>{t('t9')}</Th>
              <Th>{t('t10')}</Th>
              <Th>{t('t11')}</Th>
              <Th>{t('t12')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getRealizedCashFlowData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {/* fake data */}
                <Tr>
                  <Td w="150px">
                    <Text>Entradas</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>Venda de serviços</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>Venda de produtos</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr w="150px">
                  <Td>
                    <Text>Outras entradas</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontSize="sm" fontWeight="medium">
                    Ver por meios de recebimento
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table variant="striped" colorScheme="blue" size="sm">
                <Thead>
                  <Tr>
                    <Th>{t('meansOfReceiving')}</Th>
                    <Th>{t('t0')}</Th>
                    <Th>{t('t1')}</Th>
                    <Th>{t('t2')}</Th>
                    <Th>{t('t3')}</Th>
                    <Th>{t('t4')}</Th>
                    <Th>{t('t5')}</Th>
                    <Th>{t('t6')}</Th>
                    <Th>{t('t7')}</Th>
                    <Th>{t('t8')}</Th>
                    <Th>{t('t9')}</Th>
                    <Th>{t('t10')}</Th>
                    <Th>{t('t11')}</Th>
                    <Th>{t('t12')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getRealizedCashFlowData.isLoading ? (
                    <>
                      {skeletonRepeat.map((skeleton) => (
                        <Tr>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                        </Tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* fake data */}
                      <Tr>
                        <Td>
                          <Text>Dinheiro</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Pix</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Cartão Crédito</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Cartão Debito</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                    </>
                  )}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t1')}</Th>
              <Th>{t('t2')}</Th>
              <Th>{t('t3')}</Th>
              <Th>{t('t4')}</Th>
              <Th>{t('t5')}</Th>
              <Th>{t('t6')}</Th>
              <Th>{t('t7')}</Th>
              <Th>{t('t8')}</Th>
              <Th>{t('t9')}</Th>
              <Th>{t('t10')}</Th>
              <Th>{t('t11')}</Th>
              <Th>{t('t12')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getRealizedCashFlowData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {/* fake data */}
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.22')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.23')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.24')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.25')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.26')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.27')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.28')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.29')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.30')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.31')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.32')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.33')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td w="150px">
                    <Text>{t('category.34')}</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
        <Accordion allowMultiple>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <Text fontSize="sm" fontWeight="medium">
                    Ver por meios de pagamento
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Table variant="striped" colorScheme="blue" size="sm">
                <Thead>
                  <Tr>
                    <Th>{t('meansOfPayment')}</Th>
                    <Th>{t('t0')}</Th>
                    <Th>{t('t1')}</Th>
                    <Th>{t('t2')}</Th>
                    <Th>{t('t3')}</Th>
                    <Th>{t('t4')}</Th>
                    <Th>{t('t5')}</Th>
                    <Th>{t('t6')}</Th>
                    <Th>{t('t7')}</Th>
                    <Th>{t('t8')}</Th>
                    <Th>{t('t9')}</Th>
                    <Th>{t('t10')}</Th>
                    <Th>{t('t11')}</Th>
                    <Th>{t('t12')}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getRealizedCashFlowData.isLoading ? (
                    <>
                      {skeletonRepeat.map((skeleton) => (
                        <Tr>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                          <Td>
                            <Skeleton height="20px" />
                          </Td>
                        </Tr>
                      ))}
                    </>
                  ) : (
                    <>
                      {/* fake data */}
                      <Tr>
                        <Td>
                          <Text>BB - Banco do brasil</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Outro Banco</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Banco Inter</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                      <Tr>
                        <Td>
                          <Text>Caixinha</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                        <Td textAlign="right">
                          <Text>0,00</Text>
                        </Td>
                      </Tr>
                    </>
                  )}
                </Tbody>
              </Table>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Table variant="striped" colorScheme="blue" size="sm">
          <Thead>
            <Tr>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
              <Th>{t('t0')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {getRealizedCashFlowData.isLoading ? (
              <>
                {skeletonRepeat.map((skeleton) => (
                  <Tr>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </>
            ) : (
              <>
                {/* fake data */}
                <Tr>
                  <Td>
                    <Text>Saldo Final</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                  <Td textAlign="right">
                    <Text>0,00</Text>
                  </Td>
                </Tr>
              </>
            )}
          </Tbody>
        </Table>
      </Stack>
    </LayoutBlank>
  )
}
