import { Text } from '@chakra-ui/react'

export function Logo() {
  return (
    <Text
      fontSize={['2xl', '3xl']}
      fontWeight="bold"
      letterSpacing="tight"
      w="176px"
      color="blue.500"
    >
      Clinik.net
    </Text>
  )
}
