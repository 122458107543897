/* eslint-disable array-callback-return */
import React, { useState } from 'react'

type HealthInsurance = {
  healthInsurance: {
    value: string
    label: string
  } | null
  healthInsuranceNumber: string | null
}

type PatientsRelative = {
  patientRelatives: {
    value: string
    label: string
  } | null
  patientDegree: number
}

export const useCustomers = () => {
  // estados
  const [healthInsurance, setHealthInsurance] = useState<HealthInsurance[]>([
    {
      healthInsurance: null,
      healthInsuranceNumber: ''
    }
  ])

  const [patientRelatives, setPatientRelatives] = useState<PatientsRelative[]>([
    {
      patientRelatives: null,
      patientDegree: 0
    }
  ])

  const [patientsTags, setPatientsTags] = useState<any>([])
  const [patientsOrigins, setPatientsOrigins] = useState<any>([])
  const [image, setImage] = useState<any>([])

  // métodos p/ calcular a idade
  function fragmentDate(data: string) {
    const dia = Number(data.substring(0, 2))
    const mes = Number(data.substring(3, 5))
    const ano = Number(data.substring(6, 12))

    return {
      dia,
      mes,
      ano
    }
  }

  // métodos p/ calcular a idade
  function fragmentDateBackendFormat(data: string) {
    const ano = Number(data.substring(0, 3))
    const mes = Number(data.substring(5, 6))
    const dia = Number(data.substring(8, 12))

    return {
      dia,
      mes,
      ano
    }
  }

  function calculateAge(data: string) {
    // data atual
    const dateCurrent = new Date()
    const yearCurrent = dateCurrent.getFullYear()
    const mothCurrent = dateCurrent.getMonth() + 1
    const dayCurrent = dateCurrent.getDate()

    // data nascimento
    const { dia, mes, ano } = fragmentDate(data)

    let years = Number(yearCurrent) - Number(ano)

    if (mothCurrent < mes || (mothCurrent === mes && dayCurrent < dia)) {
      years -= 1
    }

    return years < 0 ? 0 : years
  }

  function calculateAgeBackendFormat(data: string) {
    // data atual
    const dateCurrent = new Date()
    const yearCurrent = dateCurrent.getFullYear()
    const mothCurrent = dateCurrent.getMonth() + 1
    const dayCurrent = dateCurrent.getDate()

    // data nascimento
    const { dia, mes, ano } = fragmentDateBackendFormat(data)

    let years = Number(yearCurrent) - Number(ano)

    if (mothCurrent < mes || (mothCurrent === mes && dayCurrent < dia)) {
      years -= 1
    }

    return years < 0 ? 0 : years
  }

  // métodos de controle dos campos de convênios
  function addFieldsConvenio() {
    setHealthInsurance([
      ...healthInsurance,
      {
        healthInsurance: null,
        healthInsuranceNumber: ''
      }
    ])
  }

  function removeFieldsConvenio(index: number) {
    const values = [...healthInsurance]
    values.splice(index, 1)
    setHealthInsurance(values)
  }

  function handleSelectConvenio(index: number, event: any) {
    const newHealthInsurance = [...healthInsurance]

    newHealthInsurance[index].healthInsurance = event

    setHealthInsurance(newHealthInsurance)
  }

  function handleInputConvenio(
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const values = [...healthInsurance]

    values[index].healthInsuranceNumber = event?.target.value

    setHealthInsurance(values)
  }

  // métodos de controle dos campos de convênios
  function addFieldsRelatives() {
    setPatientRelatives([
      ...patientRelatives,
      {
        patientRelatives: null,
        patientDegree: 0
      }
    ])
  }

  function removeFieldsRelatives(index: number) {
    const values = [...patientRelatives]
    values.splice(index, 1)
    setPatientRelatives(values)
  }

  function handleSelectRelativesFamily(index: number, event: any) {
    const values = [...patientRelatives]

    values[index].patientDegree = Number(event.target.value)

    setPatientRelatives(values)
  }

  function handleSelectedRelativesNames(index: number, event: any) {
    const values = [...patientRelatives]

    values[index].patientRelatives = event

    setPatientRelatives(values)
  }

  // FUNCTION FOR SELECTED TAGS
  function handleSelectedTags(event: any) {
    setPatientsTags(event)
  }
  // FUNCTION FOR SELECTED ORIGENS
  function handleSelectedOrigins(event: any) {
    setPatientsOrigins(event)
  }

  // formatação de números
  function formattingNumbers(numbers: any) {
    let numbersAux: any = []
    numbers.map((number: any) => {
      numbersAux = [
        ...numbersAux,
        {
          contact: number
        }
      ]
    })

    return numbersAux
  }

  return {
    calculateAge,
    healthInsurance,
    setHealthInsurance,
    setPatientRelatives,
    addFieldsConvenio,
    removeFieldsConvenio,
    handleSelectConvenio,
    handleInputConvenio,
    fragmentDateBackendFormat,
    patientRelatives,
    addFieldsRelatives,
    removeFieldsRelatives,
    handleSelectRelativesFamily,
    calculateAgeBackendFormat,
    handleSelectedRelativesNames,
    handleSelectedTags,
    handleSelectedOrigins,
    patientsTags,
    setImage,
    image,
    patientsOrigins,
    setPatientsTags,
    setPatientsOrigins,
    formattingNumbers
  }
}
