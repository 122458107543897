import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import { BoardCard } from '../BoardCard'

import * as C from './styles'

interface BoardListProps {
  title: string
  data: Array<any> | undefined
}

export const BoardList: React.FC<BoardListProps> = ({ title, data }) => {
  return (
    <C.Container>
      <C.Header>{title}</C.Header>
      <ul>
        <SimpleGrid minChildWidth="320px">
          {data?.map((card, index) => (
            <BoardCard key={card.id} index={index} data={card} />
          ))}
        </SimpleGrid>
      </ul>
    </C.Container>
  )
}
