/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  ListItem,
  Flex,
  Text,
  Image,
  Icon,
  CircularProgressLabel,
  CircularProgress,
  Button,
  Link
} from '@chakra-ui/react'
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useDocument } from '../../hooks/useDocument'
import { UploadFilesData } from '../../contexts/DocumentContext'

type FileListProps = {
  file: UploadFilesData
}

export const FileListItem: React.FC<FileListProps> = ({ file }) => {
  const [t] = useTranslation('global')
  const { processUpload } = useDocument()
  const [itemError, setItemError] = useState(file.error)
  const [itemProgress, setItemProgress] = useState(file.progress)
  const [itemUploaded, setItemUploaded] = useState(file.uploaded)
  const [itemUrl, setItemUrl] = useState(file.url)
  const [itemId, setItemId] = useState(file.id)

  useEffect(() => {
    function upload() {
      processUpload(
        file,
        setItemProgress,
        setItemId,
        setItemUrl,
        setItemUploaded,
        setItemError
      )
    }

    upload()
  }, [])

  return (
    <ListItem
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      color="#444"
      mb="2"
    >
      <Flex alignItems="center" justifyContent="center">
        <Image
          w="46px"
          h="46px"
          borderRadius={8}
          bgImage={file.preview}
          bgRepeat="no-repeat"
          bgSize="cover"
          bgPosition="50% 50%"
          mr="2"
        />
        <Flex flexDirection="column">
          <Text as="strong">{file.name}</Text>
          <Flex mt="2" alignItems="center" justifyContent="flex-start">
            <Text mr="2px" fontSize="12px" color="#999">
              {file.readableSize}
            </Text>
            {/* Case exclude item after upload */}
            {/* {itemUrl && (
              <Button
                fontSize="12px"
                bg="transparent"
                color="#121214"
                size="xs"
                _hover={{ color: 'red' }}
              >
                {t('remove')}
              </Button>
            )} */}
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        {!itemUploaded && !itemError && (
          <CircularProgress value={itemProgress} size={10} color="blue.400">
            <CircularProgressLabel fontSize="10px">
              {itemProgress}%
            </CircularProgressLabel>
          </CircularProgress>
        )}

        {!!itemUrl && (
          <Link href={itemUrl} target="_blank" rel="noopener noreferrer">
            <Icon as={MdLink} size={24} color="#222" />
          </Link>
        )}

        {itemUploaded && (
          <Icon mx="2" as={MdCheckCircle} size={24} color="#78e5d5" />
        )}
        {itemError && <Icon mx="2" as={MdError} size={24} color="#e57878" />}
      </Flex>
    </ListItem>
  )
}
