import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalRegisterProps {
  children: ReactNode
  title: string
  isOpen: boolean
  isUpdating?: boolean
  handlerAction: () => Promise<void>
  onClose: () => void
}

export const ModalRegister = ({
  children,
  title,
  isOpen,
  handlerAction,
  isUpdating = false,
  onClose
}: ModalRegisterProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? '4xl' : 'sm'}
        blockScrollOnMount={false}
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button variant="solid" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="solid"
              colorScheme="blue"
              mr={3}
              onClick={handlerAction}
            >
              {isUpdating ? 'Salvar' : 'Cadastrar'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
