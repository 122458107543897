import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalWebcamProps {
  children: ReactNode
  title: string
  isOpen: boolean
  onClose: () => void
}

export const ModalWebcam = ({
  children,
  title,
  isOpen,
  onClose
}: ModalWebcamProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isWideVersion ? '4xl' : 'full'}
        blockScrollOnMount={false}
        key={title}
        isCentered
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
