/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import {
  remaskCaractersAll,
  formatCurrencyUSDDefault
} from '../../utils/fns/removeCaracters'

import { useAuth } from '../../hooks/auth'
import { mask } from '../../utils/mask'
import { apiAuth } from '../../services/apiAuth'

type PaymentMethodsData = {
  paymentDate: string
  amount: string
  isRequired: boolean
  paymentMethod: string
  balance: string
}

type DocumentData = {
  id: number
  document: any
}

type ErrorData = {
  isInvalid: boolean
  message: string
}

const maskCurrencyBR = [
  '9,99',
  '99,99',
  '999,99',
  '9.999,99',
  '99.999,99',
  '999.999,99',
  '9.999.999,99'
]

const maskDate = ['99/9999']

const maskDolar = [
  '9.99',
  '99.99',
  '999.99',
  '9,999.99',
  '99,999.99',
  '999,999.99',
  '9,999,999.99'
]

let timer: any = null

export const useLogicPaymentMethods = () => {
  // hooks
  const { decodeToken } = useAuth()
  const toast = useToast()
  const [masker, setMasker] = useState<string | string[]>('')

  // States
  const [discountPrice, setDiscountPrice] = useState('')
  const [additionPrice, setAdditionPrice] = useState('')
  const [price, setPrice] = useState('')
  const [isInvalid, setIsInvalid] = useState<ErrorData>({
    isInvalid: false,
    message: ''
  })
  const [isInvalidBalance, setIsInvalidBalance] = useState<ErrorData>({
    isInvalid: false,
    message: ''
  })
  // required item PaymentMethod
  const [isRequired, setIsRequired] = useState(false)
  const [nominalPrice, setNominalPrice] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [paymentMethods, setPaymentMethod] = useState<PaymentMethodsData[]>([
    {
      paymentDate: format(new Date(), 'yyyy-MM-dd'),
      amount: '',
      paymentMethod: '',
      balance: '',
      isRequired: false
    }
  ])
  const [documents, setDocuments] = useState<DocumentData[]>([
    { id: 1, document: {} }
  ])

  // Operation
  const handleAddNewNumbersValueForFinalPrice = (
    D: string,
    N: string,
    A: string
  ) => {
    const valueDiscount = Number(remaskCaractersAll(D)) / 100
    const valuePrice = Number(remaskCaractersAll(N)) / 100
    const valueAddition = Number(remaskCaractersAll(A)) / 100

    const value = valuePrice - valueDiscount + valueAddition
    const valueReFormated = String(formatCurrencyUSDDefault(value))

    return valueReFormated
  }

  const handleRemoveNewNumbersValueDiscount = (
    N: string,
    D: string,
    A: string
  ) => {
    const valuePrice = Number(remaskCaractersAll(N)) / 100
    const valueDiscount = Number(remaskCaractersAll(D)) / 100
    const valueAddition = Number(remaskCaractersAll(A)) / 100

    const value = valuePrice - valueDiscount + valueAddition
    const valueReFormated = String(formatCurrencyUSDDefault(value))

    return valueReFormated
  }

  const handleRemoveNewNumbersValueDiscountNull = (N: string, A: string) => {
    const valuePrice = Number(remaskCaractersAll(N)) / 100
    const valueNominal = Number(remaskCaractersAll(A)) / 100

    const value = valuePrice + valueNominal
    const valueReFormated = String(formatCurrencyUSDDefault(value))

    return valueReFormated
  }

  const handleRemoveNewNumbersValueAdditionNull = (N: string, D: string) => {
    const valuePrice = Number(remaskCaractersAll(N)) / 100
    const valueDiscount = Number(remaskCaractersAll(D)) / 100

    const value = valuePrice - valueDiscount
    const valueReFormated = String(formatCurrencyUSDDefault(value))

    return valueReFormated
  }

  const handleRemoveNewNumbersValueAddition = (
    N: string,
    D: string,
    A: string
  ) => {
    const valuePrice = Number(remaskCaractersAll(N)) / 100
    const valueDiscount = Number(remaskCaractersAll(D)) / 100
    const valueAddition = Number(remaskCaractersAll(A)) / 100

    const value = valuePrice - valueDiscount + valueAddition
    const valueReFormated = String(formatCurrencyUSDDefault(value))

    return valueReFormated
  }

  // Operation Aritmética 2 balance

  // Operation Balance - amount
  function handleChangePaymentAmountCalculateNewBalance(
    changeValue: string,
    balance: string
  ) {
    const value = Number(remaskCaractersAll(changeValue)) / 100
    const balanceValue = Number(remaskCaractersAll(balance)) / 100

    const newBalance = balanceValue - value
    const valueReFormated = String(formatCurrencyUSDDefault(newBalance))

    return valueReFormated
  }

  // Verify that the price isInvalid
  useEffect(() => {
    if (price === '') {
      return
    }

    const value = price?.split('')
    if (value[0] === '-') {
      setIsInvalid({
        isInvalid: true,
        // translate message!!
        message: 'Valor final inválido!'
      })
    } else {
      setIsInvalid({
        isInvalid: false,
        message: ''
      })
    }
  }, [price])

  // Verify that the balance isInvalid
  useEffect(() => {
    paymentMethods.forEach((paymentMethod, index) => {
      const verifyBalance = paymentMethod.balance?.split('')
      const verifyAmountRequired = paymentMethod.amount.length
      if (verifyAmountRequired > 0) {
        paymentMethods[index].isRequired = true
        console.log(paymentMethods)
      } else {
        paymentMethods[index].isRequired = false
        console.log(paymentMethods)
      }
      if (verifyBalance[index] === '-') {
        setIsInvalidBalance({
          isInvalid: true,
          // translate message!!
          message: 'Saldo invalido!'
        })
      } else {
        setIsInvalidBalance({
          isInvalid: false,
          message: ''
        })
      }
    })
  }, [paymentMethods])

  // functions add New paymentMethods
  function handleAddFieldPaymentMethodsDateIndex() {
    const values: PaymentMethodsData[] = [...paymentMethods]
    const lengthAux = values.length - 1

    const valuesReordered = values.map((value) => {
      return {
        paymentDate: value.paymentDate,
        amount: value.amount,
        balance: value.balance,
        paymentMethod: value.paymentMethod,
        isRequired: value.isRequired
      }
    })

    // Get value last index
    setPaymentMethod([
      ...paymentMethods,
      {
        paymentDate: valuesReordered[lengthAux].paymentDate,
        amount: '',
        balance: valuesReordered[lengthAux].balance,
        paymentMethod: valuesReordered[lengthAux].paymentMethod,
        isRequired: valuesReordered[lengthAux].isRequired
      }
    ])
  }

  // function Reorder and change new balanced
  function handleChangeReorderChangeInputFirstBalance(newBalance: string) {
    const values: PaymentMethodsData[] = [...paymentMethods]
    const valuesAux = values.slice(1)

    const valuesReordered = values.map((value) => {
      return {
        paymentDate: value.paymentDate,
        amount: value.amount,
        balance: value.balance,
        paymentMethod: value.paymentMethod,
        isRequired: value.isRequired
      }
    })

    setPaymentMethod([
      {
        paymentDate: valuesReordered[0].paymentDate,
        amount: valuesReordered[0].amount,
        balance: newBalance,
        paymentMethod: valuesReordered[0].paymentMethod,
        isRequired: valuesReordered[0].isRequired
      },
      ...valuesAux
    ])
  }

  function handleChangeReorderChangeInputIndexBalance(
    newBalance: string,
    index: number
  ) {
    // Verify add payment method
    const verifyAdd = Number(remaskCaractersAll(newBalance))
    if (verifyAdd === 0) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }

    const values: PaymentMethodsData[] = [...paymentMethods]
    const valuesAux = values.slice(index, index + 1)
    const newValue = valuesAux.map((value) => {
      return {
        paymentDate: value.paymentDate,
        amount: value.amount,
        balance: newBalance,
        paymentMethod: value.paymentMethod,
        isRequired: value.isRequired
      }
    })

    const valuesChange = values
    valuesChange[index] = newValue[0]

    setPaymentMethod(valuesChange)
  }

  function onRemoveFieldPaymentMethodsDateIndex(index: number) {
    const values = [...paymentMethods]
    values.splice(index, 1)

    // reordering the number of paymentMethods
    const valuesNewNumber = values.map((value) => {
      const backBalance = Number(remaskCaractersAll(value.balance)) / 100

      const backAmount = Number(remaskCaractersAll(value.amount)) / 100

      const newBalance = backAmount + backBalance

      return {
        paymentDate: value.paymentDate,
        amount: value.amount,
        balance: formatCurrencyUSDDefault(newBalance),
        paymentMethod: value.paymentMethod,
        isRequired: value.isRequired
      }
    })

    setPaymentMethod(valuesNewNumber)
  }

  const changeDiscountPrice = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e.nativeEvent.CAPTURING_PHASE) {
      e.stopPropagation()
      return
    }

    if (
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowLeft' ||
      e.code === 'ArrowRight'
    ) {
      e.stopPropagation()
      return
    }

    if (e.code === 'Backspace' && discountPrice === '') {
      e.stopPropagation()
      const newPrice = handleRemoveNewNumbersValueDiscountNull(
        nominalPrice,
        additionPrice
      )
      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      return
    }

    if (e.code === 'Delete' && discountPrice === '') {
      e.stopPropagation()
      const newPrice = handleRemoveNewNumbersValueDiscountNull(
        nominalPrice,
        additionPrice
      )
      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      return
    }

    if (e.code === 'Backspace' && discountPrice !== '') {
      const newPrice = handleRemoveNewNumbersValueDiscount(
        nominalPrice,
        e.currentTarget.value,
        additionPrice
      )

      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      e.stopPropagation()
      return
    }

    if (e.key === 'Delete') {
      e.stopPropagation()
      const newPrice = handleRemoveNewNumbersValueDiscountNull(
        nominalPrice,
        additionPrice
      )
      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      return
    }

    if (e.currentTarget.value) {
      const newPrice = handleAddNewNumbersValueForFinalPrice(
        e.currentTarget.value,
        nominalPrice,
        additionPrice
      )

      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      e.stopPropagation()
    }
  }

  const changeAdditionPrice = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!e.nativeEvent.CAPTURING_PHASE) {
      e.stopPropagation()
      return
    }

    if (
      e.code === 'ArrowUp' ||
      e.code === 'ArrowDown' ||
      e.code === 'ArrowLeft' ||
      e.code === 'ArrowRight'
    ) {
      e.stopPropagation()
      return
    }

    if (e.code === 'Backspace' && additionPrice === '') {
      e.stopPropagation()
      const newPrice = handleRemoveNewNumbersValueAdditionNull(
        nominalPrice,
        discountPrice
      )
      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      return
    }

    if (e.code === 'Delete' && additionPrice === '') {
      e.stopPropagation()
      const newPrice = handleRemoveNewNumbersValueAdditionNull(
        nominalPrice,
        discountPrice
      )
      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      return
    }

    if (e.code === 'Backspace' && additionPrice !== '') {
      const newPrice = handleRemoveNewNumbersValueAddition(
        nominalPrice,
        discountPrice,
        e.currentTarget.value
      )

      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      e.stopPropagation()
      return
    }

    if (e.key === 'Delete') {
      e.stopPropagation()
      const newPrice = handleRemoveNewNumbersValueAdditionNull(
        nominalPrice,
        discountPrice
      )
      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      return
    }

    if (e.currentTarget.value) {
      const newPrice = handleAddNewNumbersValueForFinalPrice(
        discountPrice,
        nominalPrice,
        e.currentTarget.value
      )

      setPrice(newPrice)

      handleChangeReorderChangeInputFirstBalance(newPrice)

      e.stopPropagation()
    }
  }

  function maskerCurrency() {
    if (decodeToken.clinic_country === 'United States of America') {
      setMasker(maskDolar)
    } else {
      setMasker(maskCurrencyBR)
    }
  }

  useEffect(() => {
    if (decodeToken !== undefined) {
      maskerCurrency()
    }
  }, [decodeToken])

  function handleInputChangeAmountPaymentMethods(
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) {
    const valuesMyArray: any = [...paymentMethods]

    valuesMyArray[index][event.currentTarget.name] = event.currentTarget.value

    setPaymentMethod(valuesMyArray)
  }

  function handleInputChangeAmountCalculation(
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) {
    const value = event.currentTarget.value

    if (!event.nativeEvent.CAPTURING_PHASE) {
      event.stopPropagation()
      return
    }

    if (
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown' ||
      event.code === 'ArrowLeft' ||
      event.code === 'ArrowRight'
    ) {
      event.stopPropagation()
      return
    }

    if (event.ctrlKey) {
      event.stopPropagation()
      return
    }

    switch (event.key) {
      case 'Control':
        return
      case 'Space':
        return
      case 'Alt':
        return
      case '':
        return
      case 'CapsLock':
        return
      case 'NumLock':
        return
      case 'Enter':
        return
      default:
        break
    }

    if (event.key === 'Delete') {
      paymentMethods[index].amount = ''
      if (index === 0) {
        const newBalance = handleChangePaymentAmountCalculateNewBalance(
          '',
          price
        )

        clearTimeout(timer)

        timer = setTimeout(() => {
          handleChangeReorderChangeInputIndexBalance(newBalance, index)
        }, 500)
      } else {
        const newBalance = handleChangePaymentAmountCalculateNewBalance(
          '',
          paymentMethods[index - 1].balance
        )

        clearTimeout(timer)

        timer = setTimeout(() => {
          handleChangeReorderChangeInputIndexBalance(newBalance, index)
        }, 500)
      }

      event.stopPropagation()
      return
    }

    if (event.key === 'Backspace') {
      if (index === 0) {
        const newBalance = handleChangePaymentAmountCalculateNewBalance(
          event.currentTarget.value,
          price
        )

        clearTimeout(timer)

        timer = setTimeout(() => {
          handleChangeReorderChangeInputIndexBalance(newBalance, index)
        }, 500)
      } else {
        const newBalance = handleChangePaymentAmountCalculateNewBalance(
          event.currentTarget.value,
          paymentMethods[index - 1].balance
        )

        clearTimeout(timer)

        timer = setTimeout(() => {
          handleChangeReorderChangeInputIndexBalance(newBalance, index)
        }, 500)
      }

      event.stopPropagation()
      return
    }

    console.log(index)

    if (index === 0) {
      const newBalance = handleChangePaymentAmountCalculateNewBalance(
        value,
        price
      )

      clearTimeout(timer)

      timer = setTimeout(() => {
        handleChangeReorderChangeInputIndexBalance(newBalance, index)
      }, 500)
    } else {
      const newBalance = handleChangePaymentAmountCalculateNewBalance(
        event.currentTarget.value,
        paymentMethods[index - 1].balance
      )

      clearTimeout(timer)

      timer = setTimeout(() => {
        handleChangeReorderChangeInputIndexBalance(newBalance, index)
      }, 500)
    }
  }

  function handleInputChange(
    event: React.FormEvent<HTMLInputElement>,
    index: number,
    maskerInput: string = ''
  ) {
    const values: any = [...paymentMethods]
    if (maskerInput === 'MM/AAAA') {
      values[index][event.currentTarget.name] = mask(
        event.currentTarget.value,
        '99/9999'
      )
    } else {
      values[index][event.currentTarget.name] = event.currentTarget.value
    }

    setPaymentMethod(values)
  }

  function handleSelectOptionPaymentMethods(
    e: any,
    index: number,
    name: string
  ) {
    const values: any = [...paymentMethods]

    values[index][name] = e.target.value

    setPaymentMethod(values)
  }

  // function fields documents
  function handleAddFieldDocumentsPaymentMethods() {
    const values: DocumentData[] = [...documents]
    const lengthAux = values.length - 1

    setDocuments([
      ...documents,
      {
        id: values[lengthAux].id + 1,
        document: {}
      }
    ])
  }

  function onRemoveFieldDocumentsPaymentMethods(index: number) {
    if (index === 0 && documents.length === 1) {
      setDocuments([{ id: 1, document: {} }])
    } else {
      const values = [...documents]
      values.splice(index, 1)
      setDocuments(values)
    }
  }

  async function handleExcludedDocumentByIndexId(index: number) {
    const values: DocumentData[] = [...documents]
    const myDocument = values[index]
    const id = String(myDocument.id)

    const { status } = await apiAuth.delete(`/clinics/documents-posting/${id}/`)

    if (status === 204) {
      toast({
        position: 'top-right',
        status: 'success',
        duration: 3000,
        title: 'Documento excluído',
        isClosable: true,
        description: 'Seu documento foi excluído com sucesso'
      })
    } else {
      toast({
        position: 'top-right',
        status: 'warning',
        duration: 3000,
        title: 'Ação não realizada!',
        isClosable: true,
        description: 'Tente novamente acessando o Lançamento'
      })
    }
  }

  function handleUploadDocument(e: any, index: number) {
    const values: any = [...documents]
    values[index].document = e[0] || ''

    setDocuments(values)
  }

  const toBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  return {
    paymentMethods,
    documents,
    setDocuments,
    changeDiscountPrice,
    discountPrice,
    additionPrice,
    setPrice,
    setDiscountPrice,
    isDisabled,
    price,
    changeAdditionPrice,
    nominalPrice,
    setAdditionPrice,
    setNominalPrice,
    handleAddFieldPaymentMethodsDateIndex,
    handleInputChangeAmountPaymentMethods,
    handleInputChangeAmountCalculation,
    handleSelectOptionPaymentMethods,
    handleInputChange,
    isInvalid,
    isInvalidBalance,
    onRemoveFieldPaymentMethodsDateIndex,
    handleAddFieldDocumentsPaymentMethods,
    onRemoveFieldDocumentsPaymentMethods,
    handleExcludedDocumentByIndexId,
    handleUploadDocument,
    toBase64,
    setPaymentMethod,
    maskDolar,
    maskDate,
    maskCurrencyBR
  }
}
