import { ReactNode } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  Button,
  useBreakpointValue
} from '@chakra-ui/react'

interface ModalContentProps {
  children: ReactNode
  title: string
  isOpen: boolean
  onClose: () => void
  onUpdate?: () => Promise<void> | void
  notRenderFooter?: boolean
  isFull?: boolean
}

export const ModalContentComponent = ({
  children,
  title,
  isOpen,
  onClose,
  onUpdate,
  notRenderFooter = true,
  isFull
}: ModalContentProps) => {
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true
  })

  return (
    <>
      <Modal
        size={isFull ? 'full' : '5xl'}
        blockScrollOnMount={false}
        key={title}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent overflow="auto">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody>{children}</ModalBody>
          {notRenderFooter ? null : (
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="blue" onClick={onUpdate}>
                Confirma
              </Button>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}
