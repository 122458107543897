/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'
import { queryClient } from '../../services/reactQuery'

export type ServicesData = {
  id: number
  name: string
  service_sessions: Array<{
    id: number
    duration: number
    time_to_be_there: number
    session_number: number
    service_id: number
  }>
}

export type SpotsData = {
  id: number
  name: string
  is_default: boolean
}

export type TagsData = {
  id: number
  name: string
}

export type ClientsData = {
  id: number
  name: string
}

export type SpecialistsData = {
  id: number
  social_name: string
  user: {
    id: number
    name: string
    email: string
    contact: string
  }
}

export type TaxUnitData = {
  id: number
  name: string
}

export type WaysData = {
  id: number
  name: string
  day_to_receive: string
  observation: string | null
  way_to_receive_parcels: Array<{}>
  active_parcels: number
}

export const useService = () => {
  const [isLoadingSpecialists, setIsLoadingSpecialists] = useState(false)
  const [specialists, setSpecialists] = useState<SpecialistsData[]>([])
  const [taxUnits, setTaxUnits] = useState<TaxUnitData[]>([])
  const [ways, setWays] = useState<WaysData[]>([])

  const getClients = async (search?: string) => {
    try {
      const { data } = await apiAuth.get(`clinics/patients/`, {
        params: {
          is_active: true,
          name__icontains: search || ''
        }
      })

      return data.results.map((client: any) => {
        return {
          value: client.id,
          label: client.name
        }
      })
    } catch (err: any) {
      console.log(err)
      return []
    }
  }

  useEffect(() => {
    // get infos by scheduling

    const getSpecialists = () => {
      try {
        setIsLoadingSpecialists(true)
        apiAuth
          .get(`users/`, {
            params: {
              is_active: true,
              is_specialist: true
            }
          })
          .then((response) => {
            setSpecialists(response.data.results)
            setIsLoadingSpecialists(false)
          })
      } catch (err: any) {
        console.log(err)
        setIsLoadingSpecialists(false)
      }
    }

    const getTaxUnits = () => {
      try {
        setIsLoadingSpecialists(true)
        apiAuth
          .get(`clinics/tax-units/`, {
            params: {
              is_active: true
            }
          })
          .then((response) => {
            setTaxUnits(response.data.results)
          })
      } catch (err: any) {
        console.log(err)
      }
    }

    const getMeansToReceive = () => {
      apiAuth
        .get('clinics/ways-receives/', {
          params: {
            page: 1,
            is_active: true
          }
        })
        .then(({ data }) => {
          setWays(data?.results)
        })
    }

    getSpecialists()
    getTaxUnits()
    getMeansToReceive()

    return () => {}
  }, [])

  return {
    isLoadingSpecialists,
    specialists,
    getClients,
    taxUnits,
    ways
  }
}
