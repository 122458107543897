import { Box, Button, Stack, useToast } from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useModal } from '../../hooks/useModal'
import { apiAuth } from '../../services/apiAuth'
import { Input } from '../Form/Input'
import { Select } from '../Form/Select'
import { SelectComponent } from '../Form/SelectComponent'
import { ModalArrivedTime } from '../Modal/confirmArrivedTime'
import { Draft } from '../molecules/Draft'

type TypeDocument = 'form' | 'prescription' | 'exam' | 'indication'

type ServiceSelect = {
  value: number
  label: string
} | null

type Services = Array<{
  value: number
  label: string
}>

interface AddRecordUniqProps {
  id: number | string
  updateListRecording?: () => void
  servicesList: Services
}

export const AddRecordUniq: React.FC<AddRecordUniqProps> = ({
  id,
  updateListRecording,
  servicesList
}) => {
  // translate
  const [t] = useTranslation('AddRecordUniq')
  const { LocaleApp } = useModal()
  const toast = useToast()
  const optionsDocument = [
    { value: 'form', label: `${t('form')}` },
    { value: 'prescription', label: `${t('prescription')}` },
    { value: 'exam', label: `${t('exam')}` },
    { value: 'indication', label: `${t('indication')}` }
  ]
  // state configOptions
  const [isOpen, setIsOpen] = useState(false)
  const [typeDocument, setTypeDocumento] = useState<TypeDocument | string>(
    'form'
  )
  const [valueDraft, setValueDraft] = useState<any>('<p></p>')
  const [description, setDescription] = useState('')
  const [selectService, setSelectService] = useState<ServiceSelect>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState(
    format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp })
  )

  const handleConfirmationAdd = async () => {
    // validation

    if (valueDraft === '<p></p>' && typeDocument !== 'indication') {
      toast({
        status: 'error',
        position: 'top-right',
        description: 'O conteúdo não pode ser vazio',
        title: 'Atenção!'
      })

      return
    }

    if (valueDraft === '<p><br/></p>' && typeDocument !== 'indication') {
      toast({
        status: 'error',
        position: 'top-right',
        description: 'O conteúdo não pode ser vazio',
        title: 'Atenção!'
      })

      return
    }

    if (!selectService && typeDocument === 'indication') {
      toast({
        status: 'error',
        position: 'top-right',
        description: 'Selecione o serviço para indicação.',
        title: 'Atenção!'
      })

      return
    }

    if (date === '' && typeDocument === 'indication') {
      toast({
        status: 'error',
        position: 'top-right',
        description: 'A data a realizar é obrigatória',
        title: 'Atenção!'
      })

      return
    }

    setIsOpen(false)
    setIsLoading(true)

    try {
      const { data: allDataRecording } = await apiAuth.get(
        `clinics/attendances/${id}/medical-records/`
      )

      const { forms, prescriptions, exams, indications } = allDataRecording

      if (typeDocument === 'form') {
        const newFormsValues = [
          ...forms,
          { name: 'Blank', information: valueDraft }
        ]

        await apiAuth.patch(`clinics/attendances/${id}/medical-records/`, {
          forms: newFormsValues,
          is_draft: false,
          prescriptions,
          exams,
          indications
        })
      }

      if (typeDocument === 'prescription') {
        const newPrescriptionsValues = [
          ...prescriptions,
          { name: 'Blank', information: valueDraft }
        ]

        await apiAuth.patch(`clinics/attendances/${id}/medical-records/`, {
          prescriptions: newPrescriptionsValues,
          is_draft: false,
          forms,
          exams,
          indications
        })
      }

      if (typeDocument === 'exam') {
        const newExamsValues = [
          ...exams,
          { name: 'Blank', information: valueDraft }
        ]

        await apiAuth.patch(`clinics/attendances/${id}/medical-records/`, {
          exams: newExamsValues,
          is_draft: false,
          prescriptions,
          forms,
          indications
        })
      }

      if (typeDocument === 'indication') {
        const newIndicationsValues = [
          ...indications,
          {
            service_name: selectService?.label,
            observation: description === '' ? null : description,
            indication_date: `${date}T00:00:00`,
            status: 1,
            service: {
              id: selectService?.value
            }
          }
        ]

        await apiAuth.patch(`clinics/attendances/${id}/medical-records/`, {
          indications: newIndicationsValues,
          is_draft: false,
          prescriptions,
          exams,
          forms
        })
      }

      toast({
        status: 'success',
        position: 'top-right',
        description: 'Item adicionado com sucesso! atualizando a lista.',
        title: 'Atenção!'
      })

      updateListRecording?.()
    } catch (error: any) {
      toast({
        status: 'error',
        position: 'top-right',
        description: 'Não foi possível adicionar o item',
        title: 'Atenção!'
      })

      setIsLoading(false)

      return
    }

    setIsLoading(false)
    // reset values
    setTypeDocumento('form')
    setValueDraft('<p></p>')
    setDescription('')
    setSelectService(null)
    setDate(format(new Date(), 'yyyy-MM-dd', { locale: LocaleApp }))
  }

  return (
    <>
      <Button
        size="xs"
        bg="blue.300"
        color="white"
        isLoading={isLoading}
        onClick={() => setIsOpen(true)}
      >
        Adicionar item
      </Button>
      <ModalArrivedTime
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t('title')}
        key={id}
        onUpdate={async () => {
          await handleConfirmationAdd()
        }}
      >
        <Stack w="100%" direction="column" p="4">
          <Stack direction="row" w="100%">
            <Box w="220px">
              <Select
                name="typeDocument"
                options={optionsDocument}
                value={typeDocument}
                onChange={(event) => setTypeDocumento(event.target.value)}
              />
            </Box>
          </Stack>
          {typeDocument !== 'indication' ? (
            <Draft
              getValue={(value: any) => {
                setValueDraft(value)
              }}
            />
          ) : (
            <>
              <SelectComponent
                options={servicesList}
                name="services"
                placeholder="Selecione a indicação"
                value={selectService}
                onChange={(event: any) => {
                  setSelectService(event)
                }}
              />
              <Input
                name="date"
                label="Data a realizar"
                type="date"
                value={date}
                isRequired
                onChange={(event) => setDate(event.target.value)}
              />
              <Input
                name="description"
                label="Descrição"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </>
          )}
        </Stack>
      </ModalArrivedTime>
    </>
  )
}
