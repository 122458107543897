import { Button } from '@chakra-ui/react'

interface PaginationItemProps {
  isCurrent?: boolean
  numberPage: number
  onPageChange: (page: number) => void
}

export function PaginationItem({
  isCurrent = false,
  numberPage,
  onPageChange
}: PaginationItemProps) {
  if (isCurrent) {
    return (
      <Button
        size="sm"
        fontSize="small"
        width="6"
        colorScheme="blue"
        disabled
        _disabled={{
          bg: 'blue.400',
          cursor: 'default'
        }}
      >
        {numberPage}
      </Button>
    )
  }

  return (
    <Button
      size="sm"
      fontSize="x-small"
      width="6"
      bg="blue.300"
      _hover={{
        bg: 'blue.200'
      }}
      onClick={() => {
        onPageChange(numberPage)
      }}
    >
      {numberPage}
    </Button>
  )
}
