import {
  Flex,
  Box,
  Text,
  Avatar,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverBody
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/auth'
import { useModal } from '../../hooks/useModal'

interface ProfileProps {
  showProfileData?: boolean
}

export function Profile({ showProfileData = false }: ProfileProps) {
  const [t] = useTranslation('header')
  const [isHover, setHover] = useState(false)

  const { signOut, decodeToken } = useAuth()
  const { setSpecialistIds } = useModal()

  const handleHoverEnter = () => {
    setHover(true)
  }

  return (
    <Flex>
      <Popover>
        <PopoverTrigger>
          <Avatar
            size="md"
            name={decodeToken?.name}
            src={decodeToken?.image_url}
            bg={decodeToken?.user_color}
            cursor="pointer"
            onClick={() => handleHoverEnter()}
          />
        </PopoverTrigger>
        {showProfileData && isHover ? (
          <PopoverContent left="5" maxW="150px">
            <PopoverBody>
              <Button
                bg="transparent"
                color="red.600"
                w="100%"
                _hover={{ background: 'transparent' }}
                onClick={() => {
                  setSpecialistIds([])
                  signOut()
                }}
              >
                {t('header.btnSair.app')}
              </Button>
            </PopoverBody>
          </PopoverContent>
        ) : null}
      </Popover>
      {showProfileData && (
        <Box ml="6" textAlign="right">
          <Text fontWeight="hairline" fontSize="12px">
            {decodeToken?.social_name || decodeToken?.name}
          </Text>
        </Box>
      )}
    </Flex>
  )
}
