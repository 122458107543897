import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
        ::-webkit-scrollbar {
          width: 16px;
          margin-left: 2px;
        }
        ::-webkit-scrollbar-track {
          background-color: #F4F4F4;
        }
        ::-webkit-scrollbar-thumb {
          background: #E1E1E1;
          border-radius: 24px;
        }
    }

    @media(max-width: 1080px){
      html{
          font-size: 93.75%;
          }
    }

    @media(max-width: 720px){
        html{
            font-size: 87.5%;
        }
    }

    html,body {
      width: 100%;
      height: 100%;
      margin: 0px;
      padding: 0px;
      overflow-x: hidden;
    }


    body, input, textarea, button {
      font: 400 1rem 'Open Sans', sans-serif;
    }

    button {
      cursor: pointer;
    }
`
