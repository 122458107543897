/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-children-prop */
/* prettier-ignore */
import {
  forwardRef,
  ForwardRefRenderFunction,
  useState,
  useCallback,
  ElementType
} from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Icon,
  TextareaProps as ChakraTextareaProps,
  Textarea as ChakraTextarea
} from '@chakra-ui/react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { FieldError } from 'react-hook-form'

interface TextareaProps extends ChakraTextareaProps {
  name: string
  label?: string
  error?: FieldError
  type?: string
  isRequired?: boolean
  iconRight?: ElementType
  _active?: any
}

const TArea: ForwardRefRenderFunction<HTMLTextAreaElement, TextareaProps> = (
  {
    name,
    label,
    error = null,
    type = 'text',
    isRequired = false,
    iconRight,
    ...rest
  },
  ref
) => {
  const [typeInput, setTypeInput] = useState(type)

  const handleChangeType = useCallback(() => {
    if (typeInput === 'password') {
      setTypeInput('text')
    } else {
      setTypeInput('password')
    }
  }, [typeInput])

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired} bgColor="white">
      {!!label && (
        <FormLabel fontSize="14px" htmlFor={name}>
          {label}
        </FormLabel>
      )}
      <InputGroup display="flex" alignItems="center">
        <ChakraTextarea
          borderRadius="none"
          name={name}
          id={name}
          focusBorderColor="blue.400"
          bgColor="white"
          borderColor="gray.100"
          borderWidth="1px"
          variant="filled"
          type={typeInput}
          _hover={{
            bgColor: 'blue.50'
          }}
          focus
          size="lg"
          ref={ref}
          {...rest}
        />
        {!!iconRight && (
          <InputRightElement
            mt="4px"
            children={<Icon as={iconRight} fontSize="24px" color="#9699B0" />}
          />
        )}
        {type === 'password' && (
          <>
            {typeInput === 'password' ? (
              <InputRightElement
                onClick={handleChangeType}
                mt="4px"
                ml="16"
                children={<FiEye size="24px" color="#9699B0" />}
              />
            ) : (
              <InputRightElement
                mt="4px"
                ml="16"
                children={
                  <FiEyeOff
                    size="24px"
                    color="#9699B0"
                    onClick={handleChangeType}
                  />
                }
              />
            )}
          </>
        )}
      </InputGroup>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Textarea = forwardRef(TArea)
