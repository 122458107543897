import styled, { StyledProps } from 'styled-components'

export const Container = styled.div`
  padding: 0 15px;
  height: 100%;
  width: 100%;
  flex: 0 0 320px;

  ul {
    margin-top: 12px;
  }
`
export const Header = styled.header`
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: lighter;
`
