/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../hooks/auth'
import { apiAuth } from '../../services/apiAuth'

export type TagsData = {
  id: number
  name: string
}

export type DocData = {
  id: number
  name: string
}

const BASE_URL = 'clinics/services/'

export const useService = () => {
  const [loadingRest, setLoadingRest] = useState(false)
  const [tagsServices, setTagsServices] = useState<TagsData[]>([])
  const [docServices, setDocServices] = useState<DocData[]>([])

  // terceiros
  const { clinicId } = useAuth()
  const router = useHistory()
  const toast = useToast()

  // função para criar
  async function createRequest(body: object) {
    try {
      const response = await apiAuth.post(BASE_URL, body)

      if (response.data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/services')
        }, 2000)
      }
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  // função para editar
  async function updateRequest(body: object, id: number) {
    try {
      setLoadingRest(true)
      const res = await apiAuth.put(`${BASE_URL}${id}/`, body)

      console.log('payload', body)
      console.log(res)

      toast({
        title: 'Atualização realizado com sucesso!',
        description: 'Estamos lhe redirecionando.',
        status: 'success',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })

      setTimeout(() => {
        router.push('/services')
      }, 2000)
    } catch (error) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    } finally {
      setLoadingRest(false)
    }
  }

  const getGroupsServices = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(`clinics/${clinicId}/tags-services/`, {
        params: {
          is_active: true
        }
      })

      const { results } = data

      setTagsServices(results)
    } catch (error: any) {
      toast({
        title: 'Aconteceu algum erro!',
        description: 'Tente novamente em instantes entra na área da página.',
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }, [clinicId])

  const getDocumentsService = useCallback(async () => {
    try {
      const { data } = await apiAuth.get(
        `clinics/${clinicId}/documents-service/`,
        {
          params: {
            is_active: true
          }
        }
      )

      const { results } = data

      setDocServices(results)
    } catch (error: any) {
      console.log(error)
    }
  }, [clinicId])

  useEffect(() => {
    const getAll = async () => {
      await getGroupsServices()
      await getDocumentsService()
    }

    getAll()

    return () => {}
  }, [])

  // export
  return {
    createRequest,
    updateRequest,
    loadingRest,
    tagsServices,
    docServices
  }
}
