/* eslint-disable camelcase */
import { useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { apiAuth } from '../../services/apiAuth'

type User = {
  id: number
  name: string
}

export type Data = {
  id?: number
  name: string
  is_active?: boolean
  users?: Array<User>
  prescription_detail?: string
  description?: string | null
  status?: number | string
  type?: number | string
}

export type Props = {
  init: boolean
}

interface Specialist {
  id: number
  user: {
    id: number
    name: string
  }
  social_name: string
}

type SpecialistData = Array<Specialist>

type GetResponse = {
  results: SpecialistData
}

const BASE_URL = 'clinics/prescriptions-requests/'

export const useAttendance = () => {
  // terceiros
  const router = useHistory()
  const toast = useToast()
  // states
  const [specialists, setSpecialists] = useState<SpecialistData>([])

  useEffect(() => {
    apiAuth
      .get<GetResponse>(`users/`, {
        params: {
          is_active: true,
          is_specialist: true
        }
      })
      .then((response) => {
        setSpecialists(response.data.results)
      })

    return () => {}
  }, [])

  // função para criar
  async function createRecipe(payload: Data) {
    try {
      const { data } = await apiAuth.post(BASE_URL, payload)

      if (data.id !== undefined) {
        toast({
          title: 'Cadastro realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })

        setTimeout(() => {
          router.push('/settings/recipes')
        }, 2000)
      }
    } catch (error: any) {
      toast({
        title: 'Error!',
        description: `${error.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // função para editar
  async function updateRecipe({
    id,
    name,
    is_active,
    prescription_detail,
    status,
    users,
    type,
    description
  }: Data) {
    try {
      const res = await apiAuth.put(`${BASE_URL}${id}/`, {
        name,
        is_active,
        prescription_detail,
        users,
        status,
        type,
        description
      })

      if (res.data.id !== undefined) {
        toast({
          title: 'Atualização realizado com sucesso!',
          description: 'Estamos lhe redirecionando.',
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top'
        })
      }

      setTimeout(() => {
        router.push('/settings/recipes')
      }, 2000)
    } catch (error: any) {
      toast({
        title: 'Error!',
        description: `${error.response?.data?.message}`,
        status: 'error',
        isClosable: true,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // export
  return {
    createRecipe,
    updateRecipe,
    specialists
  }
}
